import styled from "styled-components";

export const Wrapper = styled.div`
	height: 100%;
`;

export const Container = styled.div`
	/* padding: 0px 10px; */
`;

export const Left = styled.div`
	width: 100%;
`;

export const Right = styled.div`
	width: 0%;
`;

export const Warn = styled.p`
	color: #ff5c00;
	margin: 5px 0 25px;

	& > a {
		color: inherit;
		font-weight: 700;
	}
`;

export const Uploaders = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 30px;
	margin-bottom: 30px;
`;

export const RadioGroup = styled.div`
	display: flex;
	margin-bottom: 20px;
`;
