import { getCurrentYear } from "../../utils/dates";
import { FooterLink, FooterText, FooterWrapper } from "./styles";

const webUrl = process.env.REACT_APP_WEB_URL;

function Footer() {
	return (
		<FooterWrapper>
			<FooterText>
				<FooterLink href={`${webUrl}`}>{"Augmento.com"}</FooterLink>
				{` ® - All rights reserved © ${getCurrentYear()}`}
			</FooterText>

			<FooterLink href={`${webUrl}/contact`}>{"Contact"}</FooterLink>
		</FooterWrapper>
	);
}

export default Footer;
