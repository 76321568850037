import { ProductsCounter, Wrapper } from "./styles";
import ReactSelect from "react-select";
const pageNumbers = [
	{ label: "10", value: "10" },
	{ label: "25", value: "25" },
	{ label: "50", value: "50" },
	{ label: "100", value: "100" },
];
const customStyles = {
	container: provided => ({
		...provided,
		border: "none",
		boxShadow: "none !important",
		backgroundColor: "transparent",
		width: "100px",
		marginLeft: "-45px",
	}),
	control: provided => ({
		...provided,
		border: "none",
		boxShadow: "none !important",
		backgroundColor: "transparent",
		width: "100px",
		alignContent: "center",
	}),
	valueContainer: provided => ({
		...provided,
		justifyContent: "flex-end",
		padding: 0,
	}),
	indicatorSeparator: () => ({
		display: "none",
	}),
	dropdownIndicator: () => ({
		color: "black",
	}),
	singleValue: provided => ({
		...provided,
		paddingRight: "2px",
	}),
	menu: provided => ({
		...provided,
		width: 100,
		textAlign: "right",
	}),
};
function CustomPageSize({ leftText, limit, onPageSizeChange, className }) {
	return (
		<ProductsCounter className={className}>
			{leftText}{" "}
			<ReactSelect
				styles={customStyles}
				defaultValue={pageNumbers.filter(
					x => Number(x.value) === limit
				)}
				width={28}
				mb={0}
				options={pageNumbers}
				onChange={onPageSizeChange}
			></ReactSelect>{" "}
		</ProductsCounter>
	);
}

export default CustomPageSize;
