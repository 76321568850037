import React, { useState, useEffect } from "react";
import { ErrorMessage, Field, Form } from "formik";
import { PrimaryButton } from "../../styles/ui";
import Radio from "../Radio";
import Tags from "../Tags";
import FormTitleCategory from "./FormTitleCategory";
import { H4, ProductGroupSection } from "./styles/ProductForm.styles";

import { Container, Left, RadioGroup } from "../../layout/ProductEdit/styles";

import { AlignRight, Error } from "../../pages/auth/styles";
import useActiveSubscription from "../../hooks/useActiveSubscription";
import Loader from "../Loader";
import MediaUploaderContainer from "../MediaUploaderContainer";

import ProductPriceSelect from "../ProductPriceSelect";
import Generate3DModel from "./Generate3DModel/Generate3DModel";
import FormTabs from "./FormTabs";
import CreateThumbnail from "./Thumbnail/CreateThumbnail";
import { Warn } from "../../layout/ProductEdit/styles";
import LinkedProducts from "../LinkedProducts";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import LinkProduct from "./LinkProduct";

const modelButtonDescription = <>max 30 MB-.Glb .Gltf allowed</>;

function ProductForm({
	values,
	errors,
	initialValues,
	setFieldValue,
	isSubmitting,
}) {
	const type = initialValues.productType ? initialValues.productType : "";

	const { data, isLoading, error } = useActiveSubscription();

	const [useVariations, setUseVariations] = useState(
		initialValues.variations ? !!initialValues.variations.length : false
	);

	const [tab, setTab] = useState(1);

	const [subscriptionPrice, setSubscriptionPrice] = useState(false);
	const [productVariants, setProductVariants] = useState(false);

	useEffect(() => {
		if (!Boolean(values.link)) {
			setFieldValue("link", null);
		}
	}, [values.link, setFieldValue]);

	useEffect(() => {
		if (data) {
			setSubscriptionPrice(data.limits.external_links_allowed);
			setProductVariants(data.limits.variations_allowed);
		}
	}, [data]);
	// clear variations field when set to false
	useEffect(() => {
		if (!useVariations) {
			setFieldValue("variations", []);
		}
	}, [useVariations, setFieldValue]);

	if (isLoading) {
		return <Loader />;
	}

	if (error) {
		return null;
	}

	const handleChange = newValue => {
		if (newValue === 1) {
			setFieldValue("useExisting", true);
		}
		if (newValue === 2) {
			setFieldValue("useExisting", false);
		}
		setTab(newValue);
	};

	return (
		<>
			<Form>
				<Container>
					<Left>
						{/* Title and Category section */}
						<FormTitleCategory />

						{type && type === 1 && (
							<>
								<H4>Upload art work</H4>
								<MediaUploaderContainer
									mediaType={"model"}
									buttonDescription={modelButtonDescription}
									title={"DROP 3D FILE HERE"}
									buttonTitle={"Or click to select file"}
									accept={
										".glb, .gLTF, model/gltf+json, model/glb+json"
									}
									productName={values.title}
									frameConfig={{
										width: values.arWidth,
										height: values.arHeight,
										frameWidth: values.frameWidth,
										frameHeight: 1,
										frameThickness: values.frameThickness,
									}}
								/>
								<AlignRight>
									<ErrorMessage
										name={"model"}
										component={Error}
									/>
								</AlignRight>
							</>
						)}
						{type && type === 2 && (
							<>
								<FormTabs
									tab={tab}
									handleChange={handleChange}
								/>
								<H4 mb="12px">Upload art work</H4>
								{type && type === 2 && tab === 2 && (
									<>
										<MediaUploaderContainer
											mediaType={"model"}
											buttonDescription={
												modelButtonDescription
											}
											title={"DROP 3D FILE HERE"}
											buttonTitle={
												"Or click to select file"
											}
											accept={
												".glb, .gLTF, model/gltf+json, model/glb+json"
											}
											productName={values.title}
											frameConfig={{
												width: values.arWidth,
												height: values.arHeight,
												frameWidth: values.frameWidth,
												frameHeight: 1,
												frameThickness:
													values.frameThickness,
											}}
										/>
										<AlignRight>
											<ErrorMessage
												name={"model"}
												component={Error}
											/>
										</AlignRight>
									</>
								)}
								{type && type === 2 && tab === 1 && (
									<Generate3DModel
										values={values}
										setFieldValue={setFieldValue}
										initialValues={initialValues}
									/>
								)}
							</>
						)}

						{/* Dropping Thumbnail image */}
						<CreateThumbnail
							values={values}
							initialValues={initialValues}
						/>

						{subscriptionPrice !== 0 && <LinkProduct />}

						<H4 mb={"12px"}>Show price (optional)</H4>
						<ProductPriceSelect name="currency" />
					</Left>

					{productVariants !== "false" && (
						<ProductGroupSection>
							{initialValues.parent && (
								<Warn>
									{"This product is linked to another "}

									<Link
										to={`/products/edit/${initialValues.parent}`}
									>
										one
									</Link>
								</Warn>
							)}

							<div className="product-variation">
								<H4 mb={"25px"}>Product is a group or set?</H4>

								<RadioGroup>
									<Radio
										name={"hasVariations"}
										title={"Yes"}
										value={true}
										state={useVariations}
										handleChange={setUseVariations}
										disabled={!!initialValues.parent}
									/>
									<Radio
										name={"hasVariations"}
										title={"No"}
										value={false}
										state={useVariations}
										handleChange={setUseVariations}
										disabled={!!initialValues.parent}
									/>
								</RadioGroup>
							</div>

							{useVariations && (
								<LinkedProducts
									excludeProduct={initialValues.productId}
								/>
							)}
						</ProductGroupSection>
					)}
					<div className="tag-area">
						<H4 mb={"12px"}>Add tag</H4>

						<Field name="tags" component={Tags} />
					</div>
				</Container>

				<PrimaryButton type="submit" disabled={isSubmitting}>
					{"Save product"}
				</PrimaryButton>
			</Form>
		</>
	);
}

export default ProductForm;
