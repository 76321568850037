import { Controller, Wrapper } from "./styles";
import { ReactComponent as ArrLeft } from "../../images/icons/arr-l.svg";
import { ReactComponent as ArrRight } from "../../images/icons/arr-r.svg";

function Pagination({ totalPages, page, goNext, goPrev }) {
	return (
		<Wrapper>
			{page > 1 && (
				<Controller onClick={goPrev}>
					<ArrLeft height={18} />
					{"Previous"}
				</Controller>
			)}

			{page < totalPages && (
				<Controller onClick={goNext}>
					{"Next"}
					<ArrRight height={18} />
				</Controller>
			)}
		</Wrapper>
	);
}

export default Pagination;
