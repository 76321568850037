import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import FlexBox from "../FlexBox";
import FlexGrow from "../FlexGrow";
import styled from "styled-components";
import { ReactComponent as Close } from "../../images/icons/close.svg";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import axios from "axios";

import {
	CropImageSection,
	ModalSaveBtn,
	OptionsSection,
	ButtonPosition,
} from "./styles";

import CropFunctions from "./CropOptions";

export const LeftAlign = styled.div`
	display: flex;
	justify-content: flex-start;
`;
export const CloseButton = styled(Close)`
	cursor: pointer;
`;

const TO_RADIANS = Math.PI / 180;

const CropModal = ({
	isOpen,
	setOpen,
	imageSrc,
	name,
	productId,
	onCropClose,
	setArtUrl,
}) => {
	const api = axios.create({
		baseURL: process.env.REACT_APP_API_URL,
		timeout: 600000,
		headers: {
			"Access-Control-Request-Method": "GET",
			"Access-Control-Allow-Origin": "*",
			Origin: "http://localhost:3000",
			Accept: "*/*",
		},
		responseType: "blob",
	});
	const [src, setSrc] = useState("");
	const [croppedSrc, setCroppedSrc] = useState("");
	const [crop, setCrop] = useState({
		height: 212.60156249999994,
		unit: "px",
		width: 182.72265625,
		x: 73.82421874999983,
		y: 40.92968750000031,
	});
	const [imageRef, setImageRef] = useState();
	const [scale, setScale] = useState(1);
	const [rotate, setRotate] = useState(0);

	const onSelectFile = e => {
		if (e.startsWith("data:image")) {
			setSrc(e);
		} else {
			if (e) {
				api.get(e).then(function (response) {
					const reader = new window.FileReader();
					reader.readAsDataURL(response.data);
					reader.onload = function () {
						const imageDataUrl = reader.result;
						setSrc(imageDataUrl);
					};
				});
			}
		}
	};
	const onImageLoaded = image => {
		setImageRef(image);
	};

	const onCropComplete = crop => {
		makeClientCrop(crop);
	};

	useEffect(() => {
		if (imageSrc && isOpen) {
			onSelectFile(imageSrc);
		}
		if (imageRef) {
			makeClientCrop({
				height: 212.60156249999994,
				unit: "px",
				width: 182.72265625,
				x: 73.82421874999983,
				y: 40.92968750000031,
			});
		}
	}, [imageSrc, isOpen, imageRef]);

	async function makeClientCrop(crop) {
		if (imageRef && crop.width && crop.height) {
			const croppedImageUrl = await getCroppedImg(imageRef, crop);
			setCroppedSrc(croppedImageUrl);
		}
	}
	function getCroppedImg(image, crop) {
		const canvas = document.createElement("canvas");
		const pixelRatio = window.devicePixelRatio;
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const ctx = canvas.getContext("2d");
		canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
		canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

		ctx.scale(pixelRatio, pixelRatio);
		ctx.imageSmoothingQuality = "high";

		const cropX = crop.x * scaleX;
		const cropY = crop.y * scaleY;

		const rotateRads = rotate * TO_RADIANS;
		const centerX = image.naturalWidth / 2;
		const centerY = image.naturalHeight / 2;

		ctx.save();

		// 5) Move the crop origin to the canvas origin (0,0)
		ctx.translate(-cropX, -cropY);
		// 4) Move the origin to the center of the original position
		ctx.translate(centerX, centerY);
		// 3) Rotate around the origin
		ctx.rotate(rotateRads);
		// 2) Scale the image
		ctx.scale(scale, scale);
		// 1) Move the center of the image to the origin (0,0)
		ctx.translate(-centerX, -centerY);

		ctx.drawImage(
			image,
			0,
			0,
			image.naturalWidth,
			image.naturalHeight,
			0,
			0,
			image.naturalWidth,
			image.naturalHeight
		);

		ctx.restore();

		return canvas.toDataURL("image/jpeg", 1);
	}
	const onCropChange = (crop, percentCrop) => {
		setCrop(crop);
	};

	function dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(","),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = window.atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename + "-cropped.jpg", { type: mime });
	}

	return (
		<>
			<Modal isOpen={isOpen || false} setOpen={setOpen}>
				<FlexBox>
					<FlexGrow>
						<LeftAlign>{name}</LeftAlign>
					</FlexGrow>
					<CloseButton onClick={() => setOpen(false)}></CloseButton>
				</FlexBox>

				<CropImageSection>
					<div
						style={{
							maxWidth: "500px",
						}}
					>
						{src && (
							<ReactCrop
								keepSelection={true}
								src={src}
								crop={crop}
								ruleOfThirds={false}
								onImageLoaded={onImageLoaded}
								onComplete={onCropComplete}
								onChange={onCropChange}
								imageStyle={{
									maxHeight: "500px",
									border: "2px solid #36b2e5",
									outline: "solid #36b2e5",
									transform: `scale(${scale}) rotate(${rotate}deg)`,
								}}
							/>
						)}
					</div>
				</CropImageSection>
				<OptionsSection>
					<CropFunctions setScale={setScale} setRotate={setRotate} />

					<ButtonPosition>
						<ModalSaveBtn
							disabled={!croppedSrc}
							onClick={() => {
								setArtUrl(dataURLtoFile(croppedSrc, name));
								setOpen(false);
							}}
						>
							Save
						</ModalSaveBtn>
					</ButtonPosition>
				</OptionsSection>
			</Modal>
		</>
	);
};

export default CropModal;
