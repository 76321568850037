import { CropOptionsPosition, OptionButton, FlipIcon } from "./styles";

import { ReactComponent as RotateIcon } from "../../images/icons/rotate.svg";
import { ReactComponent as ZoomPlusIcon } from "../../images/icons/zoom-plus.svg";
import { ReactComponent as ZoomMinusIcon } from "../../images/icons/zoom-minus.svg";

import useLongPress from "../../hooks/useLongPress";

function CropFunctions({ setScale, setRotate }) {
	function imageMinusZoom() {
		setScale(prev => prev - 0.1);
	}
	function imagePlusZoom() {
		setScale(prev => prev + 0.1);
	}
	function imageRotateLeft() {
		setRotate(prev => prev - 1);
	}
	function imageRotateRight() {
		setRotate(prev => prev + 1);
	}

	return (
		<CropOptionsPosition>
			<FlipIcon type="button" {...useLongPress(imageRotateRight, 50)}>
				<RotateIcon width={28} height={28} />
			</FlipIcon>
			<OptionButton type="button" {...useLongPress(imageRotateLeft, 50)}>
				<RotateIcon width={28} height={28} />
			</OptionButton>
			<OptionButton type="button" {...useLongPress(imagePlusZoom, 50)}>
				<ZoomPlusIcon width={24} height={24} />
			</OptionButton>
			<OptionButton type="button" {...useLongPress(imageMinusZoom, 50)}>
				<ZoomMinusIcon width={24} height={24} />
			</OptionButton>
		</CropOptionsPosition>
	);
}

export default CropFunctions;
