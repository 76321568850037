import { useQuery } from "react-query";
import { getPublicCompanies } from "../../api/categories";
import { Field } from "formik";
import React from "react";
import { Item } from "../LinkedProducts/style";
import Pagination from "../Pagination";
import Search from "./Search";
import { Wrapper } from "./styles";
import CheckboxCustom from "../CheckboxCustom";
import qs from "query-string";

/**
 *
 * @param setFieldValue
 * @returns {JSX.Element}
 * @constructor
 */
function ClientSelect({ name, setFieldValue }) {
	const [search, setSearch] = React.useState("");
	const [page, setPage] = React.useState(1);

	const queryParams = qs.stringify({
		search: search || undefined,
		page,
	});
	React.useEffect(() => {
		setPage(1);
	}, [search]);
	const [openList, setOpenList] = React.useState(false);
	/**
	 * Load clients
	 */
	const { data, isLoading, error } = useQuery(
		["publicCompanies", search, page],
		() => getPublicCompanies(`?${queryParams}`)
	);

	if (error) {
		return <div>{error.message}</div>;
	}
	return (
		<>
			<Search
				onBlur={() => {
					setTimeout(() => {
						setOpenList(false);
					}, 700);
				}}
				onFocus={() => {
					setOpenList(true);
				}}
				value={search}
				onChange={e => setSearch(e.target.value)}
			/>
			{openList && (
				<>
					<Wrapper>
						{!isLoading && !data?.data?.data?.length && (
							<div>No items</div>
						)}
						{isLoading && <div>Loading..</div>}

						{data?.data?.data?.map(company => (
							<Item>
								<Field
									name={name}
									type="checkbox"
									value={company.id}
									as={CheckboxCustom}
									title={company.name}
									setFieldValue={setFieldValue}
									key={company.id}
								/>
							</Item>
						))}
					</Wrapper>

					{!!data?.data.pages && (
						<Pagination
							totalPages={data?.data.pages}
							page={page}
							goNext={() => setPage(prev => prev + 1)}
							goPrev={() => setPage(prev => prev - 1)}
						/>
					)}
				</>
			)}
		</>
	);
}

export default ClientSelect;
