import styled from "styled-components";

export const TabsContainer = styled.ul`
	display: flex;
	list-style: none;
	padding: 0;
	font-size: smaller;
	font-weight: 600;
`;

export const TabButton = styled.li`
	padding: 8px 20px;
	min-width: 170px;
	display: flex;
	align-items: center;
	border: ${props => (props.tab === props.value ? "solid 2px #dadada" : "")};
	border-bottom: none;
	border-radius: ${props => (props.tab === props.value ? "8px" : "0px")};
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	border-right: 0px;
	position: relative;
	color: ${props => (props.tab === props.value ? "black" : "grey")};
	fill: ${props => (props.tab === props.value ? "black" : "grey")};

	cursor: pointer;

	&:hover {
		opacity: 60%;
	}

	&:before {
		visibility: ${props =>
			props.tab === props.value ? "visible" : "hidden"};
		content: "";
		top: -1px;
		left: 1%;
		position: absolute;
		width: 100%;
		height: 88%;
		border-color: transparent #dadada transparent transparent;
		border-style: solid;
		border-width: 2px;
		border-top-right-radius: 5px;

		transform: skewX(20deg);
	}

	&::after {
		visibility: ${props =>
			props.tab === props.value ? "hidden" : "visible"};
		content: "";
		top: 0;
		left: ${props => (props.tab === 2 ? "-5%" : "0%")};
		position: absolute;
		width: ${props => (props.tab === 2 ? "99%" : "100%")};
		height: 90%;
		border-color: transparent transparent #dadada transparent;
		border-style: solid;
		border-width: 2px;
		border-bottom-left-radius: ${props =>
			props.tab === 2 ? "0px" : "14px"};
		border-bottom-right-radius: ${props => props.tab === 2 && "2px"};
		transform: skewX(20deg);
	}
`;

export const TabUnderline = styled.li`
	content: "";
	padding: 20px 30px;
	width: 100%;
	height: 100%;
	border-style: solid;
	border-width: 2px;
	border-bottom-left-radius: ${props => props.tab === 2 && "14px"};
	border-color: transparent transparent #dadada transparent;
	transform: skewX(20deg);

	@media screen and (max-width: 400px) {
		visibility: hidden;
	}
`;

export const TabText = styled.span`
	margin-left: 10px;
`;
