import React from "react";
import FlexGrow from "../FlexGrow";
import {
	Image,
	ContinueButton,
	Title,
	Type,
	ContinueButtonPosition,
	ProductsTypeWrapper,
} from "./ProductType.style";
import floor from "../../images/floor.svg";
import wall from "../../images/wall.svg";
import { useHistory } from "react-router";
import { Steps } from "intro.js-react";
import useOnboarding from "../../hooks/useOnboarding";
import { handleOnchange, handleOnExit } from "../../utils/products";
import { useState } from "react";

function ProductType() {
	const history = useHistory();
	const [selectedType, setSelectedType] = useState(0);

	const { onboarding } = useOnboarding([
		{
			element: ".product-types",
			title: "Select type",
			intro: "Add products that are placed on the floor or hang on a wall",
		},
		{
			intro: "",
		},
	]);

	const handleProductType = () => {
		history.push({ pathname: "/products/add" });
		localStorage.setItem(
			"pageState",
			JSON.stringify({ type: selectedType })
		);
	};
	const { stepsEnabled, steps, initialStep } = onboarding;
	const nextOnboardingStep = "/products/add?onboarding=true";

	return (
		<>
			<Steps
				enabled={stepsEnabled}
				steps={steps}
				initialStep={initialStep}
				onExit={handleOnExit}
				onChange={step => {
					handleOnchange(step, nextOnboardingStep);
					localStorage.setItem(
						"pageState",
						JSON.stringify({ type: 1 })
					);
				}}
			/>
			<Title>Choose product placement type</Title>
			<ProductsTypeWrapper mb={32}>
				<FlexGrow grow={1}>
					<Type
						onClick={() => {
							setSelectedType(2);
						}}
						typeSelected={selectedType === 2}
					>
						<Image height="150px" src={wall}></Image>
					</Type>
				</FlexGrow>
				<FlexGrow grow={1}>
					<Type
						onClick={() => {
							setSelectedType(1);
						}}
						typeSelected={selectedType === 1}
						className="stand-floor"
					>
						<Image height="150px" src={floor}></Image>
					</Type>
				</FlexGrow>
			</ProductsTypeWrapper>

			<ContinueButtonPosition>
				<ContinueButton
					onClick={handleProductType}
					disabled={selectedType === 0}
				>
					Continue
				</ContinueButton>
			</ContinueButtonPosition>
		</>
	);
}

export default ProductType;
