import format from "date-fns/format";
const getPlanAbbrev = term => (term === "month" ? "p/m" : "p/y");
export const getPlanTitle = str => str.split("Augmento ")[1];
export const getPlanPrice = (amount, time) =>
	amount ? `€${amount / 100} ${getPlanAbbrev(time)}` : "0€";
export const getSubscriptionNextBillingDate = subscription => {
	const { current_period_end } = subscription;

	return format(current_period_end * 1000, "dd MMM yyyy");
};
export const getEndTrialDate = subscription => {
	const { trial_end } = subscription;

	return format(trial_end * 1000, "dd MMM yyyy");
};
