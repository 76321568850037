import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	border: 0.5px solid #c7c7c7;
	box-shadow: 0 4px 40px rgba(0, 0, 0, 0.03);
	border-radius: 26px;
	padding: 40px;
	justify-content: space-evenly;
	margin-top: 80px;
`;
