import search from "../../images/icons/search.svg";
import { SearchIcon, SearchInput, SearchWrapper } from "./styles";

function Search({ onChange, value, placeholder }) {
	return (
		<SearchWrapper>
			<SearchIcon src={search} />

			<SearchInput
				onChange={onChange}
				placeholder={placeholder}
				value={value}
				autoFocus
			/>
		</SearchWrapper>
	);
}

export default Search;
