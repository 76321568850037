import {
	Main,
	Side,
	Wrapper,
	Logo,
	NavigationContainer,
	MainSection,
} from "./styles";
import logo from "../../images/logo2.svg";
import Navigation from "../../components/Navigation";
import Logout from "../../components/Logout";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar/Navbar";

function WithSidebar({ children }) {
	return (
		<Wrapper>
			<Navbar />

			<Side>
				<NavigationContainer>
					<Logo src={logo} />

					<Navigation />
				</NavigationContainer>
				<Logout />
			</Side>

			<MainSection>
				<Main>{children}</Main>
				<Footer />
			</MainSection>
		</Wrapper>
	);
}

export default WithSidebar;
