import { getAuthDataFromLocalStorage } from "../../utils/authToken";
import { useQuery } from "react-query";
import { getCategoriesOptions } from "../../api/categories";
import Select from "../Select";
import convertToOptions from "../../utils/convertToOptions";
import { Field } from "formik";
import React from "react";
import qs from "query-string";

function OptionSelector({ name, placeholder }) {
	const { companyId } = getAuthDataFromLocalStorage();
	const [search, setSearch] = React.useState("");

	const queryParams = qs.stringify({
		search: search || undefined,
	});
	const { data, isLoading, error } = useQuery(
		["categoriesOptions", search],
		() => getCategoriesOptions(companyId, `?${queryParams}`)
	);

	if (isLoading) {
		return <div>Loading..</div>;
	}

	if (error) {
		return <div>{error.message}</div>;
	}

	const {
		data: { data: categories },
	} = data;

	return (
		<Field
			placeholder={placeholder}
			component={Select}
			name={name}
			options={convertToOptions(categories, "id", "title")}
		/>
	);
}

export default OptionSelector;
