import api from "./index";

export const createProduct = (companyId, data) =>
	api.post(`/v1/companies/${companyId}/products`, data);

export const updateProductsOrder = (companyId, data) =>
	api.patch(`/v1/companies/${companyId}/products/order`, data);

export const getProducts = (companyId, query) => {
	return api.get(`/v1/admin/companies/${companyId}/products${query}`);
};

export const setProductVisibility = (productId, isVisible) => {
	return api.patch(`/v1/products/${productId}`, isVisible);
};

export const getProduct = productId =>
	api.get(`/v1/admin/products/${productId}`);

export const updateProduct = (productId, data) =>
	api.patch(`/v1/products/${productId}`, data);

export const deleteProduct = productId =>
	api.delete(`/v1/products/${productId}`);

export const countProducts = companyId =>
	api.get(`/v1/admin/companies/${companyId}/products/count`);
export const getImage = url => api.get(`${url}`);
