import styled from "styled-components";

export const FooterWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 15px;
`;

export const FooterText = styled.p`
	font-size: 12px;
	color: white;
`;
export const FooterLink = styled.a`
	margin: 0 0 0 12px;
	font-size: 12px;
	color: white;
	font-weight: 600;
	text-decoration: none;
`;
