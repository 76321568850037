import { getAuthDataFromLocalStorage } from "../utils/authToken";

const { token } = getAuthDataFromLocalStorage();
export const config = {
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 600000,
	headers: {
		Authorization: `Bearer ${token}`,
	},
};
export default config;
