import { H2, Input } from "../../styles/ui";
import { AlignCenter, Button, Container, H3, Label } from "./styles";
import { Form, Field, ErrorMessage } from "formik";
import { Error } from "../../pages/auth/styles";

/**
 *
 * @constructor
 */
function TextsForm({ values, setFieldValue, isSubmitting }) {
	return (
		<Form>
			<Container rowGap={30} mb={146}>
				<AlignCenter>
					<Label></Label>
				</AlignCenter>
				<AlignCenter>
					<H2 mb={10} style={{ fontSize: "16px" }}>
						Use custom text (no changes if the field is empty)
					</H2>
				</AlignCenter>
				<AlignCenter>
					<Label>{"View in store"}</Label>
				</AlignCenter>
				<AlignCenter>
					<Field
						as={Input}
						placeholder={""}
						width={332}
						name={"view"}
					/>
					&nbsp;
					<ErrorMessage name="view" component={Error} />
				</AlignCenter>

				<AlignCenter>
					<Label>{"Contact us"}</Label>
				</AlignCenter>
				<AlignCenter>
					<Field
						as={Input}
						placeholder={""}
						width={332}
						name={"contact"}
					/>
					&nbsp;
					<ErrorMessage name="contact" component={Error} />
				</AlignCenter>

				<AlignCenter>
					<Label>{"Popular"}</Label>
				</AlignCenter>
				<AlignCenter>
					<Field
						as={Input}
						placeholder={""}
						width={332}
						name={"popular"}
					/>
					&nbsp;
					<ErrorMessage name="popular" component={Error} />
				</AlignCenter>

				<AlignCenter>
					<Label>{"All collections"}</Label>
				</AlignCenter>
				<AlignCenter>
					<Field
						as={Input}
						placeholder={""}
						width={332}
						name={"collections"}
					/>
					&nbsp;
					<ErrorMessage name="collections" component={Error} />
				</AlignCenter>
			</Container>

			<Button type={"submit"}>Save</Button>
		</Form>
	);
}

export default TextsForm;
