import React from "react";
import {
	FrameItem,
	FrameItemImage,
	FrameItemName,
	FrameItemsWrapper,
	FrameIconPosition,
} from "./styles/FrameItem.style";

import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import { ReactComponent as DoubleArrow } from "../../images/icons/double-arrow.svg";

import { Swiper, SwiperSlide } from "swiper/react";

const FrameSlider = ({ frameOptions, setFieldValue, frame }) => {
	return (
		<FrameItemsWrapper>
			<Swiper
				spaceBetween={20}
				slidesPerView={window.screen.width > 580 ? 7 : 3}
				navigation
				style={{
					padding: "10px 4px",
				}}
			>
				{frameOptions.map((frameItem, index) => (
					<SwiperSlide key={index} as={FrameItem}>
						<FrameItem key={index}>
							{frameItem.value && (
								<FrameIconPosition
									as={
										frameItem.value === "gold"
											? DoubleArrow
											: EllipsisHorizontalIcon
									}
									width={24}
									height={14}
								/>
							)}
							<FrameItemImage
								frameActive={frame === frameItem.value}
								onClick={() => {
									setFieldValue(
										"typeOfFrame",
										frameItem.value
									);
								}}
								as={frameItem.image}
								alt={`Slide ${index + 1}`}
							/>
							<FrameItemName>{frameItem.label}</FrameItemName>
						</FrameItem>
					</SwiperSlide>
				))}
			</Swiper>
		</FrameItemsWrapper>
	);
};

export default FrameSlider;
