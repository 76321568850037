import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	flex-shrink: 0;
	flex-grow: 0;
`;
export const InnerWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-shrink: 0;
	flex-grow: 0;
`;

export const Title = styled.h2`
	font-size: 30px;
	color: #102b3c;
	margin: 0;
`;

export const ProductsCounter = styled.div`
	font-family: "Philosopher", sans-serif;
	font-weight: 600;
	padding: 10px 30px;
	background: #c5d3db;
	border-radius: 8px;
	font-size: 19px;
	color: #102b3c;
`;
