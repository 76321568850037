import styled from "styled-components";

export const Wrapper = styled.div`
	position: relative;
	// box-shadow: 5px 9px 130px 20px rgba(0, 0, 0, 0.21);
	// border-radius: 24px;
	/* padding: 0px 30px; */
	// opacity: 11;
	// background-color: white;
	height: 100%;
	overflow: hidden;
`;
