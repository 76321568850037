import { InnerWrapper, ProductsCounter, Title, Wrapper } from "./styles";
import { useQuery } from "react-query";
import { countProducts } from "../../api/products";
import { getAuthDataFromLocalStorage } from "../../utils/authToken";
import useActiveSubscription from "../../hooks/useActiveSubscription";

import CustomPageSize from "../CustomPageSize";

/**
 *
 * @param title
 * @param showCounter
 * @constructor
 */
function PageHeader({
	title,
	showCounter,
	showPageSize = false,
	limit,
	changeLimit,
}) {
	const { companyId } = getAuthDataFromLocalStorage();

	const subscriptionQuery = useActiveSubscription();
	const productsCountQuery = useQuery(
		"countProducts",
		() => countProducts(companyId),
		{
			select: data => data?.data?.data,
		}
	);
	let counterReady = true;

	if (subscriptionQuery.isLoading || productsCountQuery.isLoading) {
		counterReady = false;
	}

	if (subscriptionQuery.error || productsCountQuery.error) {
		counterReady = false;
	}

	return (
		<Wrapper>
			<Title>{title}</Title>
			<InnerWrapper>
				{showPageSize && (
					<CustomPageSize
						leftText={"Show products"}
						limit={limit}
						onPageSizeChange={changeLimit}
						className="list-limit"
					/>
				)}
				<div className="product-used">
					{counterReady && showCounter && (
						<ProductsCounter
							style={{ marginLeft: "24px" }}
						>{`Products used: ${productsCountQuery.data}/${
							+subscriptionQuery?.data?.limits?.max_products || 0
						}`}</ProductsCounter>
					)}
				</div>
			</InnerWrapper>
		</Wrapper>
	);
}

export default PageHeader;
