import { verifyAuthToken } from "../api/auth";

/**
 *
 */
export const getAuthDataFromLocalStorage = () => ({
	token: localStorage.getItem("token"),
	isSuperAdmin: localStorage.getItem("isSuperAdmin"),
	email: localStorage.getItem("email"),
	companyId: localStorage.getItem("companyId"),
	userId: localStorage.getItem("userId"),
	stripeCustomer: localStorage.getItem("stripeCustomer"),
	stripeSubscription: localStorage.getItem("stripeSubscription"),
});

/**
 *
 * @param data
 */
export const saveAuthDataToLocalStorage = data => {
	localStorage.setItem("token", data.auth.accessToken);
	localStorage.setItem("isSuperAdmin", data.user.isSuperAdmin ? true : false);
	localStorage.setItem("userId", data.user.id);
	localStorage.setItem("companyId", data.company.id);
	localStorage.setItem("email", data.user.email);
	localStorage.setItem("stripeCustomer", data.user.stripeCustomer);
	localStorage.setItem("stripeSubscription", data.user.stripeSubscription);
};

/**
 *
 */
export const removeAuthDataFromLocalStorage = () => {
	localStorage.removeItem("token");
	localStorage.removeItem("userId");
	localStorage.removeItem("companyId");
	localStorage.removeItem("email");
};

/**
 *
 * @returns {Promise<boolean>}
 */
export const getAuthorizationStatus = async () => {
	const { token } = getAuthDataFromLocalStorage();

	if (!token) {
		return false;
	}

	try {
		// const { status } = await verifyAuthToken(token);
		const status = 200;
		return status === 200;
	} catch (err) {
		return false;
	}
};

/**
 *
 * @returns {Promise<boolean>}
 */
export const getAdminType = async () => {
	const { isSuperAdmin } = getAuthDataFromLocalStorage();
	return isSuperAdmin;
};
