const handleOnExit = () => {
	localStorage.setItem("isOnboarded", true);
};

const handleReOrdering = (source, destination, data) => {
	let dragIndex = source.index;
	let dragOverIndex = destination.index;

	// Check if you're dragging lower and item is not the last item
	const isDraggingLower =
		dragIndex < dragOverIndex && dragOverIndex < data.length - 1;

	const draggableItem = data[dragIndex];
	const dragOverItem =
		data[isDraggingLower ? dragOverIndex + 1 : dragOverIndex];

	return { draggableItem, dragOverItem };
};

const handleOnchange = (step, url) => {
	if (step === 1) {
		window.location.href = url;
	}
};

export { handleOnExit, handleOnchange, handleReOrdering };
