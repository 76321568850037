import React from "react";
import AuthLayout from "../../layout/Auth";
import {
	AlignCenter,
	AlignRight,
	BottomLink,
	Content,
	ContentMessage,
	ContentTitle,
	Error,
	Input,
	InputWrapper,
	SubmitButton,
} from "./styles";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { signUp } from "../../api/auth";
import { NotificationManager as Alert } from "react-notifications";
import { displayError } from "../../utils/errors";
import regex from "../../config/regex";
import { verifyAuthToken } from "../../api/auth";
import Loader from "../../components/Loader";
import Helmet from "react-helmet";

/**
 *
 */
const SignUpSchema = Yup.object().shape({
	email: Yup.string().email("E-mail not valid").required("E-mail not valid"),
	password: Yup.string()
		.matches(regex.password.value, regex.password.message)
		.required(regex.password.message),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref("password")], "Passwords don't match")
		.required("Required"),
});

/**
 *
 * @constructor
 */
function ConfirmEmail() {
	const history = useHistory();
	const { token } = useParams();
	const [verified, setVerified] = React.useState(null);
	const [loading, setLoading] = React.useState(true);
	/**
	 * Form submit handler
	 */
	const handleSubmit = React.useCallback(
		(values, { setSubmitting }) =>
			signUp({ email: values.email, password: values.password })
				// Successfully registered
				.then(() => {
					history.push("/");

					return Alert.success(
						"Check your email to confirm your email address",
						"Success"
					);
				})
				// Handle error
				.catch(displayError)
				// Finish form submitting
				.finally(() => setSubmitting(false)),
		[history]
	);
	React.useEffect(() => {
		const response = validate(token).then(res => {
			setVerified(res);
		});
	}, [token]);
	async function validate(token) {
		if (!token) {
			return false;
		}

		try {
			const { status } = await verifyAuthToken(token);
			// const status = 200;
			setLoading(false);
			return status === 200;
		} catch (err) {
			setLoading(false);
			return false;
		}
	}
	return (
		<AuthLayout>
			<Helmet>
				<title>Augmento.com - Confirm Email</title>
			</Helmet>
			{loading ? (
				<Loader />
			) : (
				<>
					{verified ? (
						<Content
							style={{
								background: "transparent",
								color: "#fff",
							}}
						>
							<ContentMessage>
								You are successfully verified.{" "}
								<a
									href="/auth/signin"
									style={{
										textDecoration: "underline",
										cursor: "pointer",
										color: "#ff5757",
									}}
								>
									Go to sign in page
								</a>
							</ContentMessage>
						</Content>
					) : (
						<Content
							style={{
								background: "#bd362f",
								color: "#fff",
							}}
						>
							<ContentMessage>Invalid token!</ContentMessage>
						</Content>
					)}
				</>
			)}
		</AuthLayout>
	);
}

export default ConfirmEmail;
