import { CustomCheckbox, Wrapper, Input } from "./style";

function Checkbox({ title, value, onChange, checked, name }) {
	return (
		<Wrapper>
			<Input
				type="checkbox"
				name={name}
				value={value}
				onChange={onChange}
				checked={checked}
			/>
			<CustomCheckbox />
			<span>{title}</span>
		</Wrapper>
	);
}

export default Checkbox;
