import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	color: #fff;
	text-decoration: none;
	cursor: pointer;
	margin-top: 48px;
`;

export const Icon = styled.div`
	margin-right: 15px;
`;
export const Text = styled.div`
	font-size: 18px;
`;
