import styled from "styled-components";

export const BackButton = styled.button`
	background: none;
	border: none;
	width: max-content;
	height: 30px;
	cursor: pointer;
	display: flex;
	align-items: center;
	font-weight: bold;
	margin-bottom: 2rem;
	position: absolute;
	top: 12px;
	left: 12px;

	&:hover {
		color: linear-gradient(to bottom, rgb(36, 40, 40), rgb(14, 63, 73));
		opacity: 80%;
	}
`;

export const BackText = styled.span`
	font-size: small;
	line-height: 20px;
	margin-left: 8px;
`;

export const ContentPosition = styled.section`
	padding: 32px 0px;
`;

export const HeaderTextPosition = styled.span`
	display: flex;
	align-items: center;
`;

export const ImageLabel = styled.label`
	margin-left: 16px;
	font-size: 24px;
`;
