import styled, { keyframes } from "styled-components";

export const LoadingWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const BounceAnimation = keyframes`
  0% { 
    margin-bottom: 0; 
  }

  50% { 
    margin-bottom: 1.5rem;
  }

  100% { 
    margin-bottom: 0;
  }
`;

export const Dot = styled.div`
	background-color: ${props => props.bg || "black"};
	border-radius: 50%;
	width: 0.75rem;
	height: 0.75rem;
	margin: 0 0.25rem;
	/*Animation*/
	animation: ${BounceAnimation} 0.8s linear infinite;
	animation-delay: ${props => props.delay};
`;
