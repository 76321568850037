import { Error, InputWrapper } from "../../pages/auth/styles";
import { Field, ErrorMessage } from "formik";
import { Input } from "../../styles/ui";
import OptionSelector from "../ProductCategorySelect/ProductCategorySelect";
import { H4, TitleSection, FieldWrapper } from "./styles/ProductForm.styles";

function FormTitleCategory() {
	return (
		<TitleSection>
			<FieldWrapper>
				<H4 for="title">Title</H4>
				<InputWrapper>
					<Field
						name="title"
						as={Input}
						placeholder={"Product name"}
					/>

					<ErrorMessage name={"title"} component={Error} />
				</InputWrapper>
			</FieldWrapper>
			<FieldWrapper>
				<H4>Product category</H4>
				<OptionSelector
					name="category"
					placeholder="Choose category.."
				/>
			</FieldWrapper>
		</TitleSection>
	);
}

export default FormTitleCategory;
