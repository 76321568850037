import React from "react";
import { getAuthDataFromLocalStorage } from "../../utils/authToken";
import { createProduct } from "../../api/products";
import { NotificationManager as Alert } from "react-notifications";
import { displayError } from "../../utils/errors";
import { useHistory } from "react-router-dom";
import { Steps } from "intro.js-react";
import useOnboarding from "../../hooks/useOnboarding";
import { useEffect } from "react";
import { handleOnExit } from "../../utils/products";
import ProductsContentWrapper from "../../components/Products/ProductsContentWrapper";
import CreateProductForm from "../../components/Products/CreateProductForm";
import {
	initialValueFloorProduct,
	initialValueWallProduct,
} from "../../components/Products/InitailValues";
import CreateProductHeader from "../../components/Products/CreateProductHeader";
import floor from "../../images/floor-icon.svg";
import wall from "../../images/wall-icon.svg";

function CreateProduct() {
	const history = useHistory();
	const state = JSON.parse(localStorage.getItem("pageState"));
	const { onboarding, setOnboarding, isAlreadyOnboarded } = useOnboarding([
		{
			title: "Link variations",
			element: ".product-variation",
			intro: "If your product has variations, you can link them here",
		},
		{
			element: ".thumbnail",
			intro: "Upload a thumbnail here to get started. You can crop your thumbnail to make sure your card looks perfect",
		},
		{
			element: ".external-link",
			intro: "Provide a link to where customers can buy the product or see more information",
		},
		{
			element: ".tag-area",
			intro: "Tags make it easier to find products (for example: colors, finishes)",
		},
		{
			element: "#generate-model",
			intro: "Click here to generate a 3D hanging model from an image",
		},
	]);
	/**
	 * Form submit handler
	 */
	const handleSubmit = React.useCallback(
		(values, { setSubmitting }) => {
			const { companyId } = getAuthDataFromLocalStorage();
			const formData = new FormData();
			for (let key in values) {
				if (
					typeof values[key] === "object" &&
					!(values[key] instanceof File) &&
					!Array.isArray(values[key])
				)
					continue;

				if (key === "link" && !values[key]) {
					continue;
				}

				const value = Array.isArray(values[key])
					? values[key]
					: values[key];
				if (key !== "variations") {
					formData.append(key, value);
				}
			}
			if (values["artworkImage"]) {
				formData.delete("arWidth");
				formData.delete("arHeight");
				formData.delete("typeOfFrame");
				formData.delete("frameWidth");
				formData.delete("frameThickness");
				formData.append("artworkProps[arWidth]", values["arWidth"]);
				formData.append("artworkProps[arHeight]", values["arHeight"]);
				formData.append(
					"artworkProps[typeOfFrame]",
					values["typeOfFrame"]
				);
				formData.append(
					"artworkProps[frameWidth]",
					values["frameWidth"]
				);
				formData.append(
					"artworkProps[frameThickness]",
					values["frameThickness"]
				);
			} else {
				formData.delete("arWidth");
				formData.delete("arHeight");
				formData.delete("typeOfFrame");
				formData.delete("frameWidth");
				formData.delete("frameThickness");
			}
			if (values["tags"].length <= 0) {
				formData.delete("tags");
			}
			if (values["variations"].length <= 0) {
				formData.delete("variations");
			}
			if (values["variations"].length > 0) {
				formData.delete("variations");
				values["variations"].forEach(val => {
					formData.append("variations[]", val);
				});
			}
			if (values["tags"].length > 0) {
				formData.delete("tags");
				values["tags"].forEach(val => {
					formData.append("tags[]", val);
				});
			}
			formData.delete("useExisting");
			formData.delete("useLink");
			formData.delete("productType");
			formData.delete("viewMode");
			formData.delete("parent");
			createProduct(companyId, formData)
				// Successfully updated
				.then(() => {
					history.push("/products");

					return Alert.success(
						`Product '${values.title}' has been created!`,
						"Success"
					);
				})
				// Handle error
				.catch(displayError)
				// Finish form submitting
				.finally(() => setSubmitting(false));
		},
		[history]
	);

	const { stepsEnabled, steps, initialStep } = onboarding;
	const handleOnBeforeChange = currentStep => {
		if (currentStep === 3 && state.type !== 2) {
			window.location.href = "/products/add?onboarding=true";
			localStorage.setItem("pageState", JSON.stringify({ type: 2 }));
		}
	};

	const handleOnbeforeChange = currentStep => {
		if (currentStep === 4) {
			document.getElementById("generate-model").click();
		}
	};

	const handleOnComplete = () => {
		localStorage.setItem("isOnboarded", true);
	};

	useEffect(() => {
		if (state.type === 2 && !isAlreadyOnboarded) {
			setOnboarding({
				...onboarding,
				initialStep: 3,
				stepsEnabled: true,
			});
		}
	}, []);

	return (
		<>
			<Steps
				enabled={stepsEnabled}
				steps={steps}
				initialStep={initialStep}
				onExit={handleOnExit}
				options={{
					doneLabel: "Done",
				}}
				onAfterChange={handleOnBeforeChange}
				onBeforeChange={handleOnbeforeChange}
				onComplete={handleOnComplete}
			/>
			<ProductsContentWrapper helmet="Augmento.com - Create product">
				{state && state.type && state.type === 1 && (
					<CreateProductForm
						pageHeader={
							<CreateProductHeader
								image={floor}
								title="Add new floor product"
							/>
						}
						onSubmit={handleSubmit}
						initialValues={initialValueFloorProduct(state.type)}
					/>
				)}
				{state && state.type && state.type === 2 && (
					<CreateProductForm
						pageHeader={
							<CreateProductHeader
								image={wall}
								title="Add new wall product"
							/>
						}
						onSubmit={handleSubmit}
						initialValues={initialValueWallProduct(state.type)}
					/>
				)}
			</ProductsContentWrapper>
		</>
	);
}

export default CreateProduct;
