import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const BANNER_HEIGHT = 36;

const useStyles = makeStyles(theme => ({
	paper: {
		position: "sticky",
		top: 80 + BANNER_HEIGHT, // offset for the banner
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		marginBottom: theme.spacing(2),
		width: "100%",
	},
	input: {
		marginLeft: 8,
		flex: 1,
	},
	iconButton: {
		padding: 10,
	},
}));

/**
 *
 * @constructor
 */
function Search({ onChange }) {
	const classes = useStyles();

	return (
		<Paper className={classes.paper}>
			<IconButton className={classes.iconButton} aria-label="search">
				<SearchIcon />
			</IconButton>

			<InputBase
				autoFocus
				onChange={event => {
					onChange(event.target.value);
				}}
				className={classes.input}
				placeholder="Search icons…"
				inputProps={{ "aria-label": "search icons" }}
			/>
		</Paper>
	);
}

export default Search;
