import styled from "styled-components";

export const Wrapper = styled.div`
	max-height: 500px;
	overflow: auto;
`;

export const List = styled.div`
	margin: 0 0 30px;
`;
