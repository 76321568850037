import styled from "styled-components";

export const FrameSection = styled.section`
	margin-bottom: 12px;
`;

export const FrameInfoSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const InfoWrapper = styled.div`
	background-color: #f6f6f6;
	padding: 0px 12px;
	border-radius: 16px;
	display: flex;
	gap: 16px;

	@media screen and (max-width: 480px) {
		gap: 2px;
		padding: 0;
	}
`;

export const FrameInfo = styled.div`
	display: flex;
	width: max-content;
	align-items: center;
	font-size: small;
	gap: 4px;
	position: relative;

	&:hover .tooltip {
		display: block;
	}

	@media screen and (max-width: 480px) {
		gap: 2px;
		font-size: x-small;
	}
`;

export const FrameItemsWrapper = styled.ul`
	display: flex;
	width: 100%;
	position: relative;
	align-items: center;
	list-style: none;
	gap: 16px;
	margin: 0;
	padding: 12px 0px;

	overflow: hidden;
	height: max-content;
	cursor: grab;
`;

export const FrameItem = styled.li`
	position: relative;
	flex: 0 0 10%;
	transition: transform 0.5s;
	transform: translateX(0%);
	visibility: visible;

	@media screen and (max-width: 480px) {
		flex: 0 0 20%;
	}
`;

export const FrameItemName = styled.h5`
	font-size: small;
	font-weight: 300;
	margin: 0;
	margin-top: 8px;
	text-align: center;
`;

export const FrameItemImage = styled.img`
	cursor: pointer;
	display: block;
	width: 100%;
	height: max-content;
	outline: ${props => props.frameActive && "solid 2px #36b2e5"};
	border: solid 1px ${props => (props.frameActive ? "#36b2e5" : "silver")};

	object-fit: cover;

	&:hover {
		stroke: white;
		outline: solid 2px #36b2e5;
		border: solid 1px #36b2e5;
		box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
			0 2px 4px -2px rgb(0 0 0 / 0.1);
	}
`;

export const FrameIconPosition = styled.img`
	position: absolute;
	top: -5px;
	right: -5px;
	background-color: white;
	padding: 0px 1px;
	border-radius: 12px;
	color: black;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
	-o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
`;
