import { CustomRadio, Input, Wrapper } from "./styles";

function Radio({ name, title, mb, value, state, handleChange, disabled }) {
	return (
		<Wrapper mb={mb}>
			<Input
				type="radio"
				name={name}
				checked={value === state}
				onChange={() => handleChange(value)}
				disabled={disabled}
			/>
			<CustomRadio />
			<span>{title}</span>
		</Wrapper>
	);
}

export default Radio;
