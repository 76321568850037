import React from "react";
import { Button, Wrapper } from "./styles";
import Search from "./Search";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import List from "./List";
import Pagination from "../Pagination";

/**
 *
 * @constructor
 */
function CollectionsList({ data, isLoading, error }) {
	const history = useHistory();
	const location = useLocation();
	const search = qs.parse(location.search);
	const page = +search.page || 1;

	/**
	 * Search input onChange handler
	 */
	const handleSearchChange = React.useCallback(
		e => {
			history.push({
				search: qs.stringify({
					...search,
					search: e.target.value || undefined,
					page: undefined,
				}),
			});
		},
		[history, search]
	);
	/**
	 *
	 * @type {(function(): void)|*}
	 */
	const handleAddCollection = React.useCallback(() => {
		history.push("/collections/add");
	}, [history]);
	/**
	 * Go to next page
	 */
	const handleNext = () => {
		history.push({
			search: qs.stringify({ ...search, page: page + 1 }),
		});
	};

	/**
	 * Go to prev page
	 */
	const handlePrev = () => {
		history.push({
			search: qs.stringify({ ...search, page: page - 1 }),
		});
	};

	if (error)
		return <div>{`An error has occurred: " + ${error.message}`}</div>;

	return (
		<Wrapper>
			<Search
				placeholder={"Search collections.."}
				onChange={handleSearchChange}
				value={search.search}
			/>

			<List data={data} isLoading={isLoading} />
			<Button onClick={handleAddCollection}>{"Add Collection"}</Button>
			<br />

			{!!data?.pages && (
				<Pagination
					page={page}
					totalPages={data?.pages}
					goNext={handleNext}
					goPrev={handlePrev}
				/>
			)}
		</Wrapper>
	);
}

export default CollectionsList;
