import { BrowserRouter, Redirect, Route } from "react-router-dom";
import GuestRoute from "./GuestRoute";
import Auth from "./Auth";
import PrivateRoute from "./PrivateRoute";
import ProductsRouter from "./Products";
import CategoriesRouter from "./Categories";
import CollectionsRouter from "./Collections";
import SearchIcons from "../components/SearchIcons";
import superAdminPages from "../config/superAdminPages";
import pages from "../config/pages";

function Router({ isAuthorized, isSuperAdmin }) {
	return (
		<BrowserRouter>
			<Route exact path="/">
				{isAuthorized ? (
					<>
						{isSuperAdmin ? (
							<Redirect to="/collections" />
						) : (
							<Redirect to="/products" />
						)}
					</>
				) : (
					<Redirect to="/auth/signin" />
				)}
			</Route>
			{/*auth routes*/}
			<GuestRoute
				isAuthorized={isAuthorized}
				path={"/auth"}
				component={Auth}
			/>
			{/*Product routes*/}
			<PrivateRoute
				isAuthorized={isAuthorized}
				path={"/products"}
				component={ProductsRouter}
			/>
			<PrivateRoute
				isAuthorized={isAuthorized}
				path={"/categories"}
				component={CategoriesRouter}
			/>
			<PrivateRoute
				isAuthorized={isAuthorized}
				path={"/search"}
				component={SearchIcons}
			/>
			<PrivateRoute
				isAuthorized={isAuthorized}
				path={"/collections"}
				component={CollectionsRouter}
			/>
			{isSuperAdmin && (
				<>
					{/* Private routes */}
					{superAdminPages.map(page1 => (
						<PrivateRoute
							isAuthorized={isAuthorized}
							key={page1.link}
							exact
							path={page1.link}
							component={page1.component}
						/>
					))}
				</>
			)}{" "}
			{!isSuperAdmin && (
				<>
					{/* Private routes */}
					{pages.map(page => (
						<PrivateRoute
							isAuthorized={isAuthorized}
							key={page.link}
							exact
							path={page.link}
							component={page.component}
						/>
					))}
				</>
			)}
		</BrowserRouter>
	);
}

export default Router;
