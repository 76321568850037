import { removeAuthDataFromLocalStorage } from "./authToken";

/**
 *
 * @param setAuthorizationStatus
 * @param history
 */
export const logoutHandler = (setAuthorizationStatus, history) => {
	removeAuthDataFromLocalStorage();
	setAuthorizationStatus(false);
	history.push("/");
};
