export const initialValueWallProduct = productType => ({
	title: "",
	image: null,
	artworkImage: null,
	model: null,
	link: null,
	category: "",
	currency: "",
	amount: "",
	variations: [],
	parent: null,
	tags: [],
	useExisting: true,
	useLink: false,
	productType,
	placementType: "wall",
	viewMode: "create",
	arWidth: 5,
	arHeight: 5,
	typeOfFrame: null,
	frameWidth: 1,
	frameThickness: 0.1,
});

export const initialValueFloorProduct = productType => ({
	title: "",
	image: null,
	artworkImage: null,
	model: null,
	link: null,
	category: "",
	currency: "",
	amount: "",
	variations: [],
	parent: null,
	tags: [],
	useExisting: true,
	useLink: false,
	productType,
	placementType: "floor",
	viewMode: "create",
	arWidth: null,
	arHeight: null,
	typeOfFrame: null,
	frameWidth: null,
	frameThickness: null,
});
