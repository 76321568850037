import { createGlobalStyle } from "styled-components";
// import EffraFont from "../fonts/effra/Effra_Std_Rg.ttf";
import UbuntuFont from "../fonts/ubuntu/Ubuntu-Regular.ttf";

export const theme = {
	colorMain: "#333641",
	colorGrey: "#979797",
	bg: "#112d41",
};

export const GlobalStyle = createGlobalStyle`
	* {
		box-sizing: border-box;
	}

	@font-face {
		font-family: 'Ubuntu';
		src: url(${UbuntuFont}) format("truetype");
	}
	
	html, body, #root {
		min-height: 100vh;
		margin: 0;
		padding: 0;
	}
	
	#root {
		display: flex;
		
		& > *:not(.notification-container) {
			width: 100%;
			height: auto;
		}
	}
	
  body {
		font-family: 'Ubuntu', sans-serif;
		color: ${props => props.theme.colorMain};
		font-size: 14px;
		background-color: #F0F0F0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'UbuntuFont', sans-serif;
    font-weight: 600;
  }

  input, button {
	  font-family: 'Ubuntu', sans-serif;
  }
`;
