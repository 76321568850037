import MediaUploaderContainer from "../../MediaUploaderContainer";
import { AlignRight } from "../../../pages/auth/styles";
import { ErrorMessage } from "formik";
import {
	TrumbnailSection,
	MediaUploaderSection,
	H4,
	CardsSection,
} from "../styles/ProductForm.styles";
import PreviewCard from "./PreviewCard";

import { Error } from "../../../pages/auth/styles";

function CreateThumbnail({ initialValues, values }) {
	return (
		<TrumbnailSection>
			<MediaUploaderSection>
				<H4>Create thumbnail</H4>
				<MediaUploaderContainer
					mediaType={"image"}
					buttonTitle={"Or click to select image"}
					buttonDescription={"max. size 5 mb"}
					accept={"image/png, image/jpeg"}
					title={"Drop image here"}
					productName={values.title}
					productId={initialValues.productId}
					className="thumbnail"
					frameConfig={{
						width: values.arWidth,
						height: values.arHeight,
						frameWidth: values.frameWidth,
						frameHeight: 1,
						frameThickness: values.frameThickness,
					}}
				/>
				<AlignRight>
					<ErrorMessage name={"image"} component={Error} />
				</AlignRight>
			</MediaUploaderSection>

			<CardsSection>
				<H4>Preview card</H4>
				<PreviewCard model={values.artworkImage} />
			</CardsSection>
		</TrumbnailSection>
	);
}

export default CreateThumbnail;
