import WithSidebar from "../../layout/WithSidebar";
import PageContent from "../../components/PageContent";
import PageHeader from "../../components/PageHeader";
import ProductsList from "../../components/ProductsList";
import { useQuery } from "react-query";
import { getProducts } from "../../api/products";
import { getAuthDataFromLocalStorage } from "../../utils/authToken";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import { Steps } from "intro.js-react";
import useOnboarding from "../../hooks/useOnboarding";
import { useEffect } from "react";
import { handleOnchange, handleOnExit } from "../../utils/products";

function Products() {
	const location = useLocation();
	const history = useHistory();

	const { companyId } = getAuthDataFromLocalStorage();
	const { data, isLoading, error, refetch } = useQuery(
		["products", location.search, location.limit],
		() => getProducts(companyId, location.search)
	);

	const search = qs.parse(location.search);
	const { onboarding, setOnboarding, isAlreadyOnboarded } = useOnboarding([
		{
			element: ".welcome",
			title: "Welcome",
			intro: "Learn in a minute how to use Augmento",
		},
		{
			intro: "",
		},
	]);
	const applyLimit = data => {
		history.push({
			search: qs.stringify({
				...search,
				limit: data.value,
				page: 1,
			}),
		});
	};

	useEffect(() => {
		if (!isLoading && data?.data?.data.length && !isAlreadyOnboarded) {
			setOnboarding({
				...onboarding,
				stepsEnabled: true,
			});
		}
	}, [isLoading]);

	const { stepsEnabled, steps, initialStep } = onboarding;
	const nextOnboardingStep = "/settings?onboarding=true";

	return (
		<>
			<Steps
				enabled={stepsEnabled}
				steps={steps}
				initialStep={initialStep}
				onExit={handleOnExit}
				onChange={step => handleOnchange(step, nextOnboardingStep)}
			/>
			<WithSidebar>
				<PageHeader
					title={"Products"}
					totalProducts={100}
					usedProducts={data ? data.data.data.length : 0}
					showCounter
					showPageSize={true}
					limit={data ? data.data.pagination.limit : 25}
					changeLimit={applyLimit}
				/>

				<PageContent>
					<ProductsList
						data={data?.data}
						error={error}
						isLoading={isLoading}
						refetch={refetch}
					/>
				</PageContent>
			</WithSidebar>
		</>
	);
}

export default Products;
