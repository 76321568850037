import { Route } from "react-router-dom";
import CreateCategory from "../pages/categories/CreateCategory";
import CreatePublicCategory from "../pages/categories/CreatePublicCategory";
import EditCategory from "../pages/categories/EditCategory";
import EditPublicCategory from "../pages/categories/EditPublicCategory";

function Categories() {
	return (
		<>
			<Route exact path="/categories/add" component={CreateCategory} />
			<Route
				exact
				path="/categories/public/add"
				component={CreatePublicCategory}
			/>
			<Route exact path="/categories/edit/:id" component={EditCategory} />
			<Route
				exact
				path="/categories/public/edit/:id"
				component={EditPublicCategory}
			/>
		</>
	);
}

export default Categories;
