import { useHistory, useLocation } from "react-router-dom";

function useToggleSort() {
	const { search } = useLocation();
	const history = useHistory();

	const params = new URLSearchParams(search);
	const currentSortBy = params.get("sortBy");
	const currentSortValue = params.get("sortValue");

	return sortBy => {
		if (currentSortBy === sortBy) {
			params.set(
				"sortValue",
				currentSortValue === "DESC" ? "ASC" : "DESC"
			);
		} else {
			params.set("sortBy", sortBy);
			params.set("sortValue", "DESC");
		}

		history.push({ search: params.toString() });
	};
}

export default useToggleSort;
