import styled from "styled-components";
import { PrimaryButton } from "../../styles/ui";
import { Link } from "react-router-dom";
import MontserratFont from "../../fonts/montserrat/Montserrat-VariableFont_wght.ttf";

export const Content = styled.div`
	max-width: 337px;
	width: 100%;
	padding: 38px 28px;
	border-radius: 16px;
	background-color: #fff;
`;

export const LoginButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1.5px solid #c4c4c4;
	border-radius: 25px;
	width: 100%;
	padding: 12px;
	background: #ffffff;
	cursor: pointer;
	font-family: "Montserrat";
`;

export const Img = styled.img`
	width: 20px;
	height: 20px;
	margin-right: 10px;
`;

export const ContentTitle = styled.h4`
	text-align: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 14px;
	text-align: center;
	color: #000000;
	margin: 0 0 32px;
	font-family: "Montserrat";

	@font-face {
		font-family: "Montserrat";
		src: url(${MontserratFont}) format("truetype");
	}
`;
export const ContentMessage = styled.h2`
	text-align: center;
	margin: 0 0;
`;

export const Input = styled.input`
	display: block;
	font-family: "Montserrat";
	width: 100%;
	height: 41px;
	border: none;
	border-radius: 6px;
	padding: 0 30px;
	color: #979797;
	outline: none;
	box-shadow: unset;
	background-color: white;
	margin-bottom: ${props => props.mb || 23}px;
`;

export const SubmitButton = styled(PrimaryButton)`
	width: 100%;
	margin: 0 0 16px;
`;

export const AlignRight = styled.div`
	width: fit-content;
	display: flex;
	justify-content: flex-end;
`;

export const AlignLeft = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-start;
`;

export const AlignCenter = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

export const BottomLink = styled(Link)`
	font-size: 12px;
	font-weight: 700;
	color: ${props => props.theme.colorMain};
	text-decoration: none;
	margin-top: 20px;
`;

export const ForgetLink = styled(Link)`
	text-decoration-line: underline;
	font-size: 11px;
	color: ${props => props.theme.colorGrey};
	margin: 0 0 30px;
`;

export const Error = styled.p`
	font-size: 10px;
	color: #f00;
	width: 100%;
`;

export const InputWrapper = styled.div`
	margin-bottom: ${props => props.mb || 23}px;
`;
