import React, { useMemo, useState, useEffect } from "react";
import FlexBox from "../FlexBox";
import FlexGrow from "../FlexGrow";
import Modal from "../Modal";
import { ReactComponent as Close } from "../../images/icons/close.svg";
import { PrimaryButton } from "../../styles/ui";
import { Cancel, CenterAlign, RightAlign } from "./styles";

const ConfirmModal = ({ isOpen, setOpen, deleteConfirmed, confirmText }) => {
	const [isOpenComplete, setOpenComplete] = useState(false);
	const [isOpenError, setOpenError] = useState(false);
	const [isLoading, setLoading] = useState(false);
	return (
		<>
			<Modal
				isOpen={isOpen || false}
				setOpen={setOpen}
				customStyle={{ content: { width: "401px", height: "254px" } }}
			>
				<FlexBox justify="flex-end">
					<FlexGrow>
						<RightAlign>
							<Close
								style={{ cursor: "pointer" }}
								onClick={() => setOpen(false)}
							/>
						</RightAlign>
					</FlexGrow>
				</FlexBox>
				<FlexBox>
					<FlexGrow>
						<CenterAlign>
							<h3
								style={{
									marginBottom: 0,
									color: "#333641",
									fontSize: "18px",
									fontWeight: "700",
								}}
							>
								Are you sure?
							</h3>
						</CenterAlign>
					</FlexGrow>
				</FlexBox>
				<CenterAlign>
					<p
						style={{
							width: "253px",
							color: "#000000",
							fontSize: "16px",
							fontWeight: "400",
							textAlign: "center",
						}}
					>
						{confirmText}
					</p>
				</CenterAlign>
				<FlexBox mb={12}>
					<FlexGrow>
						<CenterAlign>
							<PrimaryButton
								style={{ width: "166px" }}
								onClick={() => deleteConfirmed()}
							>
								{!isLoading && <>Delete</>}
							</PrimaryButton>
						</CenterAlign>
					</FlexGrow>
				</FlexBox>
				<FlexBox>
					<FlexGrow>
						<CenterAlign>
							<Cancel
								isOutline
								onClick={() => {
									setOpen(false);
								}}
							>
								Cancel
							</Cancel>
						</CenterAlign>
					</FlexGrow>
				</FlexBox>
			</Modal>
		</>
	);
};

export default ConfirmModal;
