import React from "react";
import { getDataUrlFromFile } from "../../utils/images";

function Preview({ source }) {
	const [image, setImage] = React.useState(null);

	React.useEffect(() => {
		if (source.url) {
			return setImage(source.url);
		}

		getDataUrlFromFile(source)
			.then(data => setImage(data))
			.catch(e => console.log(e));
	}, [source]);

	return <img src={image} height={62} alt="" />;
}

export default Preview;
