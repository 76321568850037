import { useState } from "react";
import {
	NavContainer,
	NavInnerContainer,
	NavLeftContainer,
	NavOpenLinkButton,
	NavRightContainer,
	NavExtendedContainer,
	NavLogo,
} from "./Navbar.style";

import Navigation from "../Navigation";
import { XMarkIcon, Bars3Icon } from "@heroicons/react/24/solid";
import logo from "../../images/logo2.svg";

function Navbar() {
	const [extendNavbar, setExtendNavbar] = useState(false);

	return (
		<NavContainer extendNavbar={extendNavbar}>
			<NavInnerContainer>
				<NavLeftContainer>
					<NavOpenLinkButton
						onClick={() => setExtendNavbar(currVal => !currVal)}
					>
						{extendNavbar ? <XMarkIcon /> : <Bars3Icon />}
					</NavOpenLinkButton>
				</NavLeftContainer>
				<NavRightContainer>
					<NavLogo src={logo} />
				</NavRightContainer>
			</NavInnerContainer>

			<NavExtendedContainer extendNavbar={extendNavbar}>
				<Navigation isNavbar={true} />
			</NavExtendedContainer>
		</NavContainer>
	);
}

export default Navbar;
