import { useEffect, useState } from "react";
import {
	CardsWrapper,
	Card,
	CardImage,
	CardEmptyField,
	CardTitle,
	CardLearnMore,
	CardInfoSection,
} from "../styles/ProductForm.styles";
import { getDataUrlFromFile } from "../../../utils/images";

function PreviewCard({ model }) {
	const [image, setImage] = useState(null);
	const [dataURL, setDataURL] = useState('');
	useEffect(() => {
		async function setNewImage() {
			if (model) {
				// const data = await getDataUrlFromFile(model);
				loadImageAsDataURL(model.url)
				setImage(dataURL);
			} else {
				setImage(null);
			}
		}
		setNewImage();
	}, [model]);
	const loadImageAsDataURL = (url) => {
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		const image = new Image();
		image.crossOrigin = 'Anonymous';
	
		image.onload = () => {
		  canvas.width = image.width;
		  canvas.height = image.height;
		  ctx.drawImage(image, 0, 0);
	
		  const dataUrl = canvas.toDataURL('image/jpeg'); // Change 'image/jpeg' to desired format
		  setDataURL(dataUrl);
		};
	
	 return	image.src = url;
	  };
	return (
		<CardsWrapper>
			<Card image={image}>
				{image ? <CardImage src={image} /> : <CardEmptyField />}
				<CardInfoSection>
					<CardTitle>Title</CardTitle>
					<CardLearnMore>Learn More</CardLearnMore>
				</CardInfoSection>
			</Card>
			<Card image={image}>
				{image ? <CardImage src={image} /> : <CardEmptyField />}
				<CardInfoSection>
					<CardTitle>Title</CardTitle>
				</CardInfoSection>
			</Card>
		</CardsWrapper>
	);
}

export default PreviewCard;
