import styled from "styled-components";
import { PrimaryButton } from "../../styles/ui";

export const Wrapper = styled.div``;

export const ButtonContainer = styled.div`
	display: flex;
	margin-top: 20px;
	align-items: center;
`;

export const Button = styled(PrimaryButton)`
	height: 22px;
	font-size: 10px;
	margin-right: 14px;
`;

export const Description = styled.div`
	font-size: 10px;
	color: #333641;
`;

export const FileInput = styled.input`
	visibility: hidden;
	position: absolute;
	width: 0;
	height: 0;
	z-index: -1;
`;

export const CropImageSection = styled.section`
	display: flex;
	justify-content: center;

	margin: 36px 0;
`;

export const OptionsSection = styled.section`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 10px;
	grid-auto-rows: minmax(30px, auto);

	@media screen and (max-width: 580px) {
		grid-template-columns: repeat(2, 1fr);
	}
`;

export const ButtonPosition = styled.div`
	grid-column: 3;
	text-align: end;

	@media screen and (max-width: 580px) {
		grid-column: 2;
	}
`;

export const ModalSaveBtn = styled.button`
	width: max-content;
	padding: 12px 30px;
	border-radius: 8px;
	color: white;
	background-color: #333541;
	cursor: pointer;
	font-size: smaller;

	box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
	-moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
	-webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
	-o-box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
`;

export const CropOptionsPosition = styled.div`
	grid-column: 2;

	display: flex;
	justify-content: space-around;
	align-items: center;

	@media screen and (max-width: 580px) {
		grid-column: 1;
	}
`;

export const OptionButton = styled.button`
	width: max-content;
	height: max-content;
	background: none;
	cursor: pointer;

	border: 0;
	margin: 0;
	padding: 0;
`;

export const FlipIcon = styled(OptionButton)`
	transform: scale(-1, 1);
	-moz-transform: scale(-1, 1);
	-webkit-transform: scale(-1, 1);
	-o-transform: scale(-1, 1);
	-ms-transform: scale(-1, 1);
	transform: scale(-1, 1);
`;
