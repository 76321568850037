import React from "react";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyle } from "./styles/global";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import Router from "./router";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { getAdminType, getAuthorizationStatus } from "./utils/authToken";
import AuthContext from "./contexts/auth";
import Loader from "./components/Loader";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./fonts/effra/Effra_Std_Rg.ttf";
import "intro.js/introjs.css";

import "swiper/css";

export const queryCache = new QueryCache({
	onError: error => {
		console.log(error);
	},
});
const queryClient = new QueryClient();
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const App = () => {
	const [isAuthorized, setIsAuthorized] = React.useState(false);
	const [isSuperAdmin, setIsSuperAdmin] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const clientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

	function parse(type) {
		return typeof type == "string" ? JSON.parse(type) : type;
	}

	React.useEffect(() => {
		(async () => {
			const status = await getAuthorizationStatus();
			setIsAuthorized(status);
			const type = await getAdminType();
			setIsSuperAdmin(parse(type));
			setLoading(false);
		})();
	}, []);

	if (loading) {
		return (
			<ThemeProvider theme={theme}>
				<Loader />
				<GlobalStyle />
			</ThemeProvider>
		);
	}

	return (
		<ThemeProvider theme={theme}>
			<QueryClientProvider client={queryClient}>
				<GoogleOAuthProvider clientId={clientID}>
					<GlobalStyle />

					<AuthContext.Provider
						value={{
							isAuthorized,
							setIsAuthorized,
							isSuperAdmin,
							setIsSuperAdmin,
						}}
					>
						<Elements stripe={stripePromise}>
							<Router
								isAuthorized={isAuthorized}
								isSuperAdmin={isSuperAdmin}
							/>
						</Elements>
					</AuthContext.Provider>

					<NotificationContainer />
				</GoogleOAuthProvider>
			</QueryClientProvider>
		</ThemeProvider>
	);
};

export default App;
