import styled from "styled-components";

export const PrimaryButton = styled.button`
	height: ${props => props.height || 44}px;
	padding: 0 30px;
	border: none;
	outline: none;
	cursor: pointer;
	background: linear-gradient(270deg, #43bbff 0%, #3676f1 100%);
	box-shadow: 0 15px 30px rgba(20, 102, 204, 0.16);
	border-radius: 25px;
	font-size: ${props => props.fz || 14}px;
	font-weight: 700;
	color: #fff;
	opacity: ${props => (props.disabled ? 0.5 : 1)};
`;

export const PinkButton = styled.button`
	height: ${props => props.height || 30}px;
	padding: 0 14px;
	border: none;
	outline: none;
	cursor: pointer;
	background: #ff00b8;
	border-radius: 55px;
	font-size: ${props => props.fz || 14}px;
	font-weight: 700;
	color: #fff;
	opacity: ${props => (props.disabled ? 0.5 : 1)};

	svg {
		margin-right: 8px;
	}
`;

export const Input = styled.input`
	display: block;
	width: ${props => (props.width ? props.width + "px" : "100%")};
	height: ${props => props.height || 40}px;
	margin-bottom: ${props => props.mb || 0}px;
	padding: 0 15px;
	border-radius: 5px;
	outline: none;

	font-size: ${props => props.fz || 16}px;
	color: #333641;
	border: solid 1px #cbcbcb;
	background-color: white;
	box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
		0 4px 6px -4px rgb(0 0 0 / 0.1);

	&:disabled {
		display: none;
	}

	&::placeholder {
		color: #c5c5c5;
	}
`;

export const Textarea = styled.textarea`
	max-width: 518px;
	width: 100%;
	background: rgba(0, 0, 0, 0.05);
	border-radius: 6px;
	padding: 10px;
	border: none;
	outline: none;
	box-shadow: none;
	font-size: ${props => props.fz || 16}px;
	color: #333641;
	resize: none;

	&::placeholder {
		color: #c5c5c5;
	}
`;

export const H2 = styled.h2`
	font-weight: 700;
	font-size: 21px;
	color: #2c4758;
	margin: 0 0 ${props => props.mb || 0}px;
`;

export const H3 = styled.h3`
	font-size: 16px;
	color: #333641;
	font-weight: 400;
	margin: 0 0 ${props => props.mb || 0}px;
`;

export const Dark = styled.button`
	height: max-content;
	padding: 14px 48px;
	border: none;
	outline: none;
	cursor: pointer;
	background: #333541;
	box-shadow: 0 15px 30px rgba(20, 102, 204, 0.16);
	border-radius: 8px;
	font-size: ${props => props.fz || 14}px;
	color: #fff;
	opacity: ${props => (props.disabled ? 0.5 : 1)};
`;
