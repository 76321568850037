import React from "react";
import Tabs from "../Tabs";
import PlansList from "../PlansList";
import Payment from "./Payment";

function CardForm() {
	const [plan, setPlan] = React.useState(null);

	const plans = React.useMemo(
		() => [
			{
				title: "Monthly",
				key: "monthly",
				component: <PlansList selectPlan={setPlan} type={"monthly"} />,
			},
			{
				title: "Yearly",
				key: "yearly",
				component: <PlansList selectPlan={setPlan} type={"yearly"} />,
			},
		],
		[]
	);
	const [active, setActive] = React.useState(plans[0]);
	return (
		<>
			<Tabs data={plans} active={active} setActive={setActive} />

			<Payment plan={plan} />
		</>
	);
}

export default CardForm;
