import { ColumnTitle, NotFound, Preloader, Table } from "./styles";
import Row from "./Row";
import { ReactComponent as Loader } from "../../images/icons/loader.svg";
import { ReactComponent as SortIcon } from "../../images/icons/sort.svg";
import useToggleSort from "../../hooks/useToggleSort";

/**
 *
 * @constructor
 */
function List({ data, isLoading }) {
	const toggleSort = useToggleSort();

	if (isLoading) {
		return (
			<Preloader>
				<Loader />
			</Preloader>
		);
	}

	return data?.length ? (
		<Table>
			<ColumnTitle pl={25}>{"Name"}</ColumnTitle>
			<ColumnTitle>{"Category"}</ColumnTitle>
			<ColumnTitle>{"Company"}</ColumnTitle>
			<ColumnTitle>{""}</ColumnTitle>
			<ColumnTitle>{"Visible"}</ColumnTitle>
			<ColumnTitle>{"Delete"}</ColumnTitle>

			{data.map(collection => (
				<Row key={collection.id} collection={collection} />
			))}
		</Table>
	) : (
		<NotFound>Nothing found..</NotFound>
	);
}

export default List;
