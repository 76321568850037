import api from "./index";

export const createCollection = data => api.post(`/v1/collections`, data);

export const getCollections = (companyId, query) => {
	return api.get(`/v1/collections${query}`);
};

export const setCollectionVisibility = (collectionId, isVisible) => {
	return api.patch(`/v1/collections/${collectionId}`, isVisible);
};

export const getCollection = collectionId =>
	api.get(`/v1/collections/${collectionId}`);

export const updateCollection = (collectionId, data) =>
	api.patch(`/v1/collections/${collectionId}`, data);

export const deleteCollection = collectionId =>
	api.delete(`/v1/collections/${collectionId}`);
