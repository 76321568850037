import Products from "../pages/products/Products";
import Categories from "../pages/categories/Categories";
import Plan from "../pages/Plan";
import Texts from "../pages/Texts";
import Settings from "../pages/Settings";
import Support from "../pages/Support";
import { ReactComponent as Cat } from "../images/icons/cat.svg";
import { ReactComponent as Star } from "../images/icons/star.svg";
import { ReactComponent as Pack } from "../images/icons/package.svg";
import { ReactComponent as Chat } from "../images/icons/chat.svg";
import { ReactComponent as Box } from "../images/icons/boxnav.svg";
import { ReactComponent as SupportIcon } from "../images/icons/support.svg";
import { ReactComponent as SettingsIcon } from "../images/icons/settings.svg";
import Collections from "../pages/collections/Collections";

export const pages = [

	{
		title: "Categories",
		link: "/categories",
		icon: Cat,
		component: Categories,
	},
	{
		title: "Products",
		link: "/products",
		icon: Star,
		component: Products,
	},
	{
		title: "Plan",
		link: "/plan",
		icon: Pack,
		component: Plan,
	},
	{
		title: "Texts",
		link: "/texts",
		icon: Chat,
		component: Texts,
	},
	{
		title: "Support",
		link: "/support",
		icon: SupportIcon,
		component: Support,
	},
	{
		title: "Settings",
		link: "/settings",
		icon: SettingsIcon,
		component: Settings,
	},
];

export default pages;
