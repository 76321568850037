import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Formik } from "formik";
import * as Yup from "yup";
import {
	NotificationManager as Alert,
	NotificationManager,
} from "react-notifications";

import WithSidebar from "../../layout/WithSidebar";
import PageHeader from "../../components/PageHeader";
import Loader from "../../components/Loader";
import { Wrapper } from "../../layout/ProductEdit/styles";
import { displayError } from "../../utils/errors";
import regex from "../../config/regex";
import PageContentOverflow from "../../components/PageContentOverflow";
import FlexBox from "../../components/FlexBox";
import FlexGrow from "../../components/FlexGrow";
import { ReactComponent as Back } from "../../images/icons/back.svg";
import { getCollection, updateCollection } from "../../api/collections";
import CollectionForm from "../../components/CollectionForm";
import convertToArrayOfStrings from "../../utils/convertToArrayOfStrings";
import { loadXHR } from "../../utils/images";
import Helmet from "react-helmet";

/**
 *
 */
const EditCollectionSchema = Yup.object().shape({
	title: Yup.string()
		.matches(regex.title.value, regex.title.message)
		.required(regex.title.message),
	categories: Yup.array().required("Required"),
	collectionId: Yup.string().strip(),
	companies: Yup.array(),
});

/**
 *
 * @param productName
 * @returns {JSX.Element}
 * @constructor
 */
function EditCollection({ productName = "Chair Evy" }) {
	const params = useParams();
	const history = useHistory();

	const { data, isLoading, error } = useQuery(
		["collection", params.id],
		() => getCollection(params.id),
		{
			cacheTime: 1,
		}
	);

	/**
	 * Form submit handler
	 */
	const handleSubmit = React.useCallback(
		(values, { setSubmitting }) => {
			if (!data) {
				return null;
			}

			const {
				data: { data: collection },
			} = data;

			const formData = new FormData();

			for (let key in values) {
				//dont include objects in form data
				if (
					typeof values[key] === "object" &&
					!(values[key] instanceof File) &&
					!Array.isArray(values[key]) &&
					key !== "link"
				) {
					continue;
				}

				if (key === "collectionId") {
					continue;
				}
				const value = Array.isArray(values[key])
					? values[key].join(", ")
					: values[key];

				formData.append(key, value);
			}
			updateCollection(collection.id, formData)
				// Successfully updated
				.then(() => {
					history.push("/collections");

					return Alert.success(
						`Collection '${collection.title}' saved!`,
						"Success"
					);
				})
				// Handle error
				.catch(displayError)
				// Finish form submitting
				.finally(() => setSubmitting(false));
		},
		[data]
	);

	if (isLoading) {
		return <Loader />;
	}

	if (error) {
		NotificationManager.error(error.message, "Error");

		history.push("/collections");
	}

	const {
		data: { data: collection },
	} = data;

	return (
		<WithSidebar>
			<Helmet>
				<title>Augmento.com - Edit collection</title>
			</Helmet>
			<PageHeader title={`Editing '${collection.title}'`} />

			<PageContentOverflow>
				<Wrapper>
					<Formik
						component={CollectionForm}
						initialValues={{
							collectionId: collection.id,
							title: collection.title,
							categories: convertToArrayOfStrings(
								collection.categories,
								"id"
							),
						}}
						validationSchema={EditCollectionSchema}
						onSubmit={handleSubmit}
					/>
				</Wrapper>
			</PageContentOverflow>
		</WithSidebar>
	);
}

export default EditCollection;
