import React from "react";
import { Wrapper } from "./style";
import Checkbox from "../Checkbox";
import { useQuery } from "react-query";
import { getProducts } from "../../api/products";
import { getAuthDataFromLocalStorage } from "../../utils/authToken";
import { Field } from "formik";
import Loader from "../Loader";
import { Item } from "./style";
import Search from "../ProductsList/Search";
import qs from "query-string";
import Pagination from "../Pagination";

/**
 *
 * @constructor
 */
function LinkedProducts({ excludeProduct }) {
	const { companyId } = getAuthDataFromLocalStorage();
	const [search, setSearch] = React.useState("");
	const [page, setPage] = React.useState(1);

	const queryParams = qs.stringify({
		search: search || undefined,
		page,
		exclude: excludeProduct,
		scope: "linked",
	});

	React.useEffect(() => {
		setPage(1);
	}, [search]);

	/**
	 * Load products
	 */
	const {
		data: list,
		isLoading,
		error,
	} = useQuery(
		["linkedProducts", search, page],
		() => getProducts(companyId, `?${queryParams}`),
		{
			select: data => data?.data,
		}
	);

	if (isLoading) {
		return (
			<Wrapper>
				<Loader />
			</Wrapper>
		);
	}

	if (!list || !list.data || error) return null;

	return (
		<>
			<Search value={search} onChange={e => setSearch(e.target.value)} />

			<Wrapper>
				{!list.data.length && <div>No items</div>}

				{list.data.map(product => (
					<Item>
						<Field
							name="variations"
							type="checkbox"
							value={product.id}
							as={Checkbox}
							title={product.title}
							key={product.id}
						/>
					</Item>
				))}
			</Wrapper>

			{!!list.pages && (
				<Pagination
					totalPages={list.pages}
					page={page}
					goNext={() => setPage(prev => prev + 1)}
					goPrev={() => setPage(prev => prev - 1)}
				/>
			)}
		</>
	);
}

export default LinkedProducts;
