import styled from "styled-components";
import { Link } from "react-router-dom";
import { darken } from "polished";

export const NavContainer = styled.nav`
	width: 100%;
	height: 72px;
	display: flex;
	flex-direction: column;
	position: relative;

	@media screen and (min-width: 1024px) {
		display: none;
	}
`;

export const NavLeftContainer = styled.div`
	flex: 30%;
	display: flex;
	align-items: center;
	padding-left: 5%;
`;

export const NavRightContainer = styled.div`
	flex: 70%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 5%;
`;
export const NavInnerContainer = styled.div`
	position: relative;
	width: 100%;
	height: 72px;
	display: flex;
	z-index: 10;
	background: linear-gradient(to bottom, rgb(36, 40, 40), rgb(14, 63, 73));
`;

export const NavLinkContainer = styled.div`
	display: flex;
`;

export const NavOpenLinkButton = styled.button`
	width: 40px;
	height: 40px;
	background: none;
	border: none;
	color: white;
	cursor: pointer;
`;

export const NavExtendedContainer = styled.div`
	position: absolute;
	top: ${props => (props.extendNavbar ? "72px" : "-800px")};
	display: flex;
	flex-direction: column;
	align-items: start;
	transition: transform 0.2s ease-out;
	background: linear-gradient(to bottom, rgb(14, 63, 73), rgb(13, 63, 74));
	width: 100%;
	border-bottom-right-radius: 16px;
	border-bottom-left-radius: 16px;
	padding-bottom: 30px;
	transition: all 0.7s ease-out;
	z-index: 5;

	&:hover {
		transform: translateX(-2px);
		color: ${darken(0.1, "#fff")};
	}

	> * {
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}

	@media screen and (min-width: 1024px) {
		display: none;
	}
`;

export const NavLogo = styled.img`
	display: block;
	width: 153px;
	height: 43px;
`;

export const NavItem = styled(Link)`
	display: flex;
	padding: 0px 6%;
	align-items: center;
	text-decoration: none;
	color: #fff;
	width: 100%;
	font-size: 20px;
	line-height: 24px;

	&:hover {
		transform: translateX(-2px);
		color: ${darken(0.1, "#fff")};
	}
`;
