import { Input, Textarea } from "../../styles/ui";
import SettingUploader from "../SettingUploader";
import {
	AlignCenter,
	AlignStart,
	Button,
	Container,
	H3,
	Info,
	Label,
	PreviewWrapper,
} from "./styles";
import b from "../../images/b.png";
import { Form, Field, ErrorMessage } from "formik";
import { Error } from "../../pages/auth/styles";
import Preview from "./Preview";

/**
 *
 * @constructor
 */
function SettingsForm({ values, setFieldValue, isSubmitting }) {

	return (
		<Form>
			<H3 mb={40}>Design</H3>

			<Container mb={136} rowGap={30}>
				<AlignCenter>
					<Label>{"Company logo (in light mode)"}</Label>
				</AlignCenter>

				<AlignCenter>
					<SettingUploader
						value={values.logo}
						onChange={value => setFieldValue("logo", value)}
					/>
					<Info ml={15} mr={40}>
						{".png / .jpg format"}
					</Info>
					{values.logo && <Preview source={values.logo} />}
					&nbsp;
					<ErrorMessage name="logo" component={Error} />
				</AlignCenter>
				<AlignCenter>
					<Label>{"Company logo (in dark Mode)"}</Label>
				</AlignCenter>

				<AlignCenter>
					<SettingUploader
						value={values.darkModeLogo}
						onChange={value => setFieldValue("darkModeLogo", value)}
					/>
					<Info ml={15} mr={40}>
						{".png / .jpg format"}
					</Info>
					{values.darkModeLogo && (
						<PreviewWrapper>
							<Preview source={values.darkModeLogo} />
						</PreviewWrapper>
					)}
					&nbsp;
					<ErrorMessage name="logo" component={Error} />
				</AlignCenter>

				<AlignCenter>
					<Label>{"Welcome screen image"}</Label>
				</AlignCenter>

				<AlignCenter>
					<SettingUploader
						value={values.welcomeScreen}
						onChange={value =>
							setFieldValue("welcomeScreen", value)
						}
					/>
					<Info ml={15} mr={75}>
						{"max. 4MB"}
					</Info>
					{values.welcomeScreen && (
						<Preview source={values.welcomeScreen} />
					)}
					<Info ml={20}>{"Recommended size: 1792 x 828px"}</Info>
					&nbsp;
					<ErrorMessage name="welcomeScreen" component={Error} />
				</AlignCenter>
			</Container>

			{/*<Container mb={50}>*/}
			{/*	<H3>Products visibility</H3>*/}

			{/*	<RadioGroup>*/}
			{/*		<Radio*/}
			{/*			name={"products"}*/}
			{/*			mb={35}*/}
			{/*			title={*/}
			{/*				<div>*/}
			{/*					<b>Public:</b> Let your products get discovered*/}
			{/*					globally*/}
			{/*				</div>*/}
			{/*			}*/}
			{/*		/>*/}
			{/*		<Radio*/}
			{/*			name={"products"}*/}
			{/*			title={*/}
			{/*				<div>*/}
			{/*					<b>Private:</b> Keep your product only visible*/}
			{/*					in your app*/}
			{/*				</div>*/}
			{/*			}*/}
			{/*		/>*/}
			{/*	</RadioGroup>*/}
			{/*</Container>*/}

			<H3 mb={40}>Company details</H3>

			<Container rowGap={30} mb={146}>
				<AlignCenter>
					<Label>{"Contact address"}</Label>
				</AlignCenter>
				<AlignCenter>
					<Field
						as={Input}
						placeholder={"Email"}
						width={332}
						name={"email"}
					/>
					&nbsp;
					<ErrorMessage name="email" component={Error} />
				</AlignCenter>

				<AlignCenter>
					<Label>{"Company name"}</Label>
				</AlignCenter>
				<AlignCenter>
					<Field
						as={Input}
						placeholder={"Name"}
						width={332}
						name={"name"}
					/>
					&nbsp;
					<ErrorMessage name="name" component={Error} />
				</AlignCenter>

				<AlignCenter>
					<Label>{"Address"}</Label>
				</AlignCenter>
				<AlignCenter>
					<Field
						as={Input}
						placeholder={"Address"}
						width={332}
						name={"address"}
					/>
					&nbsp;
					<ErrorMessage name="address" component={Error} />
				</AlignCenter>

				<AlignCenter>
					<Label>{"Zip code"}</Label>
				</AlignCenter>
				<AlignCenter>
					<Field
						as={Input}
						placeholder={"Code"}
						width={332}
						name={"zipCode"}
					/>
					&nbsp;
					<ErrorMessage name="zipCode" component={Error} />
				</AlignCenter>

				<AlignCenter>
					<Label>{"City"}</Label>
				</AlignCenter>
				<AlignCenter>
					<Field
						as={Input}
						placeholder={"City"}
						width={332}
						name={"city"}
					/>
					&nbsp;
					<ErrorMessage name="city" component={Error} />
				</AlignCenter>

				<AlignCenter>
					<Label>{"Country"}</Label>
				</AlignCenter>
				<AlignCenter>
					<Field
						as={Input}
						placeholder={"Country"}
						width={332}
						name={"country"}
					/>
					&nbsp;
					<ErrorMessage name="country" component={Error} />
				</AlignCenter>

				<AlignCenter>
					<Label>{"VAT No."}</Label>
				</AlignCenter>
				<AlignCenter>
					<Field
						as={Input}
						placeholder={"No."}
						width={332}
						name={"vat"}
					/>
					&nbsp;
					<ErrorMessage name="vat" component={Error} />
				</AlignCenter>
				<AlignCenter>
					<Label>{"Website link"}</Label>
				</AlignCenter>
				<AlignCenter>
					<Field
						as={Input}
						type="url"
						placeholder={"Website link"}
						width={332}
						name={"websiteLink"}
					/>
					&nbsp;
					<ErrorMessage name="websiteLink" component={Error} />
				</AlignCenter>
				<AlignStart pt={7}>
					<Label>{"Company description"}</Label>
				</AlignStart>
				<AlignCenter>
					<Field
						as={Textarea}
						placeholder={"Description"}
						rows={6}
						name={"description"}
					/>
					&nbsp;
					<ErrorMessage name="description" component={Error} />
				</AlignCenter>
			</Container>

			<Button type={"submit"}>Save</Button>
		</Form>
	);
}

export default SettingsForm;
