import styled from "styled-components";

export const Image = styled.img`
	display: block;
	max-width: 100%;
	max-height: 281px;
	object-fit: contain;
	margin-bottom: 20px;
`;

export const RadioGroup = styled.div`
	display: flex;
	margin-bottom: 33px;
`;

export const UploaderWrapper = styled.div`
	margin-bottom: 65px;
`;

export const FileInput = styled.input`
	visibility: hidden;
	position: absolute;
	z-index: -1;
`;
