import React from "react";
import AuthLayout from "../../layout/Auth";
import {
	AlignCenter,
	AlignRight,
	BottomLink,
	Content,
	ContentTitle,
	Error,
	Input,
	InputWrapper,
	SubmitButton,
} from "./styles";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { googleAuth, signUp } from "../../api/auth";
import Helmet from "react-helmet";
import { NotificationManager as Alert } from "react-notifications";
import { displayError } from "../../utils/errors";
import regex from "../../config/regex";
import GoogleAuthButton from "./AuthButton";
import { saveAuthDataToLocalStorage } from "../../utils/authToken";
import AuthContext from "../../contexts/auth";

/**
 *
 */
const SignUpSchema = Yup.object().shape({
	email: Yup.string().email("E-mail not valid").required("E-mail not valid"),
	password: Yup.string()
		.matches(regex.password.value, regex.password.message)
		.required(regex.password.message),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref("password")], "Passwords don't match")
		.required("Required"),
});

/**
 *
 * @constructor
 */
function SignUp() {
	const history = useHistory();
	const auth = React.useContext(AuthContext);

	/**
	 * Form submit handler
	 */
	const handleSubmit = React.useCallback(
		(values, { setSubmitting }) =>
			signUp({ email: values.email, password: values.password })
				// Successfully registered
				.then(() => {
					history.push("/");

					return Alert.success(
						"Check your email to confirm your email address",
						"Success"
					);
				})
				// Handle error
				.catch(displayError)
				// Finish form submitting
				.finally(() => setSubmitting(false)),
		[history]
	);

	const handleSuccess = res => {
		Alert.success("Successfully Authenticated!", "success");
		googleAuth({ token: res.access_token })
			.then(res => {
				saveAuthDataToLocalStorage(res.data);
				if (res.data.user.isSuperAdmin) {
					auth.setIsSuperAdmin(true);
				} else {
					auth.setIsSuperAdmin(false);
				}
				auth.setIsAuthorized(true);
				history.push("/");
				window.location.reload();
			})
			.catch(err => {
				Alert.error("Something went wrong!", "Error");
			});
	};

	const handleError = res => {
		Alert.error("Something went wrong!", "Error");
	};

	return (
		<AuthLayout>
			<Helmet>
				<title>Augmento.com - Signup</title>
			</Helmet>
			<Content>
				<ContentTitle>{"Please fill in the fields below"}</ContentTitle>

				<Formik
					initialValues={{
						email: "",
						password: "",
						confirmPassword: "",
					}}
					validationSchema={SignUpSchema}
					onSubmit={handleSubmit}
				>
					<Form>
						<InputWrapper>
							<Field
								as={Input}
								name="email"
								type="email"
								placeholder="Email"
								mb={3}
							/>
							<AlignRight>
								<ErrorMessage name="email" component={Error} />
							</AlignRight>
						</InputWrapper>

						<InputWrapper>
							<Field
								as={Input}
								name="password"
								type="password"
								placeholder="Password"
								mb={3}
							/>
							<AlignRight>
								<ErrorMessage
									name="password"
									component={Error}
								/>
							</AlignRight>
						</InputWrapper>

						<InputWrapper>
							<Field
								as={Input}
								name="confirmPassword"
								type="password"
								placeholder="Confirm password"
								mb={3}
							/>
							<AlignRight>
								<ErrorMessage
									name="confirmPassword"
									component={Error}
								/>
							</AlignRight>
						</InputWrapper>

						<SubmitButton>{"Create an account"}</SubmitButton>
						<GoogleAuthButton
							label="Signup with Google"
							onError={handleError}
							onSuccess={handleSuccess}
						/>
					</Form>
				</Formik>

				<AlignCenter>
					<BottomLink to={"/auth/signin"}>{"or LOGIN"}</BottomLink>
				</AlignCenter>
			</Content>
		</AuthLayout>
	);
}

export default SignUp;
