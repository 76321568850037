import {
	ColumnTitle,
	ColumnTitleLight,
	FlexRow,
	NotFound,
	Preloader,
	ProductWrapper,
	Table,
} from "./styles";
import Row from "./Row";
import { ReactComponent as Loader } from "../../images/icons/loader.svg";
import React from "react";
import AuthContext from "../../contexts/auth";
import { useEffect } from "react";
import { Droppable } from "react-beautiful-dnd";

/**
 *
 * @constructor
 */
function List({ data, isLoading, refetch }) {
	const [products, setProducts] = React.useState([]);
	const auth = React.useContext(AuthContext);

	useEffect(() => {
		setProducts(data);
	}, [data]);

	if (isLoading) {
		return (
			<Preloader>
				<Loader />
			</Preloader>
		);
	}
	return products?.length ? (
		<ProductWrapper>
			<FlexRow>
				<ColumnTitle basis={"27%"} shrink={"0"} grow={"0"} left pl={25}>
					{"Name"}
				</ColumnTitle>
				<ColumnTitle basis={"29%"} minWidth={"85px"}>
					{"Category"}
				</ColumnTitle>
				{auth.isSuperAdmin && (
					<ColumnTitle basis={"auto"}>{"Company"}</ColumnTitle>
				)}
				<ColumnTitleLight
					basis={"13%"}
					shrink={"0"}
					minWidth={"105px"}
					align={"start"}
				>
					{"Date added"}
				</ColumnTitleLight>
				{/* <ColumnTitle basis={"11%"} shrink={"0"} minWidth={"90px"}>
					{"Placement"}
				</ColumnTitle>
				<ColumnTitle basis={"9%"} shrink={"0"} minWidth={"80px"}>
					{"Size"}
				</ColumnTitle> */}
				<ColumnTitleLight basis={"7%"} shrink={"0"} minWidth={"81px"}>
					{""}
				</ColumnTitleLight>
				<ColumnTitleLight basis={"7%"} minWidth={"81px"}>
					{"Visible"}
				</ColumnTitleLight>
				<ColumnTitleLight
					basis={"7%"}
					shrink={"0"}
					grow={"0"}
					minWidth={"81px"}
				>
					{"Preview"}
				</ColumnTitleLight>
				<ColumnTitleLight
					basis={"7%"}
					shrink={"0"}
					grow={"0"}
					minWidth={"81px"}
				>
					{"Delete"}
				</ColumnTitleLight>
			</FlexRow>
			<Droppable droppableId="products">
				{droppableProvided => (
					<Table
						isSuperAdmin={auth.isSuperAdmin}
						{...droppableProvided.droppableProps}
						ref={droppableProvided.innerRef}
					>
						{products.map((product, index) => (
							<Row
								product={product}
								index={index}
								key={product.id}
							/>
						))}
						{droppableProvided.placeholder}
					</Table>
				)}
			</Droppable>
		</ProductWrapper>
	) : (
		<NotFound>Nothing found..</NotFound>
	);
}

export default List;
