import React from "react";
import {
	Category,
	Cell,
	Company,
	Edit,
	Icon,
	Name,
	RowWrapper,
	Toggle,
} from "./styles";
import { useHistory } from "react-router-dom";
import {
	NotificationManager as Alert,
	NotificationManager,
} from "react-notifications";
import { deleteProduct, setProductVisibility } from "../../api/products";
import { ReactComponent as Trash } from "../../images/icons/trash.svg";
// import { ReactComponent as Preview } from "../../images/icons/view.svg";
import { ReactComponent as Preview } from "../../images/icons/view_2.svg";
import { displayError } from "../../utils/errors";
import { useQueryClient } from "react-query";
import { format } from "date-fns";
import ConfirmModal from "../ConfirmModal";
import AuthContext from "../../contexts/auth";
import convertToMb from "../../utils/convertToMb";
import openLink from "../../utils/openLink";
import { Draggable } from "react-beautiful-dnd";

/**
 *
 * @constructor
 */
function Row({ product, index }) {
	const history = useHistory();
	const [isVisible, setIsVisible] = React.useState(product.isVisible);
	const queryClient = useQueryClient();
	const [modalOpen, setModalOpen] = React.useState(false);
	const handleEdit = React.useCallback(() => {
		history.push(`/products/edit/${product.id}`, product);
	}, [history, product]);
	const auth = React.useContext(AuthContext);
	const handleToggle = React.useCallback(() => {
		setIsVisible(!isVisible);
		const formData = new FormData();
		formData.append("isVisible", !isVisible);
		setProductVisibility(product.id, formData)
			.then(() => {
				NotificationManager.success(
					`Product '${product.title}' is now ${
						!isVisible ? "visible" : "invisible"
					}!`,
					"Success!"
				);
			})
			.catch(e => {
				const message = e.response
					? e.response.data.message
					: e.message;

				NotificationManager.error(message, "Error");

				setIsVisible(isVisible);
			});
	}, [isVisible, product.id, product.title]);

	const handleDelete = () => {
		deleteProduct(product.id)
			.then(() => {
				Alert.success("Product successfully deleted", "Success");
				return queryClient.invalidateQueries("products");
			})
			.catch(displayError);
	};
	const confirmDelete = () => {
		setModalOpen(true);
	};

	const modelURL = `${process.env.REACT_APP_WEB_AR_URL}/${product?.owner?.slug}/products/${product?.slug}`;

	return (
		<>
			<ConfirmModal
				isOpen={modalOpen}
				setOpen={setModalOpen}
				deleteConfirmed={handleDelete}
				confirmText={"Do you want to delete this product?"}
			/>
			<Draggable
				key={product.id}
				draggableId={product.id.toString()}
				index={index}
			>
				{(draggableProvided, draggableSnapShot) => (
					<RowWrapper
						{...draggableProvided.dragHandleProps}
						{...draggableProvided.draggableProps}
						ref={draggableProvided.innerRef}
					>
						<Name isSuperAdmin={auth.isSuperAdmin}>
							<div
								style={{
									whiteSpace: "nowrap",
									width: "100%",
									textOverflow: "ellipsis",
									overflow: "hidden",
								}}
							>
								{product.title}
							</div>
						</Name>
						<Cell basis={"29%"} less minWidth={"85px"}>
							<Category isSuperAdmin={auth.isSuperAdmin}>
								{product?.category?.title}
							</Category>
						</Cell>
						{auth.isSuperAdmin && (
							<Company isSuperAdmin={auth.isSuperAdmin}>
								{product?.company?.title}
							</Company>
						)}
						<Cell
							basis={"13%"}
							isSuperAdmin={auth.isSuperAdmin}
							minWidth={"105px"}
						>
							{format(new Date(product.createdAt), "dd/MM/yyyy")}
						</Cell>

						{/* <Cell
							basis={"11%"}
							isSuperAdmin={auth.isSuperAdmin}
							minWidth={"90px"}
						>
							{product.placementType}
						</Cell>

						<Cell
							basis={"9%"}
							isSuperAdmin={auth.isSuperAdmin}
							minWidth={"80px"}
						>
							{`${convertToMb(product.model.size)} MB`}
						</Cell> */}
						<Cell
							basis={"7%"}
							isSuperAdmin={auth.isSuperAdmin}
							less
							minWidth={"81px"}
						>
							<Edit onClick={handleEdit}>{"edit"}</Edit>
						</Cell>
						<Cell
							basis={"7%"}
							isSuperAdmin={auth.isSuperAdmin}
							less
							minWidth={"81px"}
						>
							<Toggle active={isVisible} onClick={handleToggle} />
						</Cell>
						<Cell
							basis={"7%"}
							isSuperAdmin={auth.isSuperAdmin}
							minWidth={"81px"}
						>
							<Icon onClick={() => openLink(modelURL)}>
								<Preview />
							</Icon>
						</Cell>

						

						<Cell
							basis={"7%"}
							isSuperAdmin={auth.isSuperAdmin}
							last
							minWidth={"81px"}
						>
							<Icon onClick={confirmDelete}>
								<Trash />
							</Icon>
						</Cell>
					</RowWrapper>
				)}
			</Draggable>
		</>
	);
}

export default Row;
