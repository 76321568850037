import { ArrowLeft, Controller, LI, UL, Wrapper } from "./styles";
import classnames from "classnames";
import { ReactComponent as ArrLeft } from "../../images/icons/arr-l.svg";
import { ReactComponent as ArrRight } from "../../images/icons/arr-r.svg";
import { DOTS, usePagination } from "./usePagination";

function CustomPagination({
	totalPages,
	page,
	goNext,
	goPrev,
	onPageChange,
	siblingCount = 1,
	className = "pagination-bar",
}) {
	const paginationRange = usePagination(totalPages, siblingCount, page);
	if (page === 0 || paginationRange.length < 2) {
		return null;
	}
	let lastPage = paginationRange[paginationRange.length - 1];
	return (
		<Wrapper>
			<UL key={1}>
				<LI key={2} disabled={page === 1} onClick={goPrev}>
					<ArrowLeft height={18} />
				</LI>
				{paginationRange.map(pageNumber => {
					if (pageNumber === DOTS) {
						return <LI key={pageNumber}>&#8230;</LI>;
					}

					return (
						<LI
							key={pageNumber}
							selected={pageNumber === page}
							onClick={() => onPageChange(pageNumber)}
						>
							{pageNumber}
						</LI>
					);
				})}
				<LI key={3} disabled={page === lastPage} onClick={goNext}>
					<ArrRight height={18} />
				</LI>
			</UL>
		</Wrapper>
	);
}

export default CustomPagination;
