import styled from "styled-components";
export const SearchWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	align-self: flex-start;
	margin-bottom: 20px;
	background-color: rgba(0, 0, 0, 0.03);
	border-radius: 6px;
	width: 80%;
`;

export const SearchIcon = styled.img`
	position: absolute;
	top: 50%;
	left: 14px;
	transform: translateY(-50%);
	z-index: 1;
	display: block;
	width: 16px;
	height: 16px;
	object-fit: contain;
	object-position: center;
`;
export const Wrapper = styled.div`
	position: relative;
	margin-top: -17px;
	margin-bottom: 10px;
	max-height: 300px;
	overflow: auto;
	background: rgba(0, 0, 0, 0.02);
	padding: 43px 23px 40px;
	width: 80%;
`;

export const SearchInput = styled.input`
	height: 40px;
	max-width: 280px;
	width: 100%;
	padding-left: 40px;
	padding-right: 20px;
	border: none;
	outline: none;
	box-shadow: none;
	font-size: 16px;
	background-color: transparent;

	&::placeholder {
		color: rgba(51, 54, 65, 0.4);
	}
`;
