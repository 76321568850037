import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	overflow: auto;
	height: 100%;
`;

export const Left = styled.div`
	width: 70%;
`;

export const Right = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 68%;
`;

export const RightStart = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 68%;
`;

export const Screen = styled.img`
	max-width: 271px;
	width: 100%;
	max-height: 427px;
	margin-bottom: 21px;
`;

export const Tip = styled.div`
	padding: 15px 25px;
	background: #ececec;
	border-radius: 12px;
	color: #333641;
	font-size: 16px;
`;
