import styled from "styled-components";

export const Wrapper = styled.div`
	margin: 0 0 30px;
`;
export const Header = styled.div`
	display: flex;
	align-items: center;
	margin: 0 0 40px;
`;
export const Tab = styled.div`
	font-size: ${props => (props.isActive ? 16 : 12)}px;
	font-family: "Philosopher", sans-serif;
	font-weight: 700;
	cursor: pointer;
	color: ${props => (props.isActive ? "#333641" : "#848199")};
	text-transform: uppercase;
	padding-bottom: ${props => (props.isActive ? 3 : 0)}px;
	border-bottom: ${props => (props.isActive ? "3px solid #333641" : "none")};

	&:not(:last-child) {
		margin-right: 20px;
	}
`;
export const Body = styled.div``;
