import styled from "styled-components";

const displayActionColor = props => {
	return props.isSubscribed
		? props.isHigher
			? "color: #F96464"
			: "color: #20B2E5"
		: "#000";
};

export const Wrapper = styled.div`
	flex-basis: ${props => (props.wider ? 30 : 25)}%;
	position: relative;
`;

export const Green = styled.div`
	width: 11.5px;
	height: 11.5px;
	margin-right: 10px;
	background: #28f995;
	border-radius: 86px;
	content: " ";
`;
export const Container = styled.div`
	padding: 0 15px;
	padding: 20px 30px;
	${props =>
		props.highlighted &&
		`
    position: absolute;
			width: 100%;
			height: 120%;
			padding-top: 20px;
			padding: 20px 30px;
			bottom: 0;
			left: 0;
			background: #1E2F3A;
			box-shadow: 0 22px 36px rgba(91, 164, 254, 0.33);
			border-radius: 26px;
  `}
`;
export const Price = styled.h2`
	font-size: 36px;
	font-weight: 700;
	color: ${props => (props.light ? "#fff" : "#231d4f")};
	line-height: 46px;
	margin: 0 0 20px;

	& > small {
		font-size: 17px;
		font-weight: 500;
		line-height: 23px;
		color: ${props => (props.light ? "#fff" : "#848199")};
	}

	& sup {
		font-size: 20px;
	}
`;
export const Title = styled.h3`
	font-size: 28px;
	line-height: 39px;
	font-weight: 600;
	color: ${props => (props.light ? "#fff" : "#231d4f")};
	margin: 0 0 10px;
`;
export const Description = styled.p`
	font-size: 15px;
	line-height: 21px;
	color: ${props => (props.light ? "#fff" : "#848199")};
	margin: 0 0 17px;
`;
export const List = styled.ul`
	padding-left: 16px;
	list-style: none;
	margin: 0 0 24px;
	color: ${props => (props.light ? "#fff" : "#848199")};
`;
export const ListItem = styled.li`
	display: flex;
	align-items: center;
	font-size: 15px;
	line-height: 21px;

	& > svg {
		margin-right: 10px;
	}

	&:not(:last-child) {
		margin-bottom: 10px;
	}
`;
export const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 40px;
	border: none;
	outline: none;
	box-shadow: none;
	border-radius: 24px;
	min-height: 45px;
	padding: 0 30px;
	width: 100%;
	font-size: 15px;
	font-weight: 600;
	color: ${props =>
		props.light && props.subscribed
			? "#fff"
			: props.isSubscribed
			? props.isHigher
				? "#20B2E5"
				: "#F96464"
			: "#000"};
	cursor: pointer;
	background-color: ${props =>
		props.light
			? props.subscribed
				? "#000000"
				: "#fff"
			: "rgba(151, 151, 151, .1)"};
`;
export const LabelWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-bottom: 3px;
`;
export const Label = styled.div`
	padding: 7px 17px;
	background-color: #019eff;
	color: #fff;
	text-transform: uppercase;
	font-size: 10px;
	line-height: 14px;
	font-weight: 700;
	border-radius: 14px;
`;
