import { useField } from "formik";
import React from "react";
import { CustomCheckbox, Wrapper, Input } from "./style";
/**
 *
 * @param setFieldValue
 * @returns {JSX.Element}
 * @constructor
 */
function CheckboxCustom({
	title,
	value,
	onChange,
	checked,
	name,
	setFieldValue,
}) {
	const [selectedClient, setSelectedClient] = useField({ name: "client" });
	return (
		<Wrapper>
			<Input
				type="checkbox"
				name={name}
				value={value}
				// onChange={onChange}
				onChange={e => {
					setFieldValue(name, e.target.value);
				}}
				checked={value === selectedClient.value}
			/>
			<CustomCheckbox />
			<span>{title}</span>
		</Wrapper>
	);
}

export default CheckboxCustom;
