import { Tag } from "./style";

function List({ data, remove }) {
	if (!data || !data.length) {
		return null;
	}

	return (
		<>
			{data.map((item, index) => (
				<Tag key={item + index} onClick={() => remove(index)}>
					{item}
				</Tag>
			))}
		</>
	);
}

export default List;
