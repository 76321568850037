import React, { Suspense, useMemo, useState, useEffect, useRef } from "react";
import { Description, FileInput, Button, RemoveIcon } from "./styles";
import { useField } from "formik";
import { StyledFlexBox, GlobalStyle } from "./styles.js";
import FlexBox from "../FlexBox";
import FlexGrow from "../FlexGrow";

import black from "../../images/black.jpg";
import white from "../../images/white.jpg";
import oak from "../../images/oak.jpg";
import blackWood from "../../images/blackWood.jpg";
import darkWood from "../../images/darkWood.jpg";
import gold from "../../images/gold.jpg";
import darkWoodRoughness from "../../images/darkWoodRoughness.jpg";
import ModelViewerElement from "./ModelViever";

import Loading from "../Loading/Loading";

function getFrame(name) {
	switch (name) {
		case "black":
			return black;
		case "white":
			return white;

		case "blackWood":
			return blackWood;

		case "oak":
			return oak;

		case "darkWood":
			return darkWood;

		case "gold":
			return gold;

		case "darkWoodRoughness":
			return darkWoodRoughness;

		default:
			return null;
	}
}

function MediaUploaderContainer({
	mediaType,
	buttonTitle = "Upload",
	buttonDescription,
	title,
	accept,
	productName,
	productId,
	frame,
	frameConfig,
	setFieldValue,
	className,
	useGlass,
	longestSideHandl,
	longestSide,
	inputSizeValue,
}) {
	const [field, meta, helpers] = useField({ name: mediaType });
	const [typesArray, setTypesArray] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");
	const [artUrl, setArtUrl] = React.useState(null);
	const [loadedModel, setLoadedModel] = useState(null);
	const canvasRef = useRef(null);
	const [dataURL, setDataURL] = useState('');

	const frameImage = useMemo(() => {
		if (frame) {
			return getFrame(frame);
		}
	}, [frame]);
	useEffect(() => {
		if (accept) {
			setTypesArray(
				accept.split(",").map(function (item) {
					return item.trim();
				})
			);
		}
	}, [accept]);

	useEffect(() => {
		const reader = new FileReader();

		
			if(field.value){
				if (field?.value?.url) {
					//convert field?.value?.url to dataurl and set in artUrl
					loadImageAsDataURL(field?.value?.url)
					setArtUrl(dataURL);
				} else {
					reader.readAsDataURL(field.value);
	
					reader.onload = e => {
						setArtUrl(e.target.result);
					};
				}
			}
			
		
		
	}, [field]);
	
	const loadImageAsDataURL = (url) => {
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		const image = new Image();
		image.crossOrigin = 'Anonymous';
	
		image.onload = () => {
		  canvas.width = image.width;
		  canvas.height = image.height;
		  ctx.drawImage(image, 0, 0);
	
		  const dataUrl = canvas.toDataURL('image/jpeg'); // Change 'image/jpeg' to desired format
		  setDataURL(dataUrl);
		};
	
	 return	image.src = url;
	  };
	const input = React.createRef();

	const preventDefault = e => {
		e.preventDefault();
		// e.stopPropagation();
	};

	const dragOver = e => {
		preventDefault(e);
	};

	const dragEnter = e => {
		preventDefault(e);
	};

	const dragLeave = e => {
		preventDefault(e);
	};
	const fileDrop = e => {
		preventDefault(e);
		const files = e.dataTransfer.files;
		if (files.length) {
			handleFiles(files, e.target.id);
		}
	};
	const handleFiles = (files, fieldType) => {
		const reader = new FileReader();
		const file = files[0];

		reader.onload = e => {
			if (file.type.includes("image")) {
				setArtUrl(e.target.result);
			} else {
				setLoadedModel(e.target.result);
			}
		};

		reader.readAsDataURL(file);

		for (let i = 0; i < files.length; i++) {
			if (validateFile(files[i])) {
				helpers.setValue(files[0]);
			} else {
				files[i]["invalid"] = true;
				setErrorMessage("File type not permitted");
			}
		}
	};

	const getFileExt = file => {
		if (file.type) {
			return file.type;
		}
		const name = file.name;
		const lastDot = name.lastIndexOf(".");
		const ext = name.substring(lastDot + 1);
		return "." + ext;
	};

	const validateFile = file => {
		const index = typesArray.findIndex(element => {
			return element.toLowerCase() === getFileExt(file).toLowerCase();
		});
		if (file.type && file.type !== "") {
			if (file.url && file.url !== "") {
				return true;
			}
			if (
				typesArray.indexOf(file.type) === -1
				// && (mediaType === "image" || mediaType === "artwork")
			) {
				return false;
			}

			return true;
		} else {
			if (
				index === -1
				// && (mediaType === "image" || mediaType === "artwork")
			) {
				return false;
			}

			return true;
		}
	};
	const map3dFile = file => {
		setFieldValue("model", file);
	};

	const removeArtModel = () => {
		helpers.setValue();
	};

	return (
		<div className={className}>
			<GlobalStyle />

			<>
				<StyledFlexBox
					onDragOver={dragOver}
					onDragEnter={dragEnter}
					onDragLeave={dragLeave}
					onDrop={fileDrop}
					id={field.name}
					isImage={field.name === "image"}
				>
					{field.value ? (
						<>
							{field.name === "image" ? (
								<>
									<RemoveIcon onClick={removeArtModel} />
									<img
										src={artUrl}
										style={{ height: "100%" }}
									/>
								</>
							) : (
								<Suspense fallback={<Loading />}>
									<ModelViewerElement
										artUrl={artUrl}
										frameConfig={frameConfig}
										frameImg={frameImage}
										returnFile={map3dFile}
										loadedModel={loadedModel}
										useGlass={useGlass}
										longestSideHandl={longestSideHandl}
										setFieldValue={setFieldValue}
										inputSizeValue={inputSizeValue}
										longestSide={longestSide}
										removeArtModel={removeArtModel}
										productName={productName}
										productId={productId}
										src={field.value}
										type={mediaType}
										setArtUrl={helpers.setValue}
									/>
								</Suspense>
							)}
						</>
					) : (
						<>
							<FlexBox id={field.name} direction="column" mb={16}>
								<FlexGrow id={field.name}>{title}</FlexGrow>
							</FlexBox>
							<FlexBox id={field.name} direction="column" mb={16}>
								<FlexGrow id={field.name}>
									<Button
										id={field.name}
										type="button"
										height={22}
										style={{ margin: 0 }}
										onClick={() => input.current.click()}
									>
										{buttonTitle}
									</Button>
									{mediaType === "model" ? (
										<>
											<FileInput
												id={field.name}
												name={field.name}
												ref={input}
												type="file"
												onChange={
													e =>
														handleFiles(
															e.target.files,
															"model"
														)
													// helpers.setValue(e.target.files[0])
												}
												accept={accept}
											/>
										</>
									) : (
										<>
											<FileInput
												id={field.name}
												ref={input}
												type="file"
												onChange={e =>
													helpers.setValue(
														e.target.files[0]
													)
												}
												accept={accept}
											/>
										</>
									)}
								</FlexGrow>
								<FlexGrow id={field.name}>
									<Description id={field.name}>
										{buttonDescription}
									</Description>
								</FlexGrow>
								<FlexGrow id={field.name}>
									<Description style={{ color: "red" }}>
										{errorMessage}
									</Description>
								</FlexGrow>
							</FlexBox>
						</>
					)}
				</StyledFlexBox>
			</>
		</div>
	);
}

export default MediaUploaderContainer;
