import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const FILTER_GROUPS = ["Filled", "Outlined", "Rounded", "Two tone", "Sharp"];

const useStyles = makeStyles(theme => ({
	form: {
		margin: theme.spacing(2, 0),
	},
}));

function FilterList({ value, onChange }) {
	const classes = useStyles();

	return (
		<Grid item xs={12} sm={3}>
			<form className={classes.form}>
				<RadioGroup>
					{FILTER_GROUPS.map(key => {
						return (
							<FormControlLabel
								key={key}
								control={
									<Radio
										checked={value === key}
										onChange={() => onChange(key)}
										value={key}
									/>
								}
								label={key}
							/>
						);
					})}
				</RadioGroup>
			</form>
		</Grid>
	);
}

export default FilterList;
