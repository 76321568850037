import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const useOnboarding = steps => {
	const [onboarding, setOnboarding] = useState({
		stepsEnabled: false,
		initialStep: 0,
		steps,
	});
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const isOnboarding = params.get("onboarding") === "true";
	const isAlreadyOnboarded = localStorage.getItem("isOnboarded") === "true";
	useEffect(() => {
		if (isOnboarding && !isAlreadyOnboarded) {
			setOnboarding({
				...onboarding,
				stepsEnabled: true,
			});
		}
	}, [isOnboarding]);

	return {
		onboarding,
		setOnboarding,
		isAlreadyOnboarded,
	};
};

export default useOnboarding;
