import styled from "styled-components";

export const LongestSideSection = styled.section`
	font-size: smaller;
	font-weight: bold;
	margin-bottom: 16px;
`;

export const LongestSideWrapper = styled.label`
	display: flex;
	align-items: center;
	gap: 8px;
	position: relative;

	&:hover .tooltip {
		display: block;
	}
`;

export const TextPosition = styled.span``;

export const InputSection = styled.section`
	position: relative;
	width: max-content;
`;

export const UnitOfMeasure = styled.div`
	width: max-content;
	height: 38px;
	padding: 10px;
	font-size: medium;
	font-weight: 700;
	position: absolute;
	background-color: white;
	top: 1px;
	right: 5%;
`;
