import { ReactComponent as UploadIcon } from "../../images/icons/upload-icon.svg";
import { ReactComponent as GenerateIcon } from "../../images/icons/generate-3d-icon.svg";
import {
	TabsContainer,
	TabButton,
	TabUnderline,
	TabText,
} from "./styles/Tabs.style";

function FormTabs({ tab, handleChange }) {
	return (
		<TabsContainer>
			<TabButton tab={tab} value={1} onClick={() => handleChange(1)}>
				<GenerateIcon
					width="14px"
					fill={tab === 1 ? "black" : "grey"}
				/>
				<TabText>Generate 3D model</TabText>
			</TabButton>
			<TabButton tab={tab} value={2} onClick={() => handleChange(2)}>
				<UploadIcon width="14px" fill={tab === 2 ? "black" : "grey"} />
				<TabText>Upload 3D model</TabText>
			</TabButton>
			<TabUnderline tab={tab} />
		</TabsContainer>
	);
}

export default FormTabs;
