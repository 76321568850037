import PageHeader from "../PageHeader";
import PageContentOverflow from "../PageContentOverflow";
import { Wrapper } from "../../layout/ProductEdit/styles";
import { Formik } from "formik";
import ProductForm from "../ProductForm/ProductForm";
import { CreateProductSchema } from "../../validations/CreateProductSchema";

function CreateProductForm({ pageHeader, onSubmit, initialValues }) {
	return (
		<>
			<PageHeader title={pageHeader} />
			<PageContentOverflow>
				<Wrapper>
					<Formik
						component={ProductForm}
						initialValues={initialValues}
						validationSchema={CreateProductSchema}
						onSubmit={onSubmit}
					/>
				</Wrapper>
			</PageContentOverflow>
		</>
	);
}

export default CreateProductForm;
