import styled, { css } from "styled-components";
import { darken } from "polished";
import { ReactComponent as ArrLeft } from "../../images/icons/arr-l.svg";
import { ReactComponent as ArrRight } from "../../images/icons/arr-r.svg";

export const Wrapper = styled.div`
	display: flex;
	justify-content: center;
`;

export const Controller = styled.div`
	display: flex;
	align-items: center;
	padding: 8px;
	cursor: pointer;
	border-radius: 4px;
	background: #f7f7f7;
	color: #000;
	font-size: 15px;
	transition: background-color 0.15s ease-out;
	margin: 0 10px;

	&:hover {
		background-color: ${darken(0.03, "#f7f7f7")};
	}
`;
export const UL = styled.ul`
	display: flex;
	list-style-type: none;
`;
export const ArrowLeft = styled(ArrLeft)``;
export const LI = styled.li`
	padding: 0 12px;
	height: 32px;
	text-align: center;
	color: rgba(0, 0, 0, 0.87);
	display: flex;
	box-sizing: border-box;
	align-items: center;
	letter-spacing: 0.01071em;
	border-radius: 16px;
	line-height: 1.43;
	font-size: 16px;
	min-width: 32px;

	&.dots:hover {
		background-color: transparent;
		cursor: default;
	}
	&:hover {
		background-color: rgba(0, 0, 0, 0.04);
		cursor: pointer;
	}

	${props =>
		props.selected &&
		css`
			background-color: rgba(0, 0, 0, 0.08);
		`}
	${props =>
		props.disabled &&
		css`
			pointer-events: none;

			${ArrowLeft}::before {
				border-right: 0.12em solid rgba(0, 0, 0, 0.43);
				border-top: 0.12em solid rgba(0, 0, 0, 0.43);
			}

			&:hover {
				background-color: transparent;
				cursor: default;
			}
		`}
`;
export const ProductsCounter = styled.div`
	display: flex;
	align-items: center;
	padding: 10px 30px;
	font-size: 16px;
	color: #424e61;
`;
