import React, { useState, useEffect } from "react";
import converToMb from "../../utils/convertToMb";
import { PrimaryButton } from "../../styles/ui";
import styled from "styled-components";
import CropModal from "./CropModal";
import { getProduct } from "../../api/products";
import { CropButton } from "../MediaUploaderContainer/styles";
import { ReactComponent as CropIcon } from "../../images/icons/feCrop2.svg";

export const PreviewButton = styled(PrimaryButton)`
	width: 153px;
	height: 23px;
	color: #ffffff;
	font-size: 10px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	line-height: 14px;
	text-align: center;
	box-shadow: 0 10px 20px rgba(20, 102, 204, 0.2);
	border-radius: 20px;
	border: 1px solid #ffffff;
	background: #1e2f3a;
	margin-top: 16px;
`;
function CropImage({ src, productName, productId, setArtUrl }) {
	const [file, setFile] = React.useState(null);

	const [isCropOpen, setIsCropOpen] = useState(false);

	useEffect(() => {
		if (src.url) {
			return setFile({
				src: src.url,
				size: converToMb(src.size),
				name: src.url.substring(src.url.lastIndexOf("/") + 1),
			});
		}

		const reader = new FileReader();
		reader.readAsDataURL(src);

		const size = converToMb(src.size);

		reader.onload = e => {
			setFile({ src: e.target.result, size, name: src.name });
		};
	}, [src]);

	const cropModal = () => {
		setIsCropOpen(prev => !prev);
	};
	const fetchNewImage = () => {
		getProduct(productId)
			.then(data => {
				return setFile({
					src: data.data.data.image.url,
					name: data.data.data.image.url.substring(
						data.data.data.image.url.lastIndexOf("/") + 1
					),
				});
			})
			.catch(error => console.log(error));
	};
	// file is not loaded
	if (!file) return null;

	return (
		<>
			<CropModal
				isOpen={isCropOpen}
				imageSrc={file.src}
				name={productName}
				productId={productId}
				setOpen={setIsCropOpen}
				onCropClose={fetchNewImage}
				setArtUrl={setArtUrl}
			/>
			<CropButton type="button" onClick={cropModal}>
				<CropIcon />
				<span style={{ marginLeft: "6px" }}>Crop Image</span>
			</CropButton>
		</>
	);
}

export default CropImage;
