import api from "./index";

export const getPlans = () => api.get("/v1/stripe/plans");

export const subscribe = (productId, priceId) =>
	api.post("/v1/stripe/subscribe", { productId, priceId });

export const cancelSubscription = () => api.post("/v1/stripe/unsubscribe");

export const getPaymentMethod = userId =>
	api.get(`/v1/stripe/payment-method?userId=${userId}`);

export const removeCard = cardId =>
	api.delete("/v1/stripe/payment-method", { data: cardId });

export const setupIntent = () => api.post(`/v1/stripe/setup-intent`);
export const getActiveSubscription = () => api.get("/v1/users/me");
