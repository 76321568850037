import styled from "styled-components";

export const Wrapper = styled.label`
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: 30px;
`;

export const Input = styled.input`
	position: absolute;
	visibility: hidden;
	width: 0;
	height: 0;
	z-index: -1;

	&:checked + div {
		&::after {
			opacity: 1;
		}
	}
`;

export const CustomCheckbox = styled.div`
	position: relative;
	width: 18px;
	height: 18px;
	border-radius: 3px;
	border: 1px solid #333641;
	margin-right: 10px;

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 12px;
		height: 12px;
		border-radius: 3px;
		background-image: linear-gradient(270deg, #43bbff 0%, #3676f1 100%);
		opacity: 0;
	}
`;
