import { Wrapper } from "./styles";
import { ReactComponent as LoaderIcon } from "../../images/icons/loader.svg";

function Loader() {
	return (
		<Wrapper>
			<LoaderIcon />
		</Wrapper>
	);
}

export default Loader;
