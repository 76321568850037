import AuthLayout from "../../layout/Auth";
import {
	AlignCenter,
	AlignRight,
	BottomLink,
	Content,
	ContentTitle,
	Error,
	Input,
	InputWrapper,
	SubmitButton,
} from "./styles";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { setPassword } from "../../api/auth";
import { NotificationManager as Alert } from "react-notifications";
import { displayError } from "../../utils/errors";
import regex from "../../config/regex";
import Helmet from "react-helmet";

/**
 *
 */
const SetPasswordSchema = Yup.object().shape({
	password: Yup.string()
		.matches(regex.password.value, regex.password.message)
		.required(regex.password.message),
});

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function SetPassword() {
	const history = useHistory();
	const { token } = useParams();

	/**
	 * Form submit handler
	 */
	const handleSubmit = React.useCallback(
		(values, { setSubmitting }) =>
			setPassword({ token, newPassword: values.password })
				// Successfully set new password
				.then(() => {
					history.push("/");

					return Alert.success(
						"You have successfully changed your password!",
						"Success"
					);
				})
				// Handle error
				.catch(displayError)
				// Finish form submitting
				.finally(() => setSubmitting(false)),
		[history, token]
	);

	return (
		<AuthLayout>
			<Helmet>
				<title>Augmento.com - Set password</title>
			</Helmet>
			<Content>
				<ContentTitle>{"Enter your new password"}</ContentTitle>

				<Formik
					initialValues={{
						email: "",
						password: "",
						confirmPassword: "",
					}}
					validationSchema={SetPasswordSchema}
					onSubmit={handleSubmit}
				>
					<Form>
						<InputWrapper>
							<Field
								as={Input}
								name="password"
								type="password"
								placeholder="Password"
								mb={3}
							/>
							<AlignRight>
								<ErrorMessage
									name="password"
									component={Error}
								/>
							</AlignRight>
						</InputWrapper>

						<SubmitButton type="submit">
							{"Set new password"}
						</SubmitButton>
					</Form>
				</Formik>

				<AlignCenter>
					<BottomLink to={"/auth/signin"}>{"or LOGIN"}</BottomLink>
				</AlignCenter>
			</Content>
		</AuthLayout>
	);
}

export default SetPassword;
