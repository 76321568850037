import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
`;

export const Title = styled.h2`
	font-size: 30px;
	color: #102b3c;
`;

export const ProductsCounter = styled.div`
	display: flex;
	align-items: center;
	font-size: 16px;
	color: #424e61;
`;
