import styled from "styled-components";
import { Link } from "react-router-dom";
import { darken } from "polished";

export const List = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
`;

export const Item = styled(Link)`
	display: flex;
	align-items: center;
	text-decoration: none;
	transition: transform 0.2s ease-out;
	color: #fff;
	font-size: 20px;

	&:hover {
		transform: translateX(-2px);
		color: ${darken(0.1, "#fff")};
	}
`;

export const Icon = styled.div`
	display: flex;
	margin-right: 16px;
`;

export const Title = styled.h3`
	font-weight: ${props => (props.isNameMatched ? "900" : "300")};
`;

export const Hightlight = styled.span`
	color: #112d41;
	font-weight: bold;
`;
