import React from "react";
import Row from "./Row";
import { ColumnTitle, FlexRow, NotFound, Preloader, Table } from "./styles";
import { ReactComponent as Loader } from "../../images/icons/loader.svg";
import { ReactComponent as SortIcon } from "../../images/icons/sort.svg";
import useToggleSort from "../../hooks/useToggleSort";
import AuthContext from "../../contexts/auth";
import { Droppable } from "react-beautiful-dnd";

/**
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
function List({ data, isLoading }) {
	const toggleSort = useToggleSort();
	const auth = React.useContext(AuthContext);
	if (isLoading) {
		return (
			<Preloader>
				<Loader />
			</Preloader>
		);
	}

	return data && data.length ? (
		<>
			<FlexRow>
				<ColumnTitle pl={25} onClick={() => toggleSort("title")}>
					{"Name"} <SortIcon />
				</ColumnTitle>
				<ColumnTitle>{""}</ColumnTitle>
				{auth.isSuperAdmin ? (
					<>
						<ColumnTitle>Company</ColumnTitle>
						<ColumnTitle>{"Created by"}</ColumnTitle>
					</>
				) : (
					<>
						<ColumnTitle>{""}</ColumnTitle>
						<ColumnTitle>{""}</ColumnTitle>
					</>
				)}
				<ColumnTitle>{""}</ColumnTitle>
				{/* <ColumnTitle ml="auto">{"Visible"}</ColumnTitle> */}
				<p
					style={{
						marginLeft: "auto",
						fontWeight: "400",
						fontSize: "15px",
						marginRight: "30px",
					}}
				>
					Visible
				</p>
				{/* <ColumnTitle basis={"100px"} shrink={"0"} grow={"0"} ml="30px">
					{"Delete"}
				</ColumnTitle> */}
			</FlexRow>
			<Droppable droppableId="categories">
				{droppableProvided => (
					<Table
						{...droppableProvided.droppableProps}
						ref={droppableProvided.innerRef}
					>
						{data.map((category, index) => (
							<Row
								key={category.id}
								category={category}
								index={index}
							/>
						))}
						{droppableProvided.placeholder}
					</Table>
				)}
			</Droppable>
		</>
	) : (
		<NotFound>Nothing found..</NotFound>
	);
}

export default List;
