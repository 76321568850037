function convertToOptions(arr, valueKey, labelKey) {
	if (!valueKey) throw new Error("Value key is not provided!");
	if (!labelKey) throw new Error("Label key is not provided!");
	if (!arr || !Array.isArray(arr))
		throw new Error("Array is required or value is not of type array");

	return arr.map(item => {
		return {
			value: item[valueKey],
			label: item[labelKey],
		};
	});
}

export default convertToOptions;
