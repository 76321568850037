import React, { useState } from "react";
import WithSidebar from "../layout/WithSidebar";
import PageHeader from "../components/PageHeader";
import PageContent from "../components/PageContent";
import { Formik } from "formik";
import * as Yup from "yup";
import { getAuthDataFromLocalStorage } from "../utils/authToken";
import {
	NotificationManager as Alert,
	NotificationManager,
} from "react-notifications";
import { useHistory } from "react-router-dom";
import regex from "../config/regex";
import TextsForm from "../components/TextsForm";
import { useQuery } from "react-query";
import { editCompany, getCompany } from "../api/company";
import Loader from "../components/Loader";
import { displayError } from "../utils/errors";
import Helmet from "react-helmet";
/**
 *
 */
const TextsSchema = Yup.object().shape({
	view: Yup.string().matches(regex.title.value, regex.title.message),
	contact: Yup.string().matches(regex.title.value, regex.title.message),
	popular: Yup.string().matches(regex.title.value, regex.title.message),
	collections: Yup.string().matches(regex.title.value, regex.title.message),
});

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Texts() {
	const { companyId } = getAuthDataFromLocalStorage();
	const history = useHistory();

	/**
	 * Fetching company
	 */
	const {
		data: company,
		isLoading,
		error,
		remove,
	} = useQuery("company", () => getCompany(companyId), {
		select: data => data?.data,
	});
	/**
	 * Form submit handler
	 */
	const handleSubmit = React.useCallback(
		(values, { setSubmitting }) => {
			if (!company) {
				return null;
			}

			const formData = new FormData();

			for (let key in values) {
				//dont include objects in form data
				if (
					typeof values[key] === "object" &&
					!(values[key] instanceof File) &&
					!Array.isArray(values[key])
				)
					continue;

				const value = Array.isArray(values[key])
					? JSON.stringify(values[key])
					: values[key];

				formData.append("customTexts[" + key + "]", value);
			}

			editCompany(company.id, formData)
				// Successfully updated
				.then(() => {
					Alert.success(`Changes has been saved!`, "Success");
					remove();
				})
				// Handle error
				.catch(displayError)
				// Finish form submitting
				.finally(() => setSubmitting(false));
		},
		[company]
	);

	if (isLoading) return <Loader />;

	if (error) {
		NotificationManager.error(error.message, "Error");

		history.push("/");
	}

	return (
		<WithSidebar>
			<Helmet>
				<title>Augmento.com - Texts</title>
			</Helmet>
			<PageHeader title={"Texts"} />

			<PageContent>
				<Formik
					component={TextsForm}
					initialValues={{
						view: company?.customTexts.view || "",
						contact: company?.customTexts.contact || "",
						popular: company?.customTexts.popular || "",
						collections: company?.customTexts.collections || "",
					}}
					validationSchema={TextsSchema}
					onSubmit={handleSubmit}
				/>
			</PageContent>
		</WithSidebar>
	);
}

export default Texts;
