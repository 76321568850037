import React from "react";
import { Image } from "./styles";

/**
 *
 * @constructor
 */
function Preview({ image }) {
	const [file, setFile] = React.useState(null);

	React.useEffect(() => {
		if (image.url) {
			return setFile(image.url);
		}

		const reader = new FileReader();
		reader.readAsDataURL(image);

		reader.onload = e => {
			setFile(e.target.result);
		};
	}, [image]);

	if (!file) return null;

	return <Image src={file} />;
}

export default Preview;
