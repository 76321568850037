import React from "react";
import { useQuery } from "react-query";
import { getActiveSubscription } from "../api/subscriptions";
import { getAuthDataFromLocalStorage } from "../utils/authToken";

function useActiveSubscription() {
	const { data, isLoading, error } = useQuery(
		"activeSubscription",
		() => getActiveSubscription(),
		{
			select: data => data?.data,
		}
	);
	// const data = {
	// 	// data: {
	// 	plan: {
	// 		amount: 100,
	// 		product: {
	// 			metadata: { productsAmount: 100, productVariants: true },
	// 		},
	// 	},
	// 	// },
	// };

	return { data };
}

export default useActiveSubscription;
