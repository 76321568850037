import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Formik } from "formik";
import * as Yup from "yup";
import {
	NotificationManager as Alert,
	NotificationManager,
} from "react-notifications";

import WithSidebar from "../../layout/WithSidebar";
import PageContent from "../../components/PageContent";
import PageHeader from "../../components/PageHeader";
import { getProduct, updateProduct } from "../../api/products";
import Loader from "../../components/Loader";
import { Wrapper } from "../../layout/ProductEdit/styles";
import ProductForm from "../../components/ProductForm/ProductForm";
import { displayError } from "../../utils/errors";
import regex from "../../config/regex";
import PageContentOverflow from "../../components/PageContentOverflow";
import FlexBox from "../../components/FlexBox";
import FlexGrow from "../../components/FlexGrow";
import { ReactComponent as Back } from "../../images/icons/back.svg";
import Helmet from "react-helmet";

/**
 *
 */
const EditProductSchema = Yup.object().shape({
	title: Yup.string()
		.matches(regex.title.value, regex.title.message)
		.required(regex.title.message),
	useLink: Yup.boolean(),
	amount: Yup.number().typeError("Amount should be a number"),
	currency: Yup.string().when("amount", {
		is: value => !!value,
		then: Yup.string().required("Currency is a required field"),
	}),
	link: Yup.string().when("useLink", {
		is: true,
		then: Yup.string()
			.typeError("Invalid link format. Should be 'http://domain.com'")
			.url("Invalid link format. Should be 'http://domain.com'")
			.required("Invalid link format. Should be 'http://domain.com'"),
		otherwise: Yup.string().nullable(),
	}),
	model: Yup.mixed()
		.test("model", "Invalid model type or size", value => {
			if (!value) {
				return false;
			}

			// default value is object {id, size, uri}
			if (!(value instanceof File)) {
				return true;
			}

			// 100mb
			if (value.size > 30 * 1024 * 1024) {
				return false;
			}

			const splitName = value.name.split(".");

			if (splitName.length < 2) {
				return false;
			}

			const extension = splitName[splitName.length - 1];

			return extension === "glb" || extension === "gltf";
		})
		.required("Required"),
	image: Yup.mixed()
		.test("image", "Invalid image type or size", value => {
			if (!value) {
				return false;
			}

			// default value is object {id, size, uri}
			if (!(value instanceof File)) {
				return true;
			}

			if (value.size > 500 * 1000) {
				return false;
			}

			return value.type === "image/jpeg" || value.type === "image/png";
		})
		.required("Required"),
	productId: Yup.string().strip(),
});

/**
 *
 * @param productName
 * @returns {JSX.Element}
 * @constructor
 */
function EditProduct({ productName = "Chair Evy" }) {
	const params = useParams();
	const history = useHistory();

	const { data, isLoading, error } = useQuery(
		["product", params.id],
		() => getProduct(params.id),
		{
			cacheTime: 1,
		}
	);

	/**
	 * Form submit handler
	 */
	const handleSubmit = React.useCallback(
		(values, { setSubmitting }) => {
			if (!data) {
				return null;
			}
			const { data: product } = data;

			const formData = new FormData();

			for (let key in values) {
				//dont include objects in form data
				if (
					typeof values[key] === "object" &&
					!(values[key] instanceof File) &&
					!Array.isArray(values[key]) &&
					key !== "link"
				) {
					continue;
				}

				if (key === "productId") {
					continue;
				}

				if (key === "useLink") {
					continue;
				}

				const value = Array.isArray(values[key])
					? JSON.stringify(values[key])
					: values[key];
				if (key !== "variations") {
					formData.append(key, value);
				}
			}
			if (values["artworkImage"]) {
				formData.delete("arWidth");
				formData.delete("arHeight");
				formData.delete("typeOfFrame");
				formData.delete("frameWidth");
				formData.delete("frameThickness");
				formData.append("artworkProps[arWidth]", values["arWidth"]);
				formData.append("artworkProps[arHeight]", values["arHeight"]);
				formData.append(
					"artworkProps[typeOfFrame]",
					values["typeOfFrame"]
				);
				formData.append(
					"artworkProps[frameWidth]",
					values["frameWidth"]
				);
				formData.append(
					"artworkProps[frameThickness]",
					values["frameThickness"]
				);
			} else {
				formData.delete("arWidth");
				formData.delete("arHeight");
				formData.delete("typeOfFrame");
				formData.delete("frameWidth");
				formData.delete("frameThickness");
			}
			if (!values["useLink"]) {
				formData.delete("link");
				formData.append("link", "");
			}
			if (values["tags"].length <= 0) {
				formData.delete("tags");
				formData.append("tags", "0");
			}
			if (values["tags"].length > 0) {
				formData.delete("tags");
				values["tags"].forEach(val => {
					formData.append("tags[]", val);
				});
			}
			if (values["variations"].length <= 0) {
				formData.delete("variations");
				formData.append("variations[]", "0");
			}
			if (values["variations"].length >= 0) {
				formData.delete("variations");
				values["variations"].forEach(val => {
					formData.append("variations[]", val);
				});
			}
			formData.delete("useExisting");
			formData.delete("productType");
			formData.delete("viewMode");
			formData.delete("parent");
			formData.delete("artwork");
			updateProduct(product.id, formData)
				// Successfully updated
				.then(() => {
					history.push("/products");

					return Alert.success(
						`Product '${product.title}' saved!`,
						"Success"
					);
				})
				// Handle error
				.catch(displayError)
				// Finish form submitting
				.finally(() => setSubmitting(false));
		},
		[data]
	);

	if (isLoading) {
		return <Loader />;
	}

	if (error) {
		NotificationManager.error(error.message, "Error");

		history.push("/products");
	}

	const { data: product } = data;

	return (
		<WithSidebar>
			<Helmet>
				<title>Augmento.com - Edit product</title>
			</Helmet>
			<PageHeader title={`Editing '${product.title}'`} />

			<PageContentOverflow>
				<FlexBox style={{ marginBottom: "16px" }}>
					<FlexGrow
						style={{
							flexGrow: 0,
							whiteSpace: "nowrap",
							cursor: "pointer",
						}}
						onClick={() => {
							history.push("/products");
						}}
					>
						<Back
							height={18}
							style={{ verticalAlign: "text-bottom" }}
						/>
						<span>Back</span>
					</FlexGrow>
				</FlexBox>
				<Wrapper>
					<Formik
						component={ProductForm}
						initialValues={{
							productId: product.id,
							title: product.title,
							image: product.image,
							amount: product.price?.amount || "",
							currency: product.price?.currency || "",
							artworkImage: product.artworkImage,
							model: product.model,
							link:
								product.link && product.link !== "null"
									? product.link
									: "",
							useLink: !!product.link,
							category: product.category
								? product.category.id
								: null,
							variations:
								product.variations?.map(p => p.id) || [],
							parent: product.primaryProductId,
							tags: product.tags || [],
							productType:
								product?.artworkImage && product?.artworkProps
									? 2
									: 1,
							viewMode: "edit",
							arWidth: product?.artworkProps?.arWidth,
							arHeight: product?.artworkProps?.arHeight,
							typeOfFrame: product?.artworkProps?.typeOfFrame,
							frameWidth: product?.artworkProps?.frameWidth,
							frameThickness:
								product?.artworkProps?.frameThickness,
						}}
						validationSchema={EditProductSchema}
						onSubmit={handleSubmit}
					/>
				</Wrapper>
			</PageContentOverflow>
		</WithSidebar>
	);
}

export default EditProduct;
