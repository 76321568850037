import Products from "../pages/products/Products";
import Categories from "../pages/categories/Categories";
import Settings from "../pages/Settings";
import { ReactComponent as Cat } from "../images/icons/cat.svg";
import { ReactComponent as Star } from "../images/icons/star.svg";
import { ReactComponent as Box } from "../images/icons/boxnav.svg";
import { ReactComponent as SettingsIcon } from "../images/icons/settings.svg";
import Collections from "../pages/collections/Collections";

const superAdminPages = [
	{
		title: "Public Collections",
		link: "/collections",
		icon: Cat,
		component: Collections,
	},
	{
		title: "Public Categories",
		link: "/categories",
		icon: Box,
		component: Categories,
	},
	{
		title: "Products",
		link: "/products",
		icon: Star,
		component: Products,
	},
	{
		title: "Settings",
		link: "/settings",
		icon: SettingsIcon,
		component: Settings,
	},
];

export default superAdminPages;
