import { NotificationManager as Alert } from "react-notifications";

/**
 *
 * @param res
 */
function handleResponseErrors(res) {
	const {
		data,
		data: { errors },
	} = res;

	if (errors) {
		return errors.forEach(err => Alert.error(err.msg, "Error"));
	}

	return Alert.error(data.message, "Error");
}

/**
 *
 * @param error
 */
export const displayError = error => {
	if (error.response) {
		return handleResponseErrors(error.response);
	}

	return Alert.error(error.message, "Error");
};
