import * as Yup from "yup";
import regex from "../config/regex";

export const CreateProductSchema = Yup.object().shape(
	{
		title: Yup.string()
			.matches(regex.title.value, regex.title.message)
			.required(regex.title.message),
		category: Yup.string().required("Required"),
		useExisting: Yup.boolean(),
		amount: Yup.number().typeError("Amount should be a number"),
		currency: Yup.string().when("amount", {
			is: value => !!value,
			then: Yup.string().required("Currency is a required field"),
		}),
		arWidth: Yup.number().nullable(),
		arHeight: Yup.number().nullable(),
		typeOfFrame: Yup.string().nullable(),
		frameWidth: Yup.number().nullable(),
		frameThickness: Yup.number().nullable(),
		image: Yup.mixed()
			.test("image", "Invalid image type or size", value => {
				if (!value) {
					return false;
				}

				// default value is object {id, size, uri}
				if (!(value instanceof File)) {
					return true;
				}

				if (value.size > 500 * 1000) {
					return false;
				}

				return (
					value.type === "image/jpeg" || value.type === "image/png"
				);
			})
			.required("Required"),
		model: Yup.mixed().when("useExisting", {
			is: val => val === true,
			then: Yup.mixed()
				.test("model-type", "Invalid model type or size", value => {
					if (!value) {
						return false;
					}

					if (value.size > 30 * 1024 * 1024) {
						return false;
					}

					const splitName = value.name?.split(".");

					if (splitName?.length < 2) {
						return false;
					}

					const extension = splitName[splitName?.length - 1];

					return extension === "glb" || extension === "gltf";
				})
				.required("Required"),
			otherwise: Yup.mixed().nullable(),
		}),

		artworkImage: Yup.mixed().when("useExisting", {
			is: val1 => val1 === false,
			then: Yup.mixed()
				.test("artwork-type", "Invalid artwork type or size", value => {
					if (!value) {
						return false;
					}

					if (value.size > 5000000) {
						return false;
					}

					return (
						value.type === "image/jpeg" ||
						value.type === "image/png"
					);
				})
				.required("Required"),
			otherwise: Yup.mixed().nullable(),
		}),
		placementType: Yup.string(),
		useLink: Yup.boolean(),
		link: Yup.string().when("useLink", {
			is: true,
			then: Yup.string()
				.url("Invalid link format. Should be 'http://domain.com'")
				.required("Invalid link format. Should be 'http://domain.com'")
				.typeError(
					"Invalid link format. Should be 'http://domain.com'"
				),
			otherwise: Yup.string().nullable(),
		}),
	},
	[["amout", "currency"]]
);
