import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { List, Wrapper } from "./Icons.styles";
import Pagination from "../Pagination";

const ITEMS_PER_PAGE = 20;

/**
 *
 * @constructor
 */
function Icons(props) {
	const { icons, classes, handleClickOpen, page, setPage } = props;

	const handleNext = () => {
		setPage(prev => prev + 1);
	};

	const handlePrev = () => {
		setPage(prev => prev - 1);
	};

	return (
		<Wrapper>
			<List>
				{icons
					.slice(ITEMS_PER_PAGE * (page - 1), ITEMS_PER_PAGE * page)
					.map(icon => {
						return (
							<span
								key={icon.key}
								className={clsx("markdown-body", classes.icon)}
							>
								<icon.Icon
									onClick={handleClickOpen}
									tabIndex={-1}
									title={icon.key}
									className={classes.iconSvg}
									data-ga-event-category="material-icons"
									data-ga-event-action="click"
									data-ga-event-label={icon.key}
								/>

								<p>{icon.key}</p>
							</span>
						);
					})}
			</List>

			<Pagination
				page={page}
				totalPages={Math.ceil(icons.length / ITEMS_PER_PAGE)}
				goNext={handleNext}
				goPrev={handlePrev}
			/>
		</Wrapper>
	);
}

Icons.propTypes = {
	classes: PropTypes.object.isRequired,
	handleClickOpen: PropTypes.func.isRequired,
	icons: PropTypes.array.isRequired,
};

export default React.memo(Icons);
