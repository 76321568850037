import WithSidebar from "../../layout/WithSidebar";
import PageContent from "../../components/PageContent";
import PageHeader from "../../components/PageHeader";
import ProductsList from "../../components/ProductsList";
import { useQuery } from "react-query";
import { getProducts } from "../../api/products";
import { getAuthDataFromLocalStorage } from "../../utils/authToken";
import { useHistory, useLocation } from "react-router-dom";
import { getCollections } from "../../api/collections";
import CollectionsList from "../../components/CollectionsList";
import CustomPageHeader from "../../components/CustomPageHeader";
import qs from "query-string";

function Collections() {
	const location = useLocation();
	const { companyId } = getAuthDataFromLocalStorage();
	const history = useHistory();

	// const { data, isLoading, error } = useQuery(
	// 	["collections", location.search],
	// 	() => getCollections(companyId, location.search)
	// );
	const { data, isLoading, error } = useQuery(
		["collections", location.search],
		() => getCollections(companyId, location.search),
		{
			select: data => data?.data,
		}
	);
	const search = qs.parse(location.search);

	const applyLimit = data => {
		history.push({
			search: qs.stringify({
				...search,
				limit: data.value,
			}),
		});
	};

	return (
		<WithSidebar>
			<CustomPageHeader
				title={"Public library Collections"}
				name={"collections"}
				totalProducts={100}
				usedProducts={data ? data?.data.length : 0}
				showDropdown
				limit={data ? data?.pagination?.limit : 25}
				changeLimit={applyLimit}
			/>

			<PageContent>
				<CollectionsList
					data={data?.data}
					error={error}
					isLoading={isLoading}
				/>
			</PageContent>
		</WithSidebar>
	);
}

export default Collections;
