import React from "react";
import WithSidebar from "../layout/WithSidebar";
import PageHeader from "../components/PageHeader";
import PageContent from "../components/PageContent";
import SettingsForm from "../components/SettingsForm";
import { Formik } from "formik";
import * as Yup from "yup";
import { useQuery } from "react-query";
import { editCompany, getCompany } from "../api/company";
import { getAuthDataFromLocalStorage } from "../utils/authToken";
import Loader from "../components/Loader";
import {
	NotificationManager as Alert,
	NotificationManager,
} from "react-notifications";
import { useHistory } from "react-router-dom";
import { displayError } from "../utils/errors";
import regex from "../config/regex";
import Helmet from "react-helmet";
import { Steps } from "intro.js-react";
import useOnboarding from "../hooks/useOnboarding";
import { handleOnchange, handleOnExit } from "../utils/products";

/**
 *
 */
const SettingsSchema = Yup.object().shape({
	name: Yup.string()
		.matches(regex.title.value, regex.title.message)
		.required("Required"),
	email: Yup.string().email().required(),
	city: Yup.string()
		.matches(regex.city.value, regex.city.message)
		.required("Required"),
	address: Yup.string()
		.matches(regex.title.value, regex.title.message)
		.required("Required"),
	zipCode: Yup.string().min(6).max(8).required(),
	country: Yup.string()
		.matches(regex.title.value, regex.title.message)
		.required("Required"),
	vat: Yup.string()
		.matches(regex.vat.value, regex.vat.message)
		.required("Required"),
	websiteLink: Yup.string()
		.matches(regex.websiteLink.value, regex.websiteLink.message)
		.required("Required"),
	description: Yup.string()
		.matches(
			regex.companyDescription.value,
			regex.companyDescription.message
		)
		.required("Required"),
	logo: Yup.mixed()
		.test("logo", "Invalid image or size", value => {
			if (!value) {
				return false;
			}

			// default value is object {id, size, uri}
			if (!(value instanceof File)) {
				return true;
			}

			if (value.size > 30720 * 1000) {
				return false;
			}

			return value.type === "image/jpeg" || value.type === "image/png";
		})
		.required("Required"),
	welcomeScreen: Yup.mixed()
		.test("screen", "Invalid image", value => {
			if (!value) {
				return false;
			}

			// default value is object {id, size, uri}
			if (!(value instanceof File)) {
				return true;
			}

			if (value.size > 4096 * 1000) {
				return false;
			}

			return value.type === "image/jpeg" || value.type === "image/png";
		})
		.required("Required"),
});

const allSteps = [
	{
		element: ".settings-form",
		title: "Settings",
		intro: "Please fill out this information to customize your profile and let other users know how they can reach your company",
	},
	{
		intro: "",
	},
];

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Settings() {
	const { companyId } = getAuthDataFromLocalStorage();
	const history = useHistory();
	const { onboarding } = useOnboarding(allSteps);

	/**
	 * Fetching company
	 */
	const {
		data: company,
		isLoading,
		error,
		remove,
	} = useQuery("company", () => getCompany(companyId), {
		select: data => data?.data,
	});

	/**
	 * Form submit handler
	 */
	const handleSubmit = React.useCallback(
		(values, { setSubmitting }) => {
			if (!company) {
				return null;
			}

			const formData = new FormData();

			for (let key in values) {
				//dont include objects in form data
				if (
					typeof values[key] === "object" &&
					!(values[key] instanceof File) &&
					!Array.isArray(values[key])
				)
					continue;

				const value = Array.isArray(values[key])
					? JSON.stringify(values[key])
					: values[key];

				formData.append(key, value);
			}

			editCompany(company.id, formData)
				// Successfully updated
				.then(() => {
					Alert.success(`Changes has been saved!`, "Success");
					remove();
				})
				// Handle error
				.catch(displayError)
				// Finish form submitting
				.finally(() => setSubmitting(false));
		},
		[company]
	);

	if (isLoading) return <Loader />;

	if (error) {
		NotificationManager.error(error.message, "Error");

		history.push("/");
	}

	const { stepsEnabled, steps, initialStep } = onboarding;
	const nextOnboardingStep = "/categories?onboarding=true";
	return (
		<>
			<Steps
				enabled={stepsEnabled}
				steps={steps}
				initialStep={initialStep}
				onExit={handleOnExit}
				onChange={step => handleOnchange(step, nextOnboardingStep)}
			/>
			<WithSidebar>
				<Helmet>
					<title>Augmento.com - Settings</title>
				</Helmet>
				<PageHeader title={"Settings"} />

				<PageContent className={"settings-form"}>
					<Formik
						component={SettingsForm}
						initialValues={{
							name: company.name || "",
							description: company.description || "",
							country: company.country || "",
							email: company.email || "",
							zipCode: company.zipCode || "",
							vat: company.vat || "",
							address: company.address || "",
							city: company.city || "",
							logo: company.logo || null,
							darkModeLogo: company.darkModeLogo || null,
							welcomeScreen: company.welcomeScreen || null,
							websiteLink: company.websiteLink || "",
						}}
						validationSchema={SettingsSchema}
						onSubmit={handleSubmit}
					/>
				</PageContent>
			</WithSidebar>
		</>
	);
}

export default Settings;
