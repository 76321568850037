import { SearchIcon, SearchInput, SearchWrapper } from "./styles";
import search from "../../images/icons/search.svg";

function Search({ onBlur, onFocus, onChange, value }) {
	return (
		<SearchWrapper>
			<SearchIcon src={search} />

			<SearchInput
				style={{ maxWidth: "100%" }}
				onFocus={onFocus}
				onBlur={onBlur}
				onChange={onChange}
				placeholder={"Search clients"}
				value={value}
				autoFocus
			/>
		</SearchWrapper>
	);
}

export default Search;
