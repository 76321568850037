import styled from "styled-components";

export const Wrapper = styled.div`
	margin: 0 0 28px;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	background: rgba(0, 0, 0, 0.05);
	border-radius: 6px;
	padding: 8px 11px;
`;

export const Input = styled.input`
	height: 30px;
	display: block;
	margin-left: 11px;
	margin-top: 3px;
	margin-bottom: 3px;
	background-color: transparent;
	border: none;
	outline: none;
	box-shadow: none;

	&:first-child {
		margin-left: 0;
	}
`;

export const Tag = styled.div`
	font-size: 14px;
	font-weight: 300;
	color: #fff;
	background: #333641;
	border-radius: 38px;
	padding: 7px 20px;
	cursor: pointer;
	margin-right: 5px;
	margin-top: 3px;
	margin-bottom: 3px;
`;
