import styled from "styled-components";
import { darken } from "polished";
import { PrimaryButton } from "../../styles/ui";
export const Cancel = styled.div`
	color: #424e61;
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	line-height: 21px;
	text-align: center;
	cursor: pointer;
`;
export const RightAlign = styled.div`
	display: flex;
	justify-content: flex-end;
`;
export const CenterAlign = styled.div`
	display: flex;
	justify-content: center;
`;
