import React from "react";
import { useQuery } from "react-query";
import { getPaymentMethod } from "../api/subscriptions";
import { getAuthDataFromLocalStorage } from "../utils/authToken";

function useActiveCard() {
	const { userId } = React.useMemo(getAuthDataFromLocalStorage, []);

	const { data, isLoading, error } = useQuery(
		"paymentMethod",
		() => getPaymentMethod(userId),
		{
			refetchOnWindowFocus: false,
			select: data => data?.data,
		}
	);

	return { data, isLoading, error };
}

export default useActiveCard;
