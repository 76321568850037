import styled, { css } from "styled-components";

export const VariantsWrapper = styled.div`
	${({ outlined }) => {
		if (outlined) {
			return `
        position: relative;
        opacity: .5;
        border: 1px dashed #979797;
        padding: 25px 25px 0 25px;
        border-radius: 10px;
        
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          
        }
      `;
		}
	}}
`;

export const UpgradeMsg = styled.div`
	width: 100%;
	margin-left: -25px;
	background: rgba(0, 0, 0, 0.05);
`;
export const UpgradeLink = styled.div``;

export const Type = styled.div`
	cursor: pointer;
	padding: 15px 0px;
	background: white;
	border: solid 1px ${props => (props.typeSelected ? "#36b2e5" : "#c4c4c4")};
	border-radius: 12px;
	height: max-content;
	margin: 0px 4px;
	text-align: center;

	box-shadow: 2px 10px 15px -3px rgb(0 0 0 / 0.1),
		0 4px 6px -4px rgb(0 0 0 / 0.1);

	outline: ${props => props.typeSelected && "1px solid #36b2e5"};

	&:hover {
		outline: 1px solid #36b2e5;
		border-color: #36b2e5;
	}
`;

export const Title = styled.h3`
	margin-bottom: 52px;
	width: max-content;
	height: 18px;
	color: #333641;
	font-size: 18px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: normal;
	line-height: 14px;
	text-align: left;
`;
export const StyledText = styled.span`
	color: #000000;
	font-size: 18px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	line-height: 14px;
	text-align: center;
	display: inline-flex;
	flex-grow: 2;
	justify-content: center;
	white-space: nowrap;
`;
export const Image = styled.img`
	border-radius: 12px 0px 0px 12px;
	height: ${props => props.height || "81px"};
	width: auto;
	display: inline-flex;
	flex-grow: 0;

	@media screen and (min-width: 1200px) {
		height: ${props => props.height || "180px"};
	}
`;

export const ProductsTypeWrapper = styled.section`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: ${props => (props.justify ? props.justify : "flex-start")};
	margin-bottom: ${props => (props.mb ? props.mb : 0)}px;
	height: max-content;
	gap: 12px;

	@media screen and (max-width: 480px) {
		flex-direction: column;
	}
`;

export const ContinueButtonPosition = styled.section`
	text-align: right;

	@media screen and (max-width: 480px) {
		text-align: center;
	}
`;

export const ContinueButton = styled.button`
	width: max-content;
	padding: 12px 80px;
	background-color: #20b2e5;
	border: none;
	border-radius: 8px;
	color: white;
	font-size: 16px;
	line-height: 20px;
	cursor: pointer;

	margin-bottom: 12px;

	&:disabled {
		cursor: no-drop;
		opacity: 50%;
	}

	&:hover:enabled {
		opacity: 80%;
	}
`;
