import WithSidebar from "../layout/WithSidebar";
import PageHeader from "../components/PageHeader";
import PageContent from "../components/PageContent";
import CurrentPlan from "../components/CurrentPlan";
import CardForm from "../components/CardForm";
import Helmet from "react-helmet";

function Plan() {
	return (
		<WithSidebar>
			<Helmet>
				<title>Augmento.com - Plan</title>
			</Helmet>
			<PageHeader title={"Plan"} />
			<PageContent>
				<CurrentPlan />

				<CardForm />
			</PageContent>
		</WithSidebar>
	);
}

export default Plan;
