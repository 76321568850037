import ReactModal from "react-modal";

function Modal({
	isOpen,
	setOpen,
	onDismiss,
	onClose,
	children = null,
	customStyle,
}) {
	return (
		<>
			<ReactModal
				ariaHideApp={false}
				shouldCloseOnOverlayClick={true}
				isOpen={isOpen}
				onRequestClose={() => {
					setOpen(false);
					onClose?.("", "");
				}}
				closeTimeoutMS={400}
				style={{
					overlay: {
						backgroundColor: "rgb(0 0 0 / 63%)",
						backdropFilter: "blur(6px)",
						zIndex: 99900,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						overflow: "auto",
						...customStyle?.overlay,
					},
					content: {
						width: "800px",
						borderRadius: "30px",
						backgroundColor: "white",
						maxWidth: 900,
						maxHeight: 700,
						height: "min-content",
						position: "static",
						padding: "16px",
						...customStyle?.content,
					},
				}}
			>
				{children}
			</ReactModal>
		</>
	);
}

export default Modal;
