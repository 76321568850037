import { useGoogleLogin } from "@react-oauth/google";
import { Img, LoginButton } from "./styles";

const GoogleAuthButton = ({ label, onSuccess, onError }) => {
	const login = useGoogleLogin({
		onSuccess: response => onSuccess(response),
		onError: response => onError(response),
	});

	return (
		<LoginButton onClick={() => login()}>
			<Img src="/assets/img/google-icon.png" />
			{label}
		</LoginButton>
	);
};

export default GoogleAuthButton;
