import WithSidebar from "../../layout/WithSidebar";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ArrowLongLeftIcon } from "@heroicons/react/24/solid";
import { BackButton, BackText, ContentPosition } from "./Products.style";

function ProductsContentWrapper({ helmet, children }) {
	const { goBack } = useHistory();

	return (
		<WithSidebar>
			<Helmet>
				<title>{helmet}</title>
			</Helmet>

			<BackButton onClick={goBack}>
				<ArrowLongLeftIcon width="20px" height="20px" />
				<BackText>Back</BackText>
			</BackButton>

			<ContentPosition>{children}</ContentPosition>
		</WithSidebar>
	);
}

export default ProductsContentWrapper;
