import React from "react";
import { Body, Header, Tab, Wrapper } from "./styles";

function Tabs({ data, active, setActive }) {
	if (!active) {
		return null;
	}

	return (
		<Wrapper>
			<Header>
				{data.map(tab => (
					<Tab
						isActive={tab.key === active.key}
						onClick={() => setActive(tab)}
						key={tab.key}
					>
						{tab.title}
					</Tab>
				))}
			</Header>

			<Body>{active.component}</Body>
		</Wrapper>
	);
}

export default Tabs;
