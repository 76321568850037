import React from "react";
import WithSidebar from "../../layout/WithSidebar";
import PageHeader from "../../components/PageHeader";
import PageContent from "../../components/PageContent";
import CategoryForm from "../../components/CategoryForm";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { getAuthDataFromLocalStorage } from "../../utils/authToken";
import { NotificationManager as Alert } from "react-notifications";
import { displayError } from "../../utils/errors";
import { createCategory } from "../../api/categories";
import regex from "../../config/regex";
import CategoryPublicForm from "../../components/CategoryPublicForm";
import Helmet from "react-helmet";

/**
 *
 */
const CreateCategorySchema = Yup.object().shape({
	title: Yup.string()
		.matches(regex.title.value, regex.title.message)
		.required(regex.title.message),
	image: Yup.mixed()
		.test("image-type", "Invalid image type or size", value => {
			if (!value) {
				return false;
			}

			if (value.size > 30720 * 1000) {
				return false;
			}

			return (
				value.type === "image/jpeg" ||
				value.type === "image/png" ||
				value.type === "image/svg+xml"
			);
		})
		.required("Required"),
	client: Yup.string(),
	clientCategories: Yup.array(),
});

/**
 *
 * @constructor
 */
function CreatePublicCategory() {
	const history = useHistory();

	/**
	 * Form submit handler
	 */
	const handleSubmit = React.useCallback(
		(values, { setSubmitting }) => {
			const { companyId } = getAuthDataFromLocalStorage();

			const formData = new FormData();
			for (let key in values) {
				if (
					typeof values[key] === "object" &&
					!(values[key] instanceof File) &&
					!Array.isArray(values[key])
				)
					continue;

				const value = Array.isArray(values[key])
					? JSON.stringify(values[key])
					: values[key];

				formData.append(key, value);
			}

			createCategory(companyId, formData)
				// Successfully updated
				.then(() => {
					history.push("/categories");

					return Alert.success(
						`Category '${values.title}' has been created!`,
						"Success"
					);
				})
				// Handle error
				.catch(displayError)
				// Finish form submitting
				.finally(() => setSubmitting(false));
		},
		[history]
	);

	return (
		<WithSidebar>
			<Helmet>
				<title>Augmento.com - Create public category</title>
			</Helmet>
			<PageHeader title={"Add new Public Category"} />

			<PageContent>
				<Formik
					component={CategoryPublicForm}
					initialValues={{
						title: "",
						image: null,
						isPopular: true,
						client: "",
						clientCategories: [],
					}}
					validationSchema={CreateCategorySchema}
					onSubmit={handleSubmit}
				/>
			</PageContent>
		</WithSidebar>
	);
}

export default CreatePublicCategory;
