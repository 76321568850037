import React from "react";
import pages from "../../config/pages";
import superAdminPages from "../../config/superAdminPages";
import AuthContext from "../../contexts/auth";
import { Icon, Item, Title } from "./styles";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { NavItem } from "../Navbar/Navbar.style";

function Navigation({ isNavbar = false }) {
	const auth = React.useContext(AuthContext);
	const { pathname } = useLocation();

	const DefinedItem = props => {
		return isNavbar ? <NavItem {...props} /> : <Item {...props} />;
	};

	const locationItemName = pathname.split("/");

	return (
		<>
			{auth.isSuperAdmin
				? superAdminPages.map(page => (
						<DefinedItem to={page.link} key={page.link}>
							<Icon>
								<page.icon
									width={isNavbar ? "30px" : "24px"}
									height={isNavbar ? "30px" : "24px"}
								/>
							</Icon>

							<Title
								isNameMatched={locationItemName.includes(
									page.title.toLowerCase()
								)}
							>
								{page.title}
							</Title>
						</DefinedItem>
				  ))
				: pages.map(page => (
						<DefinedItem
							to={page.link}
							key={page.link}
							className={page.title}
						>
							<Icon>
								<page.icon
									width={isNavbar ? "30px" : "24px"}
									height={isNavbar ? "30px" : "24px"}
								/>
							</Icon>

							<Title
								isNameMatched={locationItemName.includes(
									page.title.toLowerCase()
								)}
							>
								{page.title}
							</Title>
						</DefinedItem>
				  ))}
		</>
	);
}

export default Navigation;
