import styled from "styled-components";

export const CustomRadio = styled.div`
	position: relative;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	border: 1px solid #333641;
	margin-right: 10px;

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 9px;
		height: 9px;
		border-radius: 50%;
		background-image: linear-gradient(270deg, #43bbff 0%, #3676f1 100%);
		opacity: 0;
	}
`;

export const Input = styled.input`
	position: absolute;
	width: 0;
	height: 0;
	visibility: hidden;
	z-index: -1;

	&:disabled + div {
		opacity: 0.5;
	}

	&:checked + div {
		&::after {
			opacity: 1;
		}
	}
`;

export const Wrapper = styled.label`
	display: flex;
	align-items: center;
	margin-bottom: ${props => props.mb || 0}px;

	&:not(:last-child) {
		margin-right: 40px;
	}
`;
