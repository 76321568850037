import { Wrapper } from "./styles";
import ReactSelect from "react-select";
import { ErrorMessage } from "formik";
import { AlignLeft, Error } from "../../pages/auth/styles";

function Select({ width, mb, options, field, form, placeholder }) {
	const customStyles = {
		control: provided => ({
			...provided,
			minHeight: "40px",
			borderRadius: "6px",
			marginBottom: "3px",
			border: "solid 1px #cbcbcb",
			backgroundColor: "white",
			boxShadow:
				"0 10px 15px -3px rgb(0 0 0 / 0.1),0 4px 6px -4px rgb(0 0 0 / 0.1)",
		}),
		indicatorSeparator: () => ({
			display: "none",
		}),
	};

	return (
		<Wrapper width={width} mb={mb}>
			<ReactSelect
				placeholder={placeholder}
				styles={customStyles}
				options={options}
				name={field.name}
				value={
					options
						? options.find(option => option.value === field.value)
						: ""
				}
				defaul
				onChange={option =>
					form.setFieldValue(field.name, option.value)
				}
				onBlur={field.onBlur}
			/>

			<AlignLeft>
				<ErrorMessage name={field.name} component={Error} />
			</AlignLeft>
		</Wrapper>
	);
}

export default Select;
