import { FileInput } from "../MediaUploader/styles";
import React from "react";
import { Browse, Label, Wrapper, Text } from "./styles";
import { ReactComponent as Doc } from "../../images/icons/document.svg";

function SettingUploader({ value, onChange }) {
	const input = React.createRef();
	const caption = React.useMemo(() => {
		if (value?.url) {
			const strName = value.url.substring(value.url.lastIndexOf("/") + 1);
			return {
				name: strName.split("?")[0],
			};
		}

		return value;
	}, [value]);

	const handleDrop = e => {
		e.preventDefault();
		e.stopPropagation();
		let files = [...e.dataTransfer.files];

		if (files && files.length > 0) {
			onChange(files[0]);
			e.dataTransfer.clearData();
		}
	};

	const handleDragOver = e => {
		e.preventDefault();
		e.stopPropagation();
		e.dataTransfer.dropEffect = "copy";
	};

	return (
		<Wrapper
			onDrop={e => handleDrop(e)}
			onDragOver={e => handleDragOver(e)}
		>
			<Label>
				<Doc />
				&nbsp;&nbsp;
				<Text>{caption ? caption.name : " Drag image or"}</Text>
			</Label>

			<FileInput
				ref={input}
				type="file"
				onChange={e => onChange(e.target.files[0])}
				accept={"image/png, image/jpeg"}
			/>

			<Browse
				type={"button"}
				ml={8}
				onClick={() => input.current.click()}
			>
				{caption ? "Replace" : "Browse"}
			</Browse>
		</Wrapper>
	);
}

export default SettingUploader;
