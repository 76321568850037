import styled from "styled-components";
import { PrimaryButton } from "../../styles/ui";

export const H3 = styled.h3`
	font-size: 21px;
	color: rgb(44, 71, 88);
	margin: 0 0 ${props => props.mb || 0}px;
`;

export const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr 3fr;
	row-gap: ${props => props.rowGap || 0}px;
	margin-bottom: ${props => props.mb || 0}px;
`;

export const Label = styled.label`
	font-size: 18px;
	color: #000;
`;

export const RadioGroup = styled.div`
	padding-top: 5px;
`;

export const AlignCenter = styled.div`
	display: flex;
	align-items: center;
`;

export const AlignStart = styled.div`
	display: flex;
	align-items: flex-start;
	padding-top: ${props => props.pt || 0}px;
`;

export const Button = styled(PrimaryButton)`
	padding: 0 60px;
`;

export const Info = styled.span`
	font-size: 10px;
	color: #979797;
	margin-left: ${props => props.ml || 0}px;
	margin-right: ${props => props.mr || 0}px;
`;
