import React from "react";
import { ErrorMessage, Field, Form, useField } from "formik";
import { Wrapper } from "../../layout/CategoryEdit/styles";
import { H3, Input, PrimaryButton } from "../../styles/ui";
import {
	FileInput,
	Left,
	RadioGroup,
	RightStart,
	UploaderWrapper,
} from "./styles";
import Radio from "../Radio";
import screen from "../../images/screen.png";
import { Edit } from "../ProductsList/styles";
import { AlignRight, Error, InputWrapper } from "../../pages/auth/styles";
import Preview from "./Preview";
import SearchIcons from "../SearchIcons";
import Checkbox from "../Checkbox";
import ClientSelect from "../ClientSelect";
import ClientCategorySelect from "../ClientCategorySelect";

/**
 *
 * @param values
 * @param setFieldValue
 * @param isSubmitting
 * @returns {JSX.Element}
 * @constructor
 */
function CategoryPublicForm({
	values,
	initialValues,
	setFieldValue,
	isSubmitting,
}) {
	const input = React.createRef();
	const [useLinkCategories, setUseLinkCategories] = React.useState(
		initialValues.linkedCategories
			? !!initialValues.linkedCategories.length
			: false
	);
	const [selectedClient, setSelectedClient] = useField({ name: "client" });
	const handleCheck = e => {
		setUseLinkCategories(e.target.checked);
	};
	return (
		<Form>
			<Wrapper>
				<Left>
					<InputWrapper mb={33}>
						<Field
							name="title"
							as={Input}
							placeholder={"Category name"}
							mb={3}
						/>
						<AlignRight>
							<ErrorMessage name={"title"} component={Error} />
						</AlignRight>
					</InputWrapper>

					<RadioGroup>
						<Radio
							name={"externalLink"}
							title={"Use image"}
							value={true}
							state={values.isPopular}
							handleChange={value => {
								setFieldValue("isPopular", value);
								setFieldValue("image", null);
							}}
						/>
						<Radio
							name={"externalLink"}
							title={"Use icon"}
							value={false}
							state={values.isPopular}
							handleChange={value => {
								setFieldValue("isPopular", value);
								setFieldValue("image", null);
							}}
						/>
					</RadioGroup>

					{values.image && <Preview image={values.image} />}

					{!values.isPopular && (
						<SearchIcons
							onChange={value => setFieldValue("image", value)}
						/>
					)}

					<UploaderWrapper>
						<FileInput
							type="file"
							ref={input}
							onChange={e => {
								setFieldValue("image", e.target.files[0]);
							}}
							accept={
								values.isPopular
									? "image/jpeg, image/png"
									: "image/svg+xml"
							}
						/>

						<Edit
							type="button"
							onClick={() => input.current.click()}
						>
							{values.image
								? "replace image"
								: values.isPopular
								? "upload"
								: "Or upload custom icon"}
						</Edit>
						<AlignRight>
							<ErrorMessage name={"image"} component={Error} />
						</AlignRight>
					</UploaderWrapper>

					<PrimaryButton type={"submit"} disabled={isSubmitting}>
						{"Save category"}
					</PrimaryButton>
				</Left>

				<RightStart>
					<Checkbox
						onChange={e => handleCheck(e)}
						title={"Link category from existing client"}
					></Checkbox>
					{useLinkCategories && (
						<ClientSelect
							setFieldValue={setFieldValue}
							name="client"
						/>
					)}
					{useLinkCategories && selectedClient.value && (
						<ClientCategorySelect
							name="clientCategories"
							companyId={selectedClient.value}
						/>
					)}
					{/* {useVariations && (
						<LinkedProducts
							excludeProduct={initialValues.productId}
						/>
					)} */}
				</RightStart>
			</Wrapper>
		</Form>
	);
}

export default CategoryPublicForm;
