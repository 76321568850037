import styled from "styled-components";

export const Wrapper = styled.div`
	height: 100%;
	padding: 0 0 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background-image: url(${props => props.background});
	background-size: cover;
	background-position: center;
	background-blend-mode: multiply;
	// background-color: ${props => props.theme.colorMain};
`;

export const Main = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	padding-top: 50px;
`;

export const Logo = styled.img`
	display: block;
	width: 239px;
	height: 67px;
	margin: 0 0 90px;
`;
