import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: ${props => props.mb || 50}px;
`;
export const Container = styled.div`
	opacity: ${props => (props.onCancelling ? ".5" : 1)};
	border-radius: 16px;
	box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.1);
	padding: 25px;
	background: #fff;
	color: #333641;
	max-width: 458px;
`;
export const Header = styled.header`
	padding-bottom: 8px;
`;

export const Text = styled.div`
	font-size: 14px;
	font-weight: 400;
	color: #102b3c;
`;

export const Title = styled.h2`
	font-size: ${props => (props.fontSize ? `${props.fontSize}px` : "16px")};
	margin: 0 0 4px;
	${props => props.fontWeight && `font-weight: ${props.fontWeight}`}
`;
export const Description = styled.h3`
	font-size: 11px;
	margin: 0;
`;
export const HR = styled.div`
	height: 0;
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;
export const Body = styled.div`
	padding-top: 16px;
`;
export const Table = styled.table`
	width: 100%;
`;
export const Row = styled.tr`
	padding: 4px 0;
`;
const Cell = styled.td`
	font-size: 14px;
	font-weight: 700;
	padding-bottom: 8px;
`;
export const TitleCell = styled(Cell)`
	text-align: left;
	font-size: 15px;
	color: #333641;
`;
export const ValueCell = styled(Cell)`
	text-align: right;
	font-size: 15px;
	color: #20b2e5;
`;
export const UL = styled.ul`
	list-style: disc;
	margin: 0;
	padding: 0 0 0 16px;
`;
export const LI = styled.li`
	font-size: 12px;
	color: #333641;
	margin-bottom: 5px;
`;

export const CancelButton = styled.div`
	font-size: 15px;
	color: #f96464;
	margin-top: 15px;
	cursor: pointer;
	margin-bottom: ${props => props.mb || 0}px;
`;

export const ViewsCard = styled.div`
	padding: 10px;
	border: 1px solid #000000;
	border-radius: 8px;
`;
