import React from "react";
import {
	Category,
	Cell,
	Company,
	Delete,
	Edit,
	Name,
	RowWrapper,
	Toggle,
} from "./styles";
import { useHistory } from "react-router-dom";
import {
	NotificationManager as Alert,
	NotificationManager,
} from "react-notifications";
import { ReactComponent as Trash } from "../../images/icons/trash.svg";
import { displayError } from "../../utils/errors";
import { useQueryClient } from "react-query";
import { format } from "date-fns";
import {
	deleteCollection,
	setCollectionVisibility,
} from "../../api/collections";
import ConfirmModal from "../ConfirmModal";

/**
 *
 * @constructor
 */
function Row({ collection }) {
	const history = useHistory();
	const [isVisible, setIsVisible] = React.useState(collection.isVisible);
	const queryClient = useQueryClient();
	const [modalOpen, setModalOpen] = React.useState(false);

	const handleEdit = React.useCallback(() => {
		history.push(`/collections/edit/${collection.id}`, collection);
	}, [history, collection]);

	const handleToggle = React.useCallback(() => {
		setIsVisible(!isVisible);
		const formData = new FormData();
		formData.append("isVisible", !isVisible);
		setCollectionVisibility(collection.id, formData)
			.then(() => {
				NotificationManager.success(
					`Collection '${collection.title}' is now ${
						!isVisible ? "visible" : "invisible"
					}!`,
					"Success!"
				);
			})
			.catch(e => {
				const message = e.response
					? e.response.data.message
					: e.message;

				NotificationManager.error(message, "Error");

				setIsVisible(isVisible);
			});
	}, [isVisible, collection.id, collection.title]);
	const confirmDelete = () => {
		setModalOpen(true);
	};
	const handleDelete = () => {
		deleteCollection(collection.id)
			.then(() => {
				setModalOpen(false);
				Alert.success("Collection successfully deleted", "Success");
				return queryClient.invalidateQueries("collections");
			})
			.catch(displayError);
	};

	return (
		<>
			<ConfirmModal
				isOpen={modalOpen}
				setOpen={setModalOpen}
				deleteConfirmed={handleDelete}
				confirmText={"Do you want to delete this collection?"}
			/>
			<RowWrapper>
				<Name>
					<div
						style={{
							whiteSpace: "nowrap",
							width: "100%",
							textOverflow: "ellipsis",
							overflow: "hidden",
						}}
					>
						{collection.title}
					</div>
				</Name>

				<Category>
					{collection.categories.map(category => (
						<>{category.title}</>
					))}
				</Category>
				<Company>
					{collection.companies.map(company => (
						<>{company.name}</>
					))}
				</Company>

				<Cell>
					<Edit onClick={handleEdit}>{"edit"}</Edit>
				</Cell>

				<Cell center>
					<Toggle active={isVisible} onClick={handleToggle} />
				</Cell>

				<Cell center>
					<Delete onClick={confirmDelete}>
						<Trash />
					</Delete>
				</Cell>
			</RowWrapper>
		</>
	);
}

export default Row;
