import { ErrorMessage, Field } from "formik";
import { AlignLeft, Error, InputWrapper } from "../../pages/auth/styles";
import { Input } from "../../styles/ui";
import { pricesOptions } from "../../utils/selectOptions";
import FlexBox from "../FlexBox";
import Select from "../Select";

const ProductPriceSelect = ({ name }) => {
	return (
		<InputWrapper mb={20}>
			<FlexBox mb={10} alignItems="start">
				<Field
					placeholder={"$ - USD"}
					component={Select}
					name={name}
					width={120}
					options={pricesOptions}
				/>
				<Field
					placeholder="Amount"
					as={Input}
					name="amount"
					width={150}
					ml={100}
				/>
			</FlexBox>
			<AlignLeft>
				<ErrorMessage name={"amount"} component={Error} />
			</AlignLeft>
		</InputWrapper>
	);
};

export default ProductPriceSelect;
