import { ErrorMessage } from "formik";
import { AlignRight, Error } from "../../../pages/auth/styles";
import FlexGrow from "../../FlexGrow";
import MediaUploaderContainer from "../../MediaUploaderContainer";
import {
	GenerateModelSection,
	OptionsSection,
	CustomCheckbox,
	CheckboxWrapper,
	CheckboxLabel,
	H4,
} from "../styles/ProductForm.styles";
import { Input } from "../../../styles/ui";
import SizeSelector from "./SizeSelector";
import SelectFrame from "./SelectFrame";
import { useState, useRef } from "react";
import { InputSection, UnitOfMeasure } from "../styles/SizeSelector.style";

function Generate3DModel({ values, setFieldValue, initialValues }) {
	const inputSizeValue = useRef("");

	const [useGlass, setUseGlass] = useState(false);
	const [longestSide, setLongestSide] = useState(0);

	function longestSideHandl(value) {
		if (inputSizeValue.current === "height") {
			setLongestSide(value);
			setFieldValue("arHeight", value);
		}
		if (inputSizeValue.current === "width") {
			setLongestSide(value);
			setFieldValue("arWidth", value);
		}
	}

	return (
		<GenerateModelSection>
			<FlexGrow>
				<MediaUploaderContainer
					mediaType={"artworkImage"}
					buttonTitle={"Or click to select image"}
					title={"Drop image here"}
					buttonDescription={"max. size 10 Mb"}
					accept={"image/png, image/jpeg"}
					productName={values.title}
					setFieldValue={setFieldValue}
					frame={values.typeOfFrame}
					frameConfig={{
						width: values.arWidth,
						height: values.arHeight,
						frameWidth: values.frameWidth,
						frameHeight: 1,
						frameThickness: values.frameThickness,
					}}
					productId={initialValues.productId}
					useGlass={useGlass}
					longestSideHandl={longestSideHandl}
					longestSide={longestSide}
					inputSizeValue={inputSizeValue}
				/>
				<AlignRight>
					<ErrorMessage name={"artworkImage"} component={Error} />
				</AlignRight>
			</FlexGrow>

			<OptionsSection>
				<SizeSelector
					longestSideHandl={longestSideHandl}
					longestSide={longestSide}
				/>
				<CheckboxWrapper>
					<CustomCheckbox
						type="checkbox"
						onChange={e => setUseGlass(e.target.checked)}
					/>
					<CheckboxLabel>Use glass in front of artwork</CheckboxLabel>
				</CheckboxWrapper>
			</OptionsSection>

			<SelectFrame
				setFieldValue={setFieldValue}
				frame={values.typeOfFrame}
			/>
			{values.typeOfFrame && (
				<section>
					<H4>Canvas Thickness</H4>
					<InputSection>
						<Input
							placeholder="0"
							type="number"
							width={100}
							name="frameThickness"
							defaultValue={initialValues.frameThickness}
							onChange={e => {
								setFieldValue("frameThickness", e.target.value);
							}}
						/>
						<UnitOfMeasure>cm</UnitOfMeasure>
					</InputSection>
				</section>
			)}
		</GenerateModelSection>
	);
}

export default Generate3DModel;
