import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import debounce from "lodash/debounce";
import Grid from "@material-ui/core/Grid";
import FlexSearch from "flexsearch";
import * as mui from "@material-ui/icons";
import synonyms from "./synonyms";
import Search from "./Search";
import FilterList from "./FilterList";
import Icons from "./Icons";

if (process.env.NODE_ENV !== "production") {
	Object.keys(synonyms).forEach(icon => {
		if (!mui[icon]) {
			throw new Error(`The icon ${icon} does no longer exist.`);
		}
	});
}

/**
 *
 */
const useStyles = makeStyles(theme => ({
	root: {
		minHeight: 500,
		marginBottom: 50,
		paddingLeft: 10,
	},
	icon: {
		display: "inline-block",
		width: 86,
		overflow: "hidden",
		textOverflow: "ellipsis",
		textAlign: "center",
		color: theme.palette.text.secondary,
		margin: "0 4px",
		fontSize: 12,
		"& p": {
			margin: 0,
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
		},
	},
	iconSvg: {
		boxSizing: "content-box",
		cursor: "pointer",
		color: theme.palette.text.primary,
		borderRadius: theme.shape.borderRadius,
		transition: theme.transitions.create(
			["background-color", "box-shadow"],
			{
				duration: theme.transitions.duration.shortest,
			}
		),
		fontSize: 40,
		padding: theme.spacing(2),
		margin: theme.spacing(0.5, 0),
		"&:hover": {
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[1],
		},
	},
	results: {
		marginBottom: theme.spacing(1),
	},
}));

/**
 *
 * @type {Index<unknown>}
 */
const searchIndex = FlexSearch.create({
	async: true,
	tokenize: "full",
});

/**
 *
 * @type {{}}
 */
const allIconsMap = {};

const allIcons = Object.keys(mui)
	.sort()
	.map(key => {
		let tag;
		if (key.indexOf("Outlined") !== -1) {
			tag = "Outlined";
		} else if (key.indexOf("TwoTone") !== -1) {
			tag = "Two tone";
		} else if (key.indexOf("Rounded") !== -1) {
			tag = "Rounded";
		} else if (key.indexOf("Sharp") !== -1) {
			tag = "Sharp";
		} else {
			tag = "Filled";
		}

		let searchable = key.replace(/(Outlined|TwoTone|Rounded|Sharp)$/, "");
		if (synonyms[searchable]) {
			searchable += ` ${synonyms[searchable]}`;
		}
		searchIndex.add(key, searchable);

		const icon = {
			key,
			tag,
			Icon: mui[key],
		};
		allIconsMap[key] = icon;
		return icon;
	});

/**
 *
 * @constructor
 */
function SearchIcons({ onChange }) {
	const classes = useStyles();
	const [tag, setTag] = React.useState("Filled");
	const [keys, setKeys] = React.useState(null);
	const [selectedIcon, setSelectedIcon] = React.useState(null);
	const [page, setPage] = useState(1);


	const isMounted = React.useRef(false);

	React.useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	/**
	 *
	 */
	const handleClickOpen = React.useCallback(
		event => {
			const svg = event.currentTarget.outerHTML;

			const namespacedSvg =
				svg.slice(0, 5) +
				'xmlns="http://www.w3.org/2000/svg" ' +
				svg.slice(5);

			const blob = new Blob([namespacedSvg], { type: "image/svg+xml" });
			const file = new File([blob], `icon-${new Date().getTime()}.svg`, {
				type: "image/svg+xml",
			});


			onChange(file);
			setSelectedIcon(file);
		},
		[onChange]
	);

	/**
	 *
	 */
	const handleChange = React.useMemo(
		() =>
			debounce(value => {
				if (!isMounted.current) {
					return;
				}

				if (value === "") {
					setKeys(null);
				} else {
					searchIndex.search(value).then(results => {
						setKeys(results);

						// // Keep track of the no results so we can add synonyms in the future.
						// if (value.length >= 4 && results.length === 0) {
						// 	window.ga("send", {
						// 		hitType: "event",
						// 		eventCategory: "material-icons",
						// 		eventAction: "no-results",
						// 		eventLabel: value,
						// 	});
						// }
					});
					setPage(1);
				}
			}, 220),
		[]
	);

	const icons = React.useMemo(
		() =>
			(keys === null
				? allIcons
				: keys.map(key => allIconsMap[key])
			).filter(icon => tag === icon.tag),
		[tag, keys]
	);

	return (
		<Grid container className={classes.root}>
			<FilterList value={tag} onChange={setTag} />

			<Grid item xs={12} sm={9}>
				<Search onChange={handleChange} />

				<Typography className={classes.results}>
					{`${icons.length} matching results`}
				</Typography>

				<Icons
					icons={icons}
					classes={classes}
					handleClickOpen={handleClickOpen}
					page={page}
					setPage={setPage}
				/>
			</Grid>
		</Grid>
	);
}

export default SearchIcons;
