import React from "react";
import List from "./List";
import { Container, Input, Wrapper } from "./style";
import { NotificationManager } from "react-notifications";

function Tags({ field, form, form: { setFieldValue } }) {
	const [inputValue, setInputValue] = React.useState("");

	/**
	 *
	 */
	const handleKeyPress = React.useCallback(
		e => {
			if (e.code === "Enter") {
				e.preventDefault();

				if (!e.target.value) {
					return null;
				}
				if (!validateString(e.target.value)) {
					NotificationManager.error(
						"Should contain 3-32 characters. Letters and ' - allowed",
						"Error"
					);
					return null;
				}
				if (field.value.includes(e.target.value)) {
					NotificationManager.error(
						"Current tag already exists",
						"Error"
					);
					return null;
				}

				const value = [...field.value];

				value.push(e.target.value);
				setFieldValue(field.name, value);
				setInputValue("");
			}
		},
		[field, setFieldValue]
	);
	function validateString(str) {
		var format = /[a-zA-Z'-]/i;
		var format1 = /[^A-Za-z'-]/;
		var format2 = /[`!@#$%^&*()_+=[\]{};:"\\|,.<>/?~]/;

		if (str.length < 3 || str.length > 32) {
			return false;
		}
		if (/\d/.test(str)) {
			return false;
		}
		if (format2.test(str)) {
			return false;
		}
		if (str.match(format1)) {
			return false;
		}
		if (!format.test(str)) {
			return false;
		}
		return true;
	}

	/**
	 *
	 */
	const handleDelete = React.useCallback(
		index => {
			const value = [...field.value];

			value.splice(index, 1);

			setFieldValue(field.name, value);
		},
		[field.name, field.value, setFieldValue]
	);

	return (
		<Wrapper>
			<Container>
				{field.value && !!field.value.length && (
					<List data={field.value} remove={handleDelete} />
				)}

				<Input
					placeholder={"Type tag.."}
					onKeyPress={handleKeyPress}
					value={inputValue}
					onChange={e => setInputValue(e.target.value)}
				/>
			</Container>
		</Wrapper>
	);
}

export default Tags;
