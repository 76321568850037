import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	flex-wrap: nowrap;
	height: 100%;
	// background-color: ${props => props.theme.bg};
	background: linear-gradient(to bottom, rgb(36, 40, 40), rgb(14, 63, 73));
	background-size: cover;
	background-position: center;
	box-shadow: inset -3px 5px 31px rgba(0, 0, 0, 0.5);

	@media screen and (max-width: 1024px) {
		flex-wrap: wrap;
		align-content: flex-start;
	}
`;

export const Side = styled.aside`
	display: flex;
	flex-direction: column;

	flex-basis: 250px;
	flex-shrink: 0;
	flex-grow: 0;
	padding-left: 45px;
	padding-top: 60px;
	padding-bottom: 35px;

	@media screen and (max-width: 1024px) {
		display: none;
	}
`;

export const MainSection = styled.section`
	overflow: hidden;
	width: 100%;
	margin: 70px 30px 30px 30px;

	@media screen and (max-width: 880px) {
		margin: 0px;
	}
`;

export const Main = styled.main`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 28px 44px 36px 36px;
	background-color: white;
	border-radius: 18px;
	height: max-content;
	position: relative;

	@media screen and (max-width: 880px) {
		border-radius: 0px;
		padding: 20px;
	}
`;

export const Logo = styled.img`
	display: block;
	width: 153px;
	height: 43px;
	margin: 0 0 55px;
`;

export const NavigationContainer = styled.div`
	position: relative;
`;
