import { Route } from "react-router-dom";
import EditProduct from "../pages/products/EditProduct";
import CreateProduct from "../pages/products/CreateProduct";
import SelectTypeProduct from "../pages/products/SelectTypeProduct";

function Products() {
	return (
		<>
			<Route exact path="/products/type" component={SelectTypeProduct} />
			<Route exact path="/products/add" component={CreateProduct} />
			<Route exact path="/products/edit/:id" component={EditProduct} />
		</>
	);
}

export default Products;
