import { Dot, LoadingWrapper } from "./Loading.styles";

function Loading() {
	return (
		<LoadingWrapper>
			<h3>Loading</h3>
			<Dot bg={"green"} delay="0s" />
			<Dot bg={"yellow"} delay="0.1s" />
			<Dot bg={"green"} delay="0.2s" />
		</LoadingWrapper>
	);
}

export default Loading;
