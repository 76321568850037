import styled from "styled-components";
import { darken } from "polished";

export const Wrapper = styled.div`
	display: flex;
	justify-content: center;
`;

export const Controller = styled.div`
	display: flex;
	align-items: center;
	padding: 8px;
	cursor: pointer;
	border-radius: 4px;
	background: #f7f7f7;
	color: #000;
	font-size: 15px;
	transition: background-color 0.15s ease-out;
	margin: 0 10px;

	&:hover {
		background-color: ${darken(0.03, "#f7f7f7")};
	}
`;
