import {
	LinkProductSection,
	H4,
	LinkProductOptions,
} from "./styles/ProductForm.styles";
import OptionSelector from "../ProductCategorySelect/ProductCategorySelect";
import { Input } from "../../styles/ui";

function LinkProduct() {
	return (
		<LinkProductSection>
			<H4 mb="12px">Link product to external page</H4>
			<LinkProductOptions>
				<OptionSelector
					name={"productOption"}
					placeholder={"Select option.."}
				/>
				<Input type="url" placeholder="https://example.com" />
			</LinkProductOptions>
		</LinkProductSection>
	);
}

export default LinkProduct;
