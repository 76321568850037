import styled, { createGlobalStyle } from "styled-components";
import FlexBox from "../FlexBox";
import { Dark } from "../../styles/ui";
import { ReactComponent as Remove } from "../../images/icons/close.svg";

export const GlobalStyle = createGlobalStyle`
	html, body, #root {
		min-height: 100vh;
		height: auto;
		margin: 0;
		padding: 0;
	}
`;
export const StyledFlexBox = styled(FlexBox)`
	position: relative;
	flex-direction: column;
	border-radius: 4px;
	width: 100%;
	height: ${props => (props.isImage ? "280px" : "490px")};
	align-items: center;
	justify-content: center;

	background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
	border: 2px dashed #b1b1b1;
	background-repeat: no-repeat;
	z-index: 3;
`;

export const Wrapper = styled.div``;

export const ButtonContainer = styled.div`
	display: flex;
	margin-top: 20px;
	align-items: center;
`;

export const Button = styled(Dark)`
	font-size: 12px;
`;

export const Description = styled.div`
	font-size: 10px;
	color: #333641;
	width: 100%;
	text-align: center;
	color: grey;
`;

export const FileInput = styled.input`
	visibility: hidden;
	position: absolute;
	width: 0;
	height: 0;
	z-index: -1;
`;

export const PreviewContainer = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	border-radius: 6px;
	overflow: hidden;
`;

export const Image = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
`;

export const File = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const Title = styled.h3`
	margin-top: 30px;
	color: #333641;
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 10px;
	text-align: center;
`;
export const Size = styled.div`
	font-size: 12px;
	font-weight: 300;
`;

export const RemoveIcon = styled(Remove)`
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 999;

	border-radius: 50%;
	cursor: pointer;
`;

export const CropButton = styled.button`
	position: absolute;
	bottom: 14px;
	right: 14px;
	padding: 4px 8px;

	display: flex;

	z-index: 999;
	font-size: small;
	font-weight: 700;
	background-color: white;
	border: 1px solid #c4c4c4;
	border-radius: 6px;
	cursor: pointer;

	box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
	-moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
	-webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
	-o-box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);

	:active {
		transform: scaleX(1.1);
		background-color: beige;

		box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
		-moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
		-webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
		-o-box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
	}
`;
