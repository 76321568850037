import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import WithSidebar from "../../layout/WithSidebar";
import PageHeader from "../../components/PageHeader";
import PageContent from "../../components/PageContent";
import {
	NotificationManager,
	NotificationManager as Alert,
} from "react-notifications";
import { displayError } from "../../utils/errors";
import { useQuery } from "react-query";
import { getCategory, updateCategory } from "../../api/categories";
import Loader from "../../components/Loader";
import CategoryForm from "../../components/CategoryForm";
import regex from "../../config/regex";
import PageContentOverflow from "../../components/PageContentOverflow";
import Helmet from "react-helmet";

/**
 *
 */
const EditCategorySchema = Yup.object().shape({
	title: Yup.string()
		.matches(regex.title.value, regex.title.message)
		.required(regex.title.message),
	image: Yup.mixed()
		.test("image", "Invalid image type or size", value => {
			if (!value) {
				return false;
			}

			// default value is object {id, size, uri}
			if (!(value instanceof File)) {
				return true;
			}

			if (value.size > 30720 * 1000) {
				return false;
			}

			return (
				value.type === "image/jpeg" ||
				value.type === "image/png" ||
				value.type === "image/svg+xml"
			);
		})
		.required("Required"),
});

/**
 *
 * @constructor
 */
function CreateCategory() {
	const params = useParams();
	const history = useHistory();

	const {
		data: category,
		isLoading,
		error,
	} = useQuery(["category", params.id], () => getCategory(params.id), {
		cacheTime: 1,
		select: data => data.data,
	});

	/**
	 * Form submit handler
	 */
	const handleSubmit = React.useCallback(
		(values, { setSubmitting }) => {
			if (!category) {
				return null;
			}

			const formData = new FormData();

			for (let key in values) {
				//dont include objects in form data
				if (
					typeof values[key] === "object" &&
					!(values[key] instanceof File) &&
					!Array.isArray(values[key])
				)
					continue;

				const value = Array.isArray(values[key])
					? JSON.stringify(values[key])
					: values[key];

				formData.append(key, value);
			}

			updateCategory(category.id, formData)
				// Successfully updated
				.then(() => {
					history.push("/categories");

					return Alert.success(
						`Category '${category.title}' saved!`,
						"Success"
					);
				})
				// Handle error
				.catch(displayError)
				// Finish form submitting
				.finally(() => setSubmitting(false));
		},
		[category, history]
	);

	if (isLoading) {
		return <Loader />;
	}

	if (error) {
		NotificationManager.error(error.message, "Error");

		history.push("/categories");
	}

	return (
		<WithSidebar>
			<Helmet>
				<title>Augmento.com - Edit category</title>
			</Helmet>
			<PageHeader title={`Edit '${category.title}' category`} />

			<PageContentOverflow>
				<Formik
					component={CategoryForm}
					initialValues={{
						title: category.title,
						image: category.image,
						isPopular: category.isPopular,
					}}
					validationSchema={EditCategorySchema}
					onSubmit={handleSubmit}
				/>
			</PageContentOverflow>
		</WithSidebar>
	);
}

export default CreateCategory;
