import { Redirect, Route } from "react-router-dom";

function GuestRoute({ isAuthorized, component: Component, ...rest }) {
	return (
		<Route
			{...rest}
			render={props =>
				isAuthorized ? (
					<Redirect to={"/products"} />
				) : (
					<Component {...props} />
				)
			}
		/>
	);
}

export default GuestRoute;
