import { Image } from "../ProductType/ProductType.style";
import { HeaderTextPosition, ImageLabel } from "./Products.style";

function CreateProductHeader({ image, title }) {
	return (
		<HeaderTextPosition>
			<Image height="30px" src={image} />
			<ImageLabel>{title}</ImageLabel>
		</HeaderTextPosition>
	);
}

export default CreateProductHeader;
