import { Wrapper } from "./styles";
import Plan from "./Plan";
import plans from "./mock.json";
import { useQuery } from "react-query";
import { getPlans } from "../../api/subscriptions";
import Loader from "../Loader";
import useActiveSubscription from "../../hooks/useActiveSubscription";

// const MONTHLY_PLANS = "MONTHLY_PLANS";
// const YEARLY_PLANS = "YEARLY_PLANS";

function PlansList({ type, selectPlan }) {
	const { data, isLoading, error } = useQuery("plans", getPlans, {
		refetchOnWindowFocus: false,
		select: data =>
			// data?.data?.sort((a, b) => (a.amount > b.amount ? 1 : -1)),
			data?.data,
	});
	const { data: allSubscription } = useActiveSubscription();

	if (isLoading) {
		return <Loader />;
	}

	return (
		<Wrapper>
			{data.map((plan, index) => (
				<Plan
					data={plan}
					subscribedTo={allSubscription?.subscription?.items[0]}
					key={plan.productId}
					isPopular={index === 0}
					type={type}
					onClick={selectPlan}
				/>
			))}
		</Wrapper>
	);
}

export default PlansList;
