import api from "./index";

export const verifyAuthToken = token => {
	return api.get("/v1/auth/validate-token?token=" + token, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const signIn = data => api.post("/v1/auth/login", data);

export const signUp = data => api.post("/v1/auth/register", data);

export const resetPassword = data => api.post("/v1/auth/forgot-password", data);

export const setPassword = data => api.post("/v1/auth/reset-password", data);

export const googleAuth = data => api.post("/v1/auth/google-auth", data);
