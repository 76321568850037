import { Redirect, Route } from "react-router-dom";

function PrivateRoute({ isAuthorized, component: Component, ...rest }) {
	return (
		<Route
			{...rest}
			render={props =>
				isAuthorized ? <Component {...props} /> : <Redirect to={"/"} />
			}
		/>
	);
}

export default PrivateRoute;
