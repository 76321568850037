import { Route } from "react-router-dom";
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import SetPassword from "../pages/auth/SetPassword";
import ConfirmEmail from "../pages/auth/ConfirmEmail";

function Auth() {
	return (
		<>
			<Route exact path="/auth/signin" component={SignIn} />
			<Route exact path="/auth/signup" component={SignUp} />
			<Route
				exact
				path="/auth/confirm-email/:token"
				component={ConfirmEmail}
			/>
			<Route
				exact
				path="/auth/reset-password"
				component={ResetPassword}
			/>
			<Route
				exact
				path="/auth/restore-password/:token"
				component={SetPassword}
			/>
		</>
	);
}

export default Auth;
