function convertToArrayOfStrings(arr, key) {
	if (!key) throw new Error("Value key is not provided!");
	if (!arr || !Array.isArray(arr))
		throw new Error("Array is required or value is not of type array");

	return arr.map(item => {
		return item[key];
	});
}

export default convertToArrayOfStrings;
