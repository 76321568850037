import { Logo, Main, Wrapper } from "./styles";
import bg from "./bg.png";
import logo from "../../images/logo2.svg";
import Footer from "../../components/Footer";

function AuthLayout({ children }) {
	return (
		<Wrapper background={bg}>
			<Main>
				<Logo src={logo} alt={"Augmento"} />

				{children}
			</Main>

			<Footer />
		</Wrapper>
	);
}

export default AuthLayout;
