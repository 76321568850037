import styled from "styled-components";
import { darken } from "polished";
import { PrimaryButton } from "../../styles/ui";

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
`;

export const Table = styled.div`
	display: grid;
	grid-template-columns: max(270px) 1fr 150px min-content 70px 70px;
	grid-auto-rows: 40px;
	row-gap: 9px;
	overflow: auto;
`;

export const Cell = styled.div`
	display: flex;
	align-items: center;
	justify-content: ${({ center }) => (center ? "center" : "flex-start")};
	background-color: #f7f7f7;

	&:nth-child(6n) {
		border-radius: 0 61px 61px 0;
	}
`;

export const Name = styled(Cell)`
	font-size: 18px;
	color: #000;
	padding-left: 25px;
	padding-right: 15px;
	border-radius: 61px 0 0 61px;
`;

export const Category = styled(Cell)`
	font-size: 18px;
	color: #424e61;
`;
export const Company = styled(Cell)`
	font-size: 18px;
	color: #424e61;
`;

export const Edit = styled.button`
	height: 20px;
	background-color: #ffffff;
	border: 1px solid #c7c7c7;
	border-radius: 21px;
	padding: 0 27px;
	font-size: 14px;
	cursor: pointer;
	transition: background-color 0.15s ease;
	margin-right: 35px;
	outline: none;
	box-shadow: none;

	&:hover {
		background-color: ${darken(0.08, "#fff")};
	}
`;

export const Toggle = styled.div`
	position: relative;
	width: 37px;
	height: 20px;
	padding: 2px;
	background-color: #fff;
	box-shadow: ${props =>
		props.active
			? "0 15px 30px rgba(20, 102, 204, 0.16)"
			: "0px 15px 30px rgba(20, 102, 204, 0.16), inset 0px 4px 10px rgba(0, 0, 0, 0.15)"};
	border-radius: 17px;
	border: ${props =>
		props.active ? "1px solid transparent" : "1px solid #C2C2C2"};
	cursor: pointer;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 17px;
		background-image: linear-gradient(270deg, #43bbff 0%, #3676f1 100%);
		z-index: 1;
		opacity: ${props => (props.active ? 1 : 0)};
		transition: opacity 0.1s linear;
	}

	&::before {
		content: "";
		position: absolute;
		top: 50%;
		left: 2px;
		z-index: 2;
		transform: translate3d(
			${props => (props.active ? "18px" : 0)},
			-50%,
			0
		);
		height: 14px;
		width: 14px;
		border-radius: 50%;
		background: ${props =>
			props.active
				? "#fff"
				: "linear-gradient(270deg, #43BBFF 0%, #3676F1 100%)"};
		transition: transform 0.125s ease;
	}
`;

export const ColumnTitle = styled.div`
	font-size: 18px;
	font-weight: 700;
	padding-left: ${props => props.pl || 0}px;
`;

export const SearchWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	align-self: flex-end;
	margin-bottom: 20px;
	background-color: rgba(0, 0, 0, 0.03);
	border-radius: 6px;
`;

export const SearchIcon = styled.img`
	position: absolute;
	top: 50%;
	left: 14px;
	transform: translateY(-50%);
	z-index: 1;
	display: block;
	width: 16px;
	height: 16px;
	object-fit: contain;
	object-position: center;
`;

export const SearchInput = styled.input`
	height: 40px;
	// max-width: 280px;
	width: 100%;
	padding-left: 40px;
	padding-right: 20px;
	border: none;
	outline: none;
	box-shadow: none;
	// font-size: 16px;
	background-color: transparent;

	// &::placeholder {
	// 	color: rgba(51, 54, 65, 0.4);
	// }
`;

export const NotFound = styled.div`
	display: flex;
	justify-content: center;
	font-size: 16px;
`;

export const Preloader = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

export const Button = styled(PrimaryButton)`
	align-self: flex-end;
	margin-top: 40px;
	flex-shrink: 0;
`;

export const RowWrapper = styled.div`
	display: contents;
`;

export const Delete = styled.div`
	padding: 5px 15px;
	cursor: pointer;
`;
export const Cancel = styled.div`
	color: #424e61;
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	line-height: 21px;
	text-align: center;
	cursor: pointer;
`;
export const RightAlign = styled.div`
	display: flex;
	justify-content: flex-end;
`;
export const CenterAlign = styled.div`
	display: flex;
	justify-content: center;
`;
