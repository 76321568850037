import {
	Wrapper,
	Price,
	Title,
	Description,
	ListItem,
	List,
	Button,
	Container,
	LabelWrapper,
	Label,
	Green,
} from "./Plan.styles";
import { ReactComponent as Allowed } from "../../images/icons/check.svg";
import { ReactComponent as Disallowed } from "../../images/icons/circle.svg";
import { subscribe } from "../../api/subscriptions";
import { NotificationManager as Alert } from "react-notifications";
import React from "react";
import Loader from "../Loader";
import { isInteger, getDecimalPart } from "../../utils/numbers";

function Plan({ isPopular, subscribedTo, data, onClick, type }) {
	const [loading, setLoading] = React.useState(false);
	const { metadata } = data;
	const getPlanPrice = (method, priceData) => {
		if (method === "monthly") {
			return priceData.monthlyPrice.price / 100;
		}
		if (method === "yearly") {
			return priceData.yearlyPrice.price / 100;
		}
		return 0;
	};
	const getPriceId = (method, priceData) => {
		if (method === "monthly") {
			return priceData.monthlyPrice.priceId;
		}
		if (method === "yearly") {
			return priceData.yearlyPrice.priceId;
		}
	};
	const getInterval = (method, priceData) => {
		if (method === "monthly") {
			return priceData.monthlyPrice.interval;
		}
		if (method === "yearly") {
			return priceData.yearlyPrice.interval;
		}
	};

	const isSubscribedToPlan = () => {
		return subscribedTo?.plan?.interval === "month" && type === "monthly"
			? subscribedTo?.plan?.id === data?.monthlyPrice?.priceId
			: subscribedTo?.plan?.id === data?.yearlyPrice?.priceId;
	};

	const isHigherThanSubscription = () => {
		if (subscribedTo?.plan.interval === "month" && type === "monthly") {
			return subscribedTo?.plan.amount < data.monthlyPrice.price;
		}
		return subscribedTo?.plan.amount < data.yearlyPrice.price;
	};
	const setupSubscription = async data => {
		setLoading(true);
		const productId = data.productId;
		const priceId = getPriceId(type, data);
		if (productId && priceId) {
			subscribe(productId, priceId)
				.then(() => {
					Alert.success("Successfully subscribed!", "Success");
					window.location.reload();
					setLoading(false);
				})
				.catch(e => {
					console.log(e.message);
					Alert.error("No payment methods found", "Error");
				})
				.finally(() => setLoading(false));
		}
	};

	const displaySubscriptionAction = () => {
		return !!subscribedTo
			? isHigherThanSubscription()
				? "Upgrade"
				: "Downgrade"
			: "Choose Package";
	};

	return (
		<Wrapper wider={isPopular}>
			<Container highlighted={isPopular}>
				{isPopular && (
					<LabelWrapper>
						<Label>{"Most popular"}</Label>
					</LabelWrapper>
				)}
				{loading && <Loader />}
				<Price light={isPopular}>
					{"€"}
					{getPlanPrice(type, data)}
					{isInteger(getPlanPrice(type, data)) ? (
						" "
					) : (
						<span>
							,
							<sup>
								{getDecimalPart(getPlanPrice(type, data))}{" "}
							</sup>
						</span>
					)}
					<small>
						{getPlanPrice(type, data) === 0
							? "Forever"
							: `/${getInterval(type, data)}`}
					</small>
				</Price>

				<Title light={isPopular}>{data.productName}</Title>

				<Description light={isPopular}>
					{"For most businesses that want to otpimize web queries"}
				</Description>

				<List light={isPopular}>
					{/*<ListItem>*/}
					{/*	<Allowed />*/}
					{/*	{data.downloadsAmount}*/}
					{/*	{" downloads"}*/}
					{/*</ListItem>*/}
					<ListItem>
						<Allowed />
						{`Up to `}
						&nbsp;
						<b>{`${metadata.max_products || 0} products`}</b>
					</ListItem>
					<ListItem>
						<Allowed />
						{"Easy to use web interface"}
					</ListItem>
					<ListItem>
						{metadata.categories_allowed ? (
							<Allowed />
						) : (
							<Disallowed />
						)}
						{"Create categories"}
					</ListItem>
					<ListItem>
						{metadata.variations_allowed ? (
							<Allowed />
						) : (
							<Disallowed />
						)}
						{"Product variants"}
					</ListItem>
				</List>

				{isSubscribedToPlan() ? (
					<Button light={isPopular} subscribed={isSubscribedToPlan()}>
						<Green />
						{"Active package"}
					</Button>
				) : (
					<Button
						light={isPopular}
						isHigher={isHigherThanSubscription()}
						isSubscribed={!!subscribedTo}
						subscribed={isSubscribedToPlan()}
						onClick={() => setupSubscription(data)}
					>
						{displaySubscriptionAction()}
					</Button>
				)}
			</Container>
		</Wrapper>
	);
}

export default Plan;
