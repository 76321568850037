import { ReactComponent as BlackFrame } from "../../images/frames/black01.svg";
import { ReactComponent as BlackWoodFrame } from "../../images/frames/black02.svg";
import { ReactComponent as GoldFrame } from "../../images/frames/gold1.svg";
import { ReactComponent as DarkOakFirstFrame } from "../../images/frames/dark-oak1.svg";
import { ReactComponent as DarkOakSecondFrame } from "../../images/frames/dark-oak2.svg";
import { ReactComponent as OakSecondFrame } from "../../images/frames/oak2.svg";
import { ReactComponent as NoFrame } from "../../images/frames/noframe.svg";

import oak from "../../images/oak.jpg";
import oakRough from "../../images/oakRoughness.jpg";
import blackWood from "../../images/blackWood.jpg";
import darkWood from "../../images/darkWood.jpg";
import darkWoodRough from "../../images/darkWoodRoughness.jpg";
import blackWoodRoughness from "../../images/blackWoodRoughness.jpg";

export const frameOptions = [
	{ label: "No frame", value: null, image: NoFrame },
	{ label: "Black 01", value: "black", image: BlackFrame },
	{ label: "Black 02", value: "blackWood", image: BlackWoodFrame },
	{ label: "Gold 1", value: "gold", image: GoldFrame },
	{ label: "Dark Oak 1", value: "darkWood", image: DarkOakFirstFrame },
	{
		label: "Dark Oak 2",
		value: "darkWoodRoughness",
		image: DarkOakSecondFrame,
	},
	{ label: "Oak 2", value: "oak", image: OakSecondFrame },
	{ label: "White", value: "white", image: OakSecondFrame },
];

export const frameConfig = [
	{
		key: 1,
		position: [0, 0, 0],
		bottomText: "Black",
		color: "#000",
		metalness: 1,
		rough: 0.5,
		lightColor: "white",
		rotation: [0.4, 0.4, 0],
		width: 1,
		height: 1,
		frameWidth: 1,
		frameHeight: 1,
		frameDepth: 2,
	},
	{
		key: 2,
		position: [0, 0, 0],
		bottomText: "White",
		color: "#fff",
		metalness: 1,
		rough: 0.5,
		lightColor: "white",
		rotation: [0.4, 0.4, 0],
		width: 1,
		height: 1,
		frameWidth: 1,
		frameHeight: 1,
		frameDepth: 2,
	},
	{
		key: 3,
		position: [0, 0, 0],
		bottomText: "Black wood",
		texture: blackWood,
		rough: blackWoodRoughness,
		lightColor: "white",
		rotation: [0.4, 0.4, 0],
		width: 1,
		height: 1,
		frameWidth: 1,
		frameHeight: 1,
		frameDepth: 2,
	},
	{
		key: 4,
		position: [0, 0, 0],
		bottomText: "Oak",
		texture: oak,
		rough: oakRough,
		lightColor: "white",
		rotation: [0.4, 0.4, 0],
		width: 1,
		height: 1,
		frameWidth: 1,
		frameHeight: 1,
		frameDepth: 2,
	},
	{
		key: 5,
		position: [0, 0, 0],
		bottomText: "Dark wood",
		texture: darkWood,
		rough: darkWoodRough,
		lightColor: "white",
		rotation: [0.4, 0.4, 0],
		width: 1,
		height: 1,
		frameWidth: 1,
		frameHeight: 1,
		frameDepth: 2,
	},
	{
		key: 6,
		position: [0, 0, 0],
		bottomText: "Gold",
		color: "#B18E12",
		metalness: 1,
		rough: 0.2,
		lightColor: "white",
		rotation: [0.4, 0.4, 0],
		width: 1,
		height: 1,
		frameWidth: 1,
		frameHeight: 1,
		frameDepth: 2,
	},
];
