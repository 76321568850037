import React from "react";
import WithSidebar from "../../layout/WithSidebar";
import PageHeader from "../../components/PageHeader";
import { Wrapper } from "../../layout/ProductEdit/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { getAuthDataFromLocalStorage } from "../../utils/authToken";
import { NotificationManager as Alert } from "react-notifications";
import { displayError } from "../../utils/errors";
import { useHistory, useLocation } from "react-router-dom";
import regex from "../../config/regex";
import PageContentOverflow from "../../components/PageContentOverflow";
import CollectionForm from "../../components/CollectionForm";
import { createCollection } from "../../api/collections";
import Helmet from "react-helmet";

/**
 *
 */
const CreateCollectioinSchema = Yup.object().shape({
	title: Yup.string()
		.matches(regex.title.value, regex.title.message)
		.required(regex.title.message),
	categories: Yup.array().required("Required"),
});

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function CreateCollection() {
	const history = useHistory();
	const { state } = useLocation();
	const [imageSrc, setImageSrc] = React.useState({});
	/**
	 * Form submit handler
	 */
	const handleSubmit = React.useCallback(
		(values, { setSubmitting }) => {
			const { userId } = getAuthDataFromLocalStorage();
			const formData = new FormData();
			if (Array.isArray(values.categories)) {
				values.categories.join(", ");
			}
			for (let key in values) {
				if (
					typeof values[key] === "object" &&
					!(values[key] instanceof File) &&
					!Array.isArray(values[key])
				)
					continue;

				if (key === "link" && !values[key]) {
					continue;
				}
				if (Array.isArray(values[key])) {
					values[key].forEach(item => {
						formData.append(key + "[]", item);
					});
				} else {
					formData.append(key, values[key]);
				}
			}

			createCollection(formData)
				// Successfully updated
				.then(() => {
					history.push("/collections");
					return Alert.success(
						`Collection '${values.title}' has been created!`,
						"Success"
					);
				})
				// Handle error
				.catch(displayError)
				// Finish form submitting
				.finally(() => setSubmitting(false));
		},
		[history]
	);

	return (
		<WithSidebar>
			<Helmet>
				<title>Augmento.com - Create collection</title>
			</Helmet>
			<PageHeader title={"Collection"} />
			<PageContentOverflow>
				<Wrapper>
					<Formik
						component={CollectionForm}
						initialValues={{
							title: "",
							categories: "",
						}}
						validationSchema={CreateCollectioinSchema}
						onSubmit={handleSubmit}
					/>
				</Wrapper>
			</PageContentOverflow>
		</WithSidebar>
	);
}

export default CreateCollection;
