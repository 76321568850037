import { H4 } from "../styles/ProductForm.styles";
import {
	LongestSideWrapper,
	LongestSideSection,
	TextPosition,
	InputSection,
	UnitOfMeasure,
} from "../styles/SizeSelector.style";
import { Tooltip } from "../styles/ProductForm.styles";
import { ReactComponent as DoubleArrow } from "../../../images/icons/double-arrow.svg";
import { Input } from "../../../styles/ui";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

function SizeSelector({ longestSideHandl, longestSide }) {
	return (
		<LongestSideSection>
			<H4>
				<LongestSideWrapper>
					<DoubleArrow />
					<TextPosition>Longest side</TextPosition>
					<QuestionMarkCircleIcon width={12} height={12} />
					<Tooltip className="tooltip">
						Longest side more information
					</Tooltip>
				</LongestSideWrapper>
			</H4>

			<InputSection>
				<Input
					autoComplete
					value={longestSide}
					placeholder="0"
					type="number"
					width={130}
					onChange={e => longestSideHandl(e.target.value)}
				/>
				<UnitOfMeasure>cm</UnitOfMeasure>
			</InputSection>
		</LongestSideSection>
	);
}

export default SizeSelector;
