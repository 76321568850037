const regex = {
	title: {
		value: /^[a-zA-Z+&#|?><~\\'().,\/\-\s\d]{3,32}$/gi,
		message:
			"Should contain 3-32 characters. Letters, digits and special characters: + & # / \\ | ? > < ~ ' - ( ) . , also space allowed",
	},
	city: {
		value: /^[a-zA-Z'\-\s]{3,32}$/gi,
		message:
			"Should contain 3-32 characters. Letters and special characters: ' - also space allowed",
	},
	vat: {
		value: /^[a-zA-Z\d]{9,15}$/gi,
		message: "Should contain 9-15 characters. Letters and digits allowed",
	},
	websiteLink: {
		value: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gi,
		message: "Should contain 4-50 characters",
	},
	companyDescription: {
		value: /^[a-zA-Z+&#|?><~\\'().,\/\-?\s\d]{10,1000}$/gi,
		message:
			"Should contain 10-1000 characters. Letters, digits and special characters: + & # / \\ | ? > < ~ ' - ( ) . , also space allowed",
	},
	password: {
		value: /^.{8,16}$/gi,
		message: "Should contain 8-16 characters",
	},
};

export default regex;
