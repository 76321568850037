import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 40px;
	max-width: 330px;
	background: rgba(0, 0, 0, 0.05);
	border-radius: 6px;
	padding: 0 15px;
	margin: 0 0 8px;
`;
