import React from "react";
import { ErrorMessage, Field, Form } from "formik";
import {
	Left,
	Right,
	Screen,
	Tip,
	Wrapper,
} from "../../layout/CategoryEdit/styles";
import { Input, PrimaryButton } from "../../styles/ui";
import { FileInput, RadioGroup, UploaderWrapper } from "./styles";
import Radio from "../Radio";
import screen from "../../images/screen.png";
import { Edit } from "../ProductsList/styles";
import { AlignRight, Error, InputWrapper } from "../../pages/auth/styles";
import Preview from "./Preview";
import SearchIcons from "../SearchIcons";

/**
 *
 * @param values
 * @param setFieldValue
 * @param isSubmitting
 * @returns {JSX.Element}
 * @constructor
 */
function CategoryForm({ values, setFieldValue, isSubmitting }) {
	const input = React.createRef();

	return (
		<Form>
			<Wrapper>
				<Left>
					<InputWrapper mb={33}>
						<Field
							name="title"
							as={Input}
							placeholder={"Category name"}
							mb={3}
						/>
						<AlignRight>
							<ErrorMessage name={"title"} component={Error} />
						</AlignRight>
					</InputWrapper>

					<RadioGroup>
						<Radio
							name={"externalLink"}
							title={"Use image"}
							value={true}
							state={values.isPopular}
							handleChange={value => {
								setFieldValue("isPopular", value);
								setFieldValue("image", null);
							}}
						/>
						<Radio
							name={"externalLink"}
							title={"Use icon"}
							value={false}
							state={values.isPopular}
							handleChange={value => {
								setFieldValue("isPopular", value);
								setFieldValue("image", null);
							}}
						/>
					</RadioGroup>

					{values.image && <Preview image={values.image} />}

					{!values.isPopular && (
						<SearchIcons
							onChange={value => setFieldValue("image", value)}
						/>
					)}

					<UploaderWrapper>
						<FileInput
							type="file"
							ref={input}
							onChange={e => {
								setFieldValue("image", e.target.files[0]);
							}}
							accept={
								values.isPopular
									? "image/jpeg, image/png"
									: "image/svg+xml"
							}
						/>

						<Edit
							type="button"
							onClick={() => input.current.click()}
						>
							{values.image
								? "replace"
								: values.isPopular
								? "upload"
								: "Or upload custom icon"}
						</Edit>
						<AlignRight>
							<ErrorMessage name={"image"} component={Error} />
						</AlignRight>
					</UploaderWrapper>

					<PrimaryButton type={"submit"} disabled={isSubmitting}>
						{"Save category"}
					</PrimaryButton>
				</Left>

				<Right>
					<Screen src={screen} />

					<Tip>
						{"Tip: Use an image to make a category stand out"}
					</Tip>
				</Right>
			</Wrapper>
		</Form>
	);
}

export default CategoryForm;
