import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 75px;
	border: 1px dashed rgba(51, 54, 65, 0.1);
	background: rgba(216, 216, 216, 0.1);
	border-radius: 8px;
	width: 450px;
`;

export const Browse = styled.button`
	outline: none;
	box-shadow: none;
	border: none;
	padding: 3px 15px;
	background-color: #dadada;
	border-radius: 3px;
	margin-left: ${props => props.ml || 0}px;
`;

export const Label = styled.div`
	display: flex;
	align-items: center;
`;

export const Text = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 150px;
`;
