import React from "react";
import { ReactComponent as Power } from "../../images/icons/power.svg";
import { Icon, Text, Wrapper } from "./styles";
import AuthContext from "../../contexts/auth";
import { useHistory } from "react-router-dom";
import { logoutHandler } from "../../utils/auth";

function Logout() {
	const auth = React.useContext(AuthContext);
	const history = useHistory();

	return (
		<Wrapper onClick={() => logoutHandler(auth.setIsAuthorized, history)}>
			<Icon>
				<Power />
			</Icon>

			<Text>Logout</Text>
		</Wrapper>
	);
}

export default Logout;
