import { Route } from "react-router-dom";
import CreateCollection from "../pages/collections/CreateCollection";
import EditCollection from "../pages/collections/EditCollection";

function Collections() {
	return (
		<>
			{/* <Route exact path="/products/type" component={SelectTypeProduct} /> */}
			<Route exact path="/collections/add" component={CreateCollection} />
			<Route
				exact
				path="/collections/edit/:id"
				component={EditCollection}
			/>
		</>
	);
}

export default Collections;
