import React from "react";
import AuthLayout from "../../layout/Auth";
import {
	AlignCenter,
	AlignRight,
	BottomLink,
	Content,
	ContentTitle,
	Error,
	ForgetLink,
	Input,
	InputWrapper,
	SubmitButton,
} from "./styles";
import { useHistory } from "react-router-dom";
import { Formik, Field, ErrorMessage, Form } from "formik";
import Helmet from "react-helmet";
import { NotificationManager as Alert } from "react-notifications";
import * as Yup from "yup";
import AuthContext from "../../contexts/auth";
import { googleAuth, signIn } from "../../api/auth";
import { saveAuthDataToLocalStorage } from "../../utils/authToken";
import { displayError } from "../../utils/errors";
import regex from "../../config/regex";
import GoogleAuthButton from "./AuthButton";

/**
 *
 */
const SignInSchema = Yup.object().shape({
	email: Yup.string().email("Invalid email").required("Required"),
	password: Yup.string()
		.matches(regex.password.value, "Password not valid")
		.required("Password not valid"),
});

/**
 *
 * @constructor
 */
function SignIn() {
	const history = useHistory();
	const auth = React.useContext(AuthContext);

	/**
	 * Form submit handler
	 */
	const handleSubmit = React.useCallback(
		(values, { setSubmitting }) =>
			signIn(values)
				// Successfully signed in
				.then(res => {
					saveAuthDataToLocalStorage(res.data);
					if (res.data.user.isSuperAdmin) {
						auth.setIsSuperAdmin(true);
					} else {
						auth.setIsSuperAdmin(false);
					}
					auth.setIsAuthorized(true);
					history.push("/");
					window.location.reload();
				})
				// Handle error
				.catch(displayError)
				// Finish form submitting
				.finally(() => setSubmitting(false)),
		[auth, history]
	);

	const handleSocialLogin = res => {
		Alert.success("Successfully Authenticated!", "success");
		googleAuth({ token: res.access_token })
			.then(res => {
				saveAuthDataToLocalStorage(res.data);
				if (res.data.user.isSuperAdmin) {
					auth.setIsSuperAdmin(true);
				} else {
					auth.setIsSuperAdmin(false);
				}
				auth.setIsAuthorized(true);
				history.push("/");
				window.location.reload();
			})
			.catch(() => Alert.error("Something went wrong!", "Error"));
	};

	const handleError = () => {
		Alert.error("Something went wrong!", "Error");
	};

	return (
		<AuthLayout>
			<Helmet>
				<title>Augmento.com - Signin</title>
			</Helmet>
			<Content>
				<ContentTitle>{"Please login to continue"}</ContentTitle>

				<Formik
					initialValues={{ email: "", password: "" }}
					validationSchema={SignInSchema}
					onSubmit={handleSubmit}
				>
					<Form>
						<InputWrapper>
							<Field
								as={Input}
								name="email"
								type="email"
								placeholder="Email"
								mb={3}
							/>
							<AlignRight>
								<ErrorMessage name="email" component={Error} />
							</AlignRight>
						</InputWrapper>

						<InputWrapper mb={8}>
							<Field
								as={Input}
								name="password"
								type="password"
								placeholder="Password"
							/>
							<AlignRight>
								<ErrorMessage
									name="password"
									component={Error}
								/>
							</AlignRight>
						</InputWrapper>

						<AlignRight>
							<ForgetLink to={"/auth/reset-password"}>
								{"Forgot password?"}
							</ForgetLink>
						</AlignRight>

						<SubmitButton type="submit">Login</SubmitButton>
						<GoogleAuthButton
							label="Login with Google"
							onSuccess={handleSocialLogin}
							onError={handleError}
						/>
					</Form>
				</Formik>

				<AlignCenter>
					<BottomLink to={"/auth/signup"}>
						{"or CREATE AN ACCOUNT"}
					</BottomLink>
				</AlignCenter>
			</Content>
		</AuthLayout>
	);
}

export default SignIn;
