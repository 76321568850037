import { H4, Tooltip } from "../styles/ProductForm.styles";

import {
	FrameSection,
	InfoWrapper,
	FrameInfoSection,
	FrameInfo,
} from "../styles/FrameItem.style";
import {
	EllipsisHorizontalIcon,
	QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { ReactComponent as DoubleArrow } from "../../../images/icons/double-arrow.svg";
import { frameOptions } from "../constants";
import FrameSlider from "../FrameSlides";

function SelectFrame({ setFieldValue, frame }) {
	return (
		<FrameSection>
			<FrameInfoSection>
				<H4>Select frame</H4>
				<InfoWrapper>
					<FrameInfo>
						<EllipsisHorizontalIcon width={24} height={24} />
						<h5 style={{ margin: "0px" }}>Flexible frame</h5>
						<QuestionMarkCircleIcon width={12} height={12} />
						<Tooltip className="tooltip">
							Flex frame more information
						</Tooltip>
					</FrameInfo>
					<FrameInfo>
						<DoubleArrow width={14} height={14} />
						<h5 style={{ margin: "0px" }}>Fixed size</h5>
						<QuestionMarkCircleIcon width={12} height={12} />
						<Tooltip className="tooltip">
							Fixed size more information
						</Tooltip>
					</FrameInfo>
				</InfoWrapper>
			</FrameInfoSection>

			<FrameSlider
				frameOptions={frameOptions}
				setFieldValue={setFieldValue}
				frame={frame}
			/>
		</FrameSection>
	);
}

export default SelectFrame;
