import React from "react";
import {
	Button,
	ColumnTitle,
	NotFound,
	Preloader,
	Table,
	Wrapper,
} from "./styles";
import Row from "./Row";
import Search from "./Search";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import { ReactComponent as Loader } from "../../images/icons/loader.svg";
import List from "./List";
import Pagination from "../Pagination";
import AuthContext from "../../contexts/auth";
import CustomPagination from "../CustomPagination";
import Helmet from "react-helmet";
import { Steps } from "intro.js-react";
import useOnboarding from "../../hooks/useOnboarding";
import {
	handleOnchange,
	handleOnExit,
	handleReOrdering,
} from "../../utils/products";
import { DragDropContext } from "react-beautiful-dnd";
import { useState } from "react";
import { updateCategoriesOrder } from "../../api/categories";
import { getAuthDataFromLocalStorage } from "../../utils/authToken";
import { TopMenu } from "./styles";

/**
 *
 * @constructor
 */
function CategoriesList({ data, isLoading, error, refetch }) {
	const auth = React.useContext(AuthContext);
	const history = useHistory();
	const location = useLocation();
	const search = qs.parse(location.search);
	const { companyId } = getAuthDataFromLocalStorage();
	const [isUpdating, setIsUpdating] = useState(false);
	const page = +search.page || 1;
	const { onboarding } = useOnboarding([
		{
			element: ".create-categories",
			title: "Create Categories",
			intro: "Create categories enriched with images or an icon, create as many as you like. The categories with images will show up on top",
		},
		{
			intro: "",
		},
	]);

	/**
	 *
	 */
	const handleSearchChange = React.useCallback(
		e => {
			history.push({
				search: qs.stringify({
					...search,
					search: e.target.value || undefined,
					page: undefined,
				}),
			});
		},
		[history, search]
	);

	/**
	 * Go to next page
	 */
	const handleNext = () => {
		history.push({
			search: qs.stringify({ ...search, page: page + 1 }),
		});
	};

	/**
	 * Go to prev page
	 */
	const handlePrev = () => {
		history.push({
			search: qs.stringify({ ...search, page: page - 1 }),
		});
	};
	/**
	 * Go to page
	 */
	const handlePage = page => {
		history.push({
			search: qs.stringify({ ...search, page: page }),
		});
	};
	/**
	 *
	 * @type {(function(): void)|*}
	 */
	const handleAddCategory = React.useCallback(() => {
		if (auth.isSuperAdmin) {
			history.push("/categories/public/add");
		} else {
			history.push("/categories/add");
		}
	}, [history]);

	if (error)
		return <div>{`An error has occurred: " + ${error.message}`}</div>;

	const handleReArrange = async result => {
		const { source, destination } = result;
		const { dragOverItem, draggableItem } = handleReOrdering(
			source,
			destination,
			data
		);

		const isReplacingLastItem = destination.index === data.length - 1;
		setIsUpdating(true);
		await updateCategoriesOrder(companyId, [
			draggableItem.id,
			dragOverItem.id,
			isReplacingLastItem,
		]);
		refetch();
		setIsUpdating(false);
	};

	const { stepsEnabled, steps, initialStep } = onboarding;
	const nextOnboardingStep = "/products/type?onboarding=true";
	return (
		<>
			<Steps
				enabled={stepsEnabled}
				steps={steps}
				initialStep={initialStep}
				onExit={handleOnExit}
				onChange={step => handleOnchange(step, nextOnboardingStep)}
			/>

			<DragDropContext onDragEnd={handleReArrange}>
				<Wrapper>
					<Helmet>
						<title>Augmento.com - Categories</title>
					</Helmet>
					<TopMenu>
						
						<Search
							placeholder={"Search categories.."}
							onChange={handleSearchChange}
							value={search.search}
						/>
					</TopMenu>

					<List data={data} isLoading={isLoading || isUpdating} />
					<Button
							onClick={handleAddCategory}
							className="create-categories"
						>
							{"Add new category"}
					</Button>
					<br />

					{!!data?.pages && (
						<CustomPagination
							page={page}
							totalPages={data?.pages}
							goNext={handleNext}
							goPrev={handlePrev}
							onPageChange={handlePage}
						/>
					)}
				</Wrapper>
			</DragDropContext>
		</>
	);
}

export default CategoriesList;
