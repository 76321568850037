export const isInteger = num => Number.isInteger(num);

export const getDecimalPart = num => {
	if (isInteger(num)) {
		return 0;
	}

	const decimalStr = num.toString().split(".")[1];
	return Number(decimalStr);
};
