import React from "react";
import { Preloader, TopMenu, Wrapper } from "./styles";
import Search from "./Search";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import List from "./List";
import Pagination from "../Pagination";
import CustomPagination from "../CustomPagination";
import Helmet from "react-helmet";
import Loader from "../Loader";
import { DragDropContext } from "react-beautiful-dnd";
import { updateProductsOrder } from "../../api/products";
import { getAuthDataFromLocalStorage } from "../../utils/authToken";
import { useState } from "react";
import { handleReOrdering } from "../../utils/products";
import { PrimaryButton } from "../../styles/ui";
import { PinkButton } from "../../styles/ui";


/**
 *
 * @constructor
 */
function ProductsList({ data, isLoading, error, refetch }) {
	const history = useHistory();
	const location = useLocation();
	const search = qs.parse(location.search);
	const { companyId } = getAuthDataFromLocalStorage();
	const [isUpdating, setIsUpdating] = useState(false);
	const page = +search.page || 1;

	/**
	 * Search input onChange handler
	 */
	const handleSearchChange = React.useCallback(
		e => {
			history.push({
				search: qs.stringify({
					...search,
					search: e.target.value || undefined,
					page: undefined,
				}),
			});
		},
		[history, search]
	);
	/**
	 * Go to page
	 */
	const handlePage = page => {
		history.push({
			search: qs.stringify({ ...search, page: page }),
		});
	};

	/**
	 * Add product
	 */
	const handleSelectType = React.useCallback(() => {
		history.push("/products/type");
	}, [history]);

	/**
	 * Go to next page
	 */
	const handleNext = () => {
		history.push({
			search: qs.stringify({ ...search, page: page + 1 }),
		});
	};

	/**
	 * Go to prev page
	 */
	const handlePrev = () => {
		history.push({
			search: qs.stringify({ ...search, page: page - 1 }),
		});
	};

	// Re-arrange Items
	const handleReArrange = async result => {
		const { source, destination } = result;

		const { dragOverItem, draggableItem } = handleReOrdering(
			source,
			destination,
			data?.data
		);
		const isReplacingLastItem = destination.index === data?.data.length - 1;
		setIsUpdating(true);
		await updateProductsOrder(companyId, [
			draggableItem.id,
			dragOverItem.id,
			isReplacingLastItem,
		]);
		refetch();
		setIsUpdating(false);
	};

	if (error)
		return <div>{`An error has occurred: " + ${error.message}`}</div>;

	if (!data?.data || isUpdating) {
		return (
			<Preloader>
				<Loader />
			</Preloader>
		);
	}

	return (
		<DragDropContext onDragEnd={handleReArrange}>
			<Wrapper>
				<Helmet>
					<title>Augmento.com - Products</title>
				</Helmet>
				<TopMenu>
					<PinkButton
						onClick={handleSelectType}
						className="add-product align-bottom"
					>
						<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
							<path d="M4.58619 8.60584C3.97708 8.60584 3.48329 8.11206 3.48329 7.50295V1.80895C3.48329 1.19984 3.97708 0.706055 4.58619 0.706055C5.1953 0.706055 5.68908 1.19984 5.68908 1.80895V7.50295C5.68908 8.11206 5.1953 8.60584 4.58619 8.60584ZM1.56819 5.699C0.992135 5.699 0.525146 5.23201 0.525146 4.65595C0.525146 4.07989 0.992134 3.6129 1.56819 3.6129H7.62128C8.19734 3.6129 8.66432 4.07989 8.66432 4.65595C8.66432 5.23201 8.19734 5.699 7.62128 5.699H1.56819Z" fill="white"/>
						</svg>
						{"Add new product"}
					</PinkButton>
					<Search
						onChange={handleSearchChange}
						value={search.search}
						className="search"
					/>
				</TopMenu>

				{data?.data.length && (
					<List
						data={data?.data}
						isLoading={isLoading}
						refetch={refetch}
					/>
				)}

				<br />
				{!!data?.pagination?.total && (
					// <Pagination
					// 	page={page}
					// 	totalPages={data?.pages}
					// 	goNext={handleNext}
					// 	goPrev={handlePrev}
					// />
					<CustomPagination
						page={page}
						totalPages={data?.pagination?.total}
						goNext={handleNext}
						goPrev={handlePrev}
						onPageChange={handlePage}
					/>
				)}
			</Wrapper>
		</DragDropContext>
	);
}

export default ProductsList;
