import { useQuery } from "react-query";
import { getCategories } from "../../api/categories";
import { Field } from "formik";
import React from "react";
import { Item } from "../LinkedProducts/style";
import Checkbox from "../Checkbox";
import Search from "./Search";
import { Wrapper } from "./styles";
import qs from "query-string";

function ClientCategorySelect({ name, companyId }) {
	const [search, setSearch] = React.useState("");
	const [page, setPage] = React.useState(1);
	const queryParams = qs.stringify({
		search: search || undefined,
	});
	const { data, isLoading, error } = useQuery(
		["clientCategories", search, companyId],
		() => getCategories(companyId, `?${queryParams}`)
	);
	if (error) {
		return <div>{error.message}</div>;
	}

	return (
		<>
			<Search value={search} onChange={e => setSearch(e.target.value)} />
			<Wrapper>
				{!isLoading && !data?.data?.data?.length && <div>No items</div>}
				{isLoading && <div>Loading..</div>}

				{data?.data?.data?.map(category => (
					<Item>
						<Field
							name={name}
							type="checkbox"
							value={category.id}
							as={Checkbox}
							title={category.title}
							key={category.id}
						/>
					</Item>
				))}
			</Wrapper>

			{/* {!!data?.data?.pages && (
				<Pagination
					totalPages={data.data.pages}
					page={page}
					goNext={() => setPage(prev => prev + 1)}
					goPrev={() => setPage(prev => prev - 1)}
				/>
			)} */}
		</>
	);
}

export default ClientCategorySelect;
