import WithSidebar from "../layout/WithSidebar";
import PageHeader from "../components/PageHeader";
import PageContent from "../components/PageContent";
import { useEffect, useState } from "react";
import { H2 } from "../styles/ui";
import styled, { ThemeProvider } from "styled-components";
import Loader from "../components/Loader";
import { GlobalStyle, theme } from "../styles/global";
import Helmet from "react-helmet";
export const StyledDiv = styled.div``;
function Support() {
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://js-eu1.hsforms.net/forms/shell.js";
		document.body.appendChild(script);

		script.addEventListener("load", () => {
			// @TS-ignore
			if (window.hbspt) {
				// @TS-ignore
				window.hbspt.forms.create({
					target: "#hubspotForm",
					region: "eu1",
					portalId: "25281940",
					formId: "c68f1727-78d8-4cdd-8ea7-115e9df5967b",
				});
				setLoading(false);
			}
		});
	}, []);
	return (
		<WithSidebar>
			<Helmet>
				<title>Augmento.com - Support</title>
			</Helmet>
			<PageHeader title={"Support"} />
			<PageContent>
				{!loading ? (
					<>
						<H2
							mb={40}
							style={{
								color: "#000000",
								fontFamily: "Eina 02",
								fontSize: "21px",
								fontWeight: "400",
							}}
						>
							Create a support ticket here, and we will help you
							within 24 hours
						</H2>
						<StyledDiv id="hubspotForm" />
					</>
				) : (
					<ThemeProvider theme={theme}>
						<Loader />
						<GlobalStyle />
					</ThemeProvider>
				)}
			</PageContent>
		</WithSidebar>
	);
}

export default Support;
