import api from "./index";

export const createCategory = (companyId, data) =>
	api.post(`/v1/categories`, data);

export const updateCategoriesOrder = (companyId, data) =>
	api.patch(`/v1/companies/${companyId}/categories/order`, data);

export const getCategories = (companyId, query) =>
	api.get(`/v1/admin/companies/${companyId}/categories${query}`);

export const getCategoriesOptions = (companyId, query) =>
	api.get(`/v1/companies/${companyId}/categories/asOptions${query}`);

export const getPublicCompanies = query =>
	api.get(`/v1/admin/companies${query}`);

export const setCategoryVisibility = (categoryId, isVisible) => {
	return api.patch(`/v1/categories/${categoryId}`, isVisible);
};

export const getCategory = categoryId =>
	api.get(`/v1/categories/${categoryId}`);

export const updateCategory = (categoryId, data) =>
	api.patch(`/v1/categories/${categoryId}`, data);

export const deleteCategory = categoryId =>
	api.delete(`/v1/categories/${categoryId}`);
