import WithSidebar from "../../layout/WithSidebar";
import PageHeader from "../../components/PageHeader";
import PageContent from "../../components/PageContent";
import CategoriesList from "../../components/CategoriesList";
import { useQuery } from "react-query";
import { getCategories } from "../../api/categories";
import { getAuthDataFromLocalStorage } from "../../utils/authToken";
import { useHistory, useLocation } from "react-router-dom";
import React from "react";
import AuthContext from "../../contexts/auth";
import CustomPageHeader from "../../components/CustomPageHeader";
import qs from "query-string";

function Categories() {
	const auth = React.useContext(AuthContext);
	const { companyId } = getAuthDataFromLocalStorage();
	const location = useLocation();
	const history = useHistory();

	/**
	 * Fetching categories list
	 */
	const { data, isLoading, error, refetch } = useQuery(
		["categories", location.search],
		() => getCategories(companyId, location.search),
		{
			select: data => data?.data,
		}
	);
	const search = qs.parse(location.search);

	const applyLimit = data => {
		history.push({
			search: qs.stringify({
				...search,
				limit: data.value,
			}),
		});
	};

	return (
		<>
			<WithSidebar>
				{/* <PageHeader
				title={"Categories"}
				totalProducts={100}
				usedProducts={3}
				showCounter={false}
			/> */}
				<CustomPageHeader
					title={
						auth.isSuperAdmin
							? "Public library Categories"
							: "Categories"
					}
					name={"categories"}
					totalProducts={100}
					usedProducts={3}
					showDropdown
					limit={data?.pagination?.limit || 25}
					changeLimit={applyLimit}
				/>

				<PageContent>
					<CategoriesList
						data={data?.data}
						isLoading={isLoading}
						error={error}
						refetch={refetch}
					/>
				</PageContent>
			</WithSidebar>
		</>
	);
}

export default Categories;
