import React from "react";
import { H2, PrimaryButton } from "../../styles/ui";
import {
	Body,
	Container,
	Description,
	Header,
	HR,
	LI,
	Row,
	Table,
	Title,
	TitleCell,
	UL,
	ValueCell,
	Wrapper,
	CancelButton,
	ViewsCard,
	Text,
} from "./styles";
import useActiveSubscription from "../../hooks/useActiveSubscription";
import Loader from "../Loader";
import {
	getEndTrialDate,
	getPlanPrice,
	getPlanTitle,
	getSubscriptionNextBillingDate,
} from "../../utils/plans";
import {
	cancelSubscription,
	getPlans,
	removeCard,
	setupIntent,
} from "../../api/subscriptions";
import { NotificationManager as Alert } from "react-notifications";
import { useQuery, useQueryClient } from "react-query";
import useActiveCard from "../../hooks/useActiveCard";
import FlexBox from "../FlexBox";
import FlexGrow from "../FlexGrow";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

/**
 *
 * @param mb
 * @returns {JSX.Element}
 * @constructor
 */
function CurrentPlan({ mb }) {
	const { data, error, isLoading } = useActiveSubscription();
	const cardInfo = useActiveCard();
	const [loadingCancel, setLoadingCancel] = React.useState(false);
	const [loadingRemove, setLoadingRemove] = React.useState(false);
	const [items, setItems] = React.useState();
	const queryClient = useQueryClient();
	React.useEffect(() => {
		setItems(data?.subscription?.items[0]);
	}, [data]);

	if (isLoading) {
		return <Loader />;
	}

	if (error) {
		console.log(error);
	}

	// const billingDate = getSubscriptionNextBillingDate(data);

	const handleSubscriptionCancel = async () => {
		if (loadingCancel) {
			return null;
		}

		setLoadingCancel(true);

		cancelSubscription()
			.then(() => {
				Alert.success("Subscription successfully changed!", "Success");
				return new Promise(res => {
					setTimeout(() => res(), 5000);
				});
			})
			.then(() => {
				queryClient.invalidateQueries("activeSubscription");
			})
			.catch(e => Alert.error(e.message, "Error"))
			.finally(() => setLoadingCancel(false));
	};
	const deleteCard = async id => {
		if (loadingRemove) {
			return null;
		}

		setLoadingRemove(true);

		removeCard({ id: id })
			.then(() => {
				Alert.success("Card removed successfully!", "Success");
				window.location.reload();
				return new Promise(res => {
					setTimeout(() => res(), 5000);
				});
			})
			.then(() => {
				queryClient.invalidateQueries("removeCard");
			})
			.catch(e => Alert.error(e.message, "Error"))
			.finally(() => setLoadingRemove(false));
	};
	const setupPayment = async () => {
		setupIntent()
			.then(res => {
				window.open(res.data.url, "_self");
			})
			.catch(e => Alert.error(e.message, "Error"))
			.finally(() => {
				setLoadingCancel(false);
			});
	};

	return (
		<Wrapper mb={mb}>
			<FlexBox alignItems={"flex-start"}>
				<FlexGrow>
					<H2 mb={8}>{"Current plan"}</H2>
					<Container
						style={{ minHeight: "184.854px" }}
						onCancelling={loadingCancel}
					>
						{isLoading && <Loader />}
						{!items ? (
							<>
								<FlexBox>
									<FlexGrow>
										<Title>No plan</Title>
									</FlexGrow>
								</FlexBox>
							</>
						) : (
							<>
								<FlexBox justify="space-between">
									<Title fontSize={24} fontWeight={700}>
										{items?.plan
											? `${items?.metadata.display_name}`
											: "No plan"}
									</Title>
									<ViewsCard>
										<Text>
											View used:{" "}
											{items?.plan
												? `${items?.plan.interval_count}/${items?.price.metadata.max_views}`
												: "--"}
										</Text>
									</ViewsCard>
								</FlexBox>

								<HR />

								<Body>
									<Table>
										<Row>
											<TitleCell>{"Price"}</TitleCell>

											<ValueCell>
												{getPlanPrice(
													items?.plan?.amount,
													items?.plan?.interval
												)}
											</ValueCell>
										</Row>

										<Row>
											<TitleCell>
												{"Next billing date"}
											</TitleCell>

											<ValueCell>
												{data?.subscription &&
													getSubscriptionNextBillingDate(
														data?.subscription
													)}
											</ValueCell>
										</Row>
									</Table>
									<UL>
										<LI>{`You will next be billed on ${getSubscriptionNextBillingDate(
											data?.subscription
										)}`}</LI>
										<LI>{"Cancel anytime"}</LI>
									</UL>
								</Body>
							</>
						)}
					</Container>
					{!!items?.plan?.amount && (
						<CancelButton onClick={handleSubscriptionCancel}>
							{"Cancel subscription"}
						</CancelButton>
					)}
				</FlexGrow>
				<FlexGrow>
					<H2 mb={8}>{"Payment method"}</H2>
					{cardInfo.isLoading ? (
						<Loader />
					) : (
						<>
							{cardInfo?.error &&
							cardInfo?.error?.response?.status === 426 ? (
								<>
									<Header style={{ marginTop: "16px" }}>
										<Title style={{ color: "red" }}>
											{
												cardInfo?.error?.response?.data
													?.message
											}
										</Title>

										<Description>
											{
												"Please add a payment method to continue"
											}
										</Description>
									</Header>
									<PrimaryButton
										onClick={() => setupPayment()}
									>
										Add
									</PrimaryButton>
								</>
							) : (
								<>
									<Header
										style={{
											display: "inline-flex",
											paddingBottom: "0",
											opacity: loadingRemove ? ".5" : 1,
										}}
									>
										<Cards
											style={{ marginLeft: "0" }}
											cvc={"xxx"}
											expiry={
												cardInfo.data.card.exp_month +
												"/" +
												cardInfo.data.card.exp_year
											}
											preview={true}
											focused={"expiry"}
											name={"XXXXXXX XXXXXXX"}
											number={
												"**** **** ****" +
												cardInfo.data.card.last4
											}
										/>
									</Header>
									<CancelButton
										align={"right"}
										onClick={() =>
											deleteCard(cardInfo.data.id)
										}
									>
										{"Remove card"}
									</CancelButton>
								</>
							)}
						</>
					)}
				</FlexGrow>
			</FlexBox>
		</Wrapper>
	);
}

export default CurrentPlan;
