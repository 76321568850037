import React from "react";
import { ErrorMessage, Field, Form } from "formik";
import { H3, Input, PrimaryButton } from "../../styles/ui";
import CategoriesSelect from "../CategoriesSelect";

import { Container, Left } from "../../layout/ProductEdit/styles";

import { AlignRight, Error, InputWrapper } from "../../pages/auth/styles";
import useActiveSubscription from "../../hooks/useActiveSubscription";
import Loader from "../Loader";

/**
 *
 * @param values
 * @param setFieldValue
 * @param isSubmitting
 * @returns {JSX.Element}
 * @constructor
 */
function CollectionForm({
	values,
	initialValues,
	setFieldValue,
	isSubmitting,
}) {
	const { data, isLoading, error } = useActiveSubscription();
	const input = React.createRef();

	if (isLoading) {
		return <Loader />;
	}

	if (error) {
		return null;
	}
	return (
		<>
			<Form>
				<Container>
					<Left>
						<InputWrapper mb={50}>
							<Field
								name="title"
								as={Input}
								placeholder={"Product name"}
								mb={3}
							/>
							<AlignRight>
								<ErrorMessage
									name={"title"}
									component={Error}
								/>
							</AlignRight>
						</InputWrapper>

						<H3 mb={21}>{"Which categories are included?"}</H3>

						<CategoriesSelect name="categories" />
					</Left>
				</Container>

				<PrimaryButton
					type="submit"
					disabled={isSubmitting}
					style={{ marginTop: "74px" }}
				>
					{"Save"}
				</PrimaryButton>
			</Form>
		</>
	);
}

export default CollectionForm;
