export const pricesOptions = [
  {
    value: '',
    label: 'Currency',
  },
  {
    value: '$',
    label: '$ - USD',
  },
  {
    value: '€',
    label: '€ - EUR',
  },
  {
    value: '£',
    label: '£ - GBP',
  },
  {
    value: 'A$',
    label: 'A$ - AUD',
  },
  {
    value: 'C$',
    label: 'C$ - CAD',
  },
  {
    value: 'د.إ',
    label: 'د.إ - AED',
  },
  {
    value: 'R$',
    label: 'R$ - BRL',
  },
  {
    value: 'CHF',
    label: 'CHF - CHF',
  },
  {
    value: 'NZ$',
    label: 'NZ$ - NZD',
  },
  {
    value: 'kr',
    label: 'kr - SEK',
  },
  {
    value: 'S$',
    label: 'S$ - SGD',
  },
  {
    value: '$',
    label: '$ - MXN',
  },
  {
    value: '₺',
    label: '₺ - TRY',
  },
];
