import React from "react";
import { Wrapper } from "./styles";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { displayError } from "../../utils/errors";
import { H3 } from "../SettingsForm/styles";
import { PrimaryButton } from "../../styles/ui";
import { subscribe } from "../../api/subscriptions";
import { NotificationManager as Alert } from "react-notifications";
import { getAuthDataFromLocalStorage } from "../../utils/authToken";
import { useQueryClient } from "react-query";

/**
 *
 * @param plan
 * @returns {JSX.Element|null}
 * @constructor
 */
function Payment({ plan }) {
	const stripe = useStripe();
	const elements = useElements();
	const queryClient = useQueryClient();
	const { stripeCustomer } = getAuthDataFromLocalStorage();

	const [isLoading, setIsLoading] = React.useState(false);
	const [show, setShow] = React.useState(true);

	if (!show) {
		return null;
	}

	if (!plan) {
		return null;
	}

	/**
	 * Submit form handler
	 *
	 * @param event
	 */
	const handleSubmit = async event => {
		event.preventDefault();

		setIsLoading(true);

		if (!stripe || !elements) {
			return;
		}

		const card = elements.getElement(CardElement);
		const result = await stripe.createToken(card);

		if (result.error) {
			displayError(result.error);
		} else {
			await subscribe(result.token.id, stripeCustomer, plan)
				.then(() => {
					Alert.success("Successfully subscribed!", "Success");
					setShow(false);
					return new Promise(res => {
						setTimeout(() => res(), 5000);
					});
				})
				.then(() => {
					queryClient.invalidateQueries("activeSubscription");
				})
				.catch(error => Alert.error(error.message, "Error"));
		}

		setIsLoading(false);
	};

	return (
		<form onSubmit={handleSubmit}>
			<H3 mb={8}>{"Payment Information"}</H3>

			<Wrapper>
				<CardElement
					options={{
						hidePostalCode: true,
						style: {
							base: {
								fontSize: "16px",
								color: "#333641",
								"::placeholder": {
									color: "#aab7c4",
								},
							},
							invalid: {
								color: "#9e2146",
							},
						},
					}}
				/>
			</Wrapper>

			<PrimaryButton type="submit" disabled={isLoading}>
				{"Subscribe"}
			</PrimaryButton>
		</form>
	);
}

export default Payment;
