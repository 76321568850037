import styled, { css } from "styled-components";

export const FlexBox = styled.div`
	display: ${props => (props.inline ? "inline-flex" : "flex")};
	flex-wrap: wrap;
	align-items: ${props => (props.alignItems ? props.alignItems : "center")};
	justify-content: ${props => (props.justify ? props.justify : "flex-start")};
	margin-bottom: ${props => (props.mb ? props.mb : 0)}px;
	${props =>
		props.direction &&
		css`
			flex-direction: ${props.direction};
		`};

	& > * {
		margin: 0;

		&:not(:last-child) {
			${props =>
				props.direction === "column" &&
				css`
					margin-bottom: ${8 *
					(props.space !== undefined ? props.space : 1)}px;
				`}

			${props =>
				(!props.direction || props.direction === "row") &&
				css`
					margin-right: ${8 *
					(props.space !== undefined ? props.space : 1)}px;
				`}
		}
	}
`;
