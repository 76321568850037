import { Field } from "formik";
import styled from "styled-components";

import { makeStyles } from "@material-ui/styles";

export const StyledField = styled(Field)`
	width: 68px;
`;

export const VariantsWrapper = styled.div`
	${({ outlined }) => {
		if (outlined) {
			return `
        position: relative;
        opacity: .5;
        border: 1px dashed #979797;
        padding: 25px 25px 0 25px;
        border-radius: 10px;
        
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          
        }
      `;
		}
	}}
`;

export const UpgradeMsg = styled.div`
	width: 100%;
	margin-left: -25px;
	background: rgba(0, 0, 0, 0.05);
`;
export const UpgradeLink = styled.div``;

export const H4 = styled.h4`
	font-size: ${props => (props.size ? props.size : "small")};
	font-weight: bold;
	margin: 0;
	margin-bottom: ${props => (props.mb ? props.mb : "8px")};
`;

export const FieldWrapper = styled.div`
	width: 100%;
`;

export const TitleSection = styled.section`
	display: flex;
	justify-content: space-between;
	gap: 24px;

	@media screen and (max-width: 580px) {
		gap: 0px;
		flex-direction: column;
	}
`;

export const useStyles = makeStyles({
	tabs: { marginBottom: "24px" },
	root: { textTransform: "inherit" },
	label: { flexDirection: "row" },
});

export const GenerateModelSection = styled.section`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

export const OptionsSection = styled.section`
	display: flex;
	gap: 20px;

	@media screen and (max-width: 680px) {
		gap: 0px;
		flex-direction: column;
	}
`;

export const CheckboxWrapper = styled.div`
	margin: auto 0;
	display: flex;
	align-items: center;
`;

export const CustomCheckbox = styled.input`
	appearance: none; /* Remove the default checkbox appearance */
	-webkit-appearance: none; /* For cross-browser compatibility */
	-moz-appearance: none; /* For cross-browser compatibility */
	width: 16px;
	height: 16px;
	border: 1px solid black;
	border-radius: 4px;
	background-color: white;
	cursor: pointer;
	position: relative;

	::before {
		content: "✓";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 16px;
		color: black;
		opacity: 0;
	}

	:checked::before {
		opacity: 1;
	}
`;

export const CheckboxLabel = styled.label`
	margin-left: 8px;
`;

export const Tooltip = styled.p`
	position: absolute;
	display: none;
	background-color: #dadada;
	color: black;
	padding: 5px;
	border-radius: 6px;
	white-space: nowrap;
	bottom: 80%;
	left: 50%;
	font-size: small;
	font-weight: 300;
	transform: translateX(-50%);
`;

export const TrumbnailSection = styled.section`
	display: flex;
	gap: 12px;
	margin-top: 20px;

	@media screen and (max-width: 680px) {
		gap: 8px;
		flex-direction: column;
	}
`;

export const MediaUploaderSection = styled.section`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 60%;

	@media screen and (max-width: 680px) {
		width: 100%;
	}
`;

export const CardsSection = styled.section`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 40%;

	@media screen and (max-width: 680px) {
		width: 100%;
		margin-bottom: 12px;
	}
`;

export const CardsWrapper = styled.div`
	display: flex;
	gap: 12px;
`;

export const Card = styled.div`
	overflow: hidden;
	width: 100%;
	height: max-content;
	min-height: 150px;
	border-radius: 20px;
	background-color: ${props => (props.image ? "white" : "#d9d9d9")};

	box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
	-o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
`;

export const CardImage = styled.img`
	width: 100%;
	height: max-content;
	object-fit: contain;
	position: relative;
`;

export const CardInfoSection = styled.section`
	height: 30%;
	display: flex;
	flex-wrap: wrap;
`;
export const CardTitle = styled.label`
	width: 100%;
	height: 20%;
	padding: 12px 0;
	color: grey;

	text-align: center;
	background: white;
`;

export const CardLearnMore = styled.label`
	width: 100%;
	height: 20%;
	color: white;

	padding: 12px 0;
	text-align: center;
	background-color: #323641;
`;

export const CardEmptyField = styled.div`
	width: 100%;
	height: 70%;
	padding: 50% 50%;
`;

export const LinkProductSection = styled.section`
	margin-bottom: 16px;
`;

export const LinkProductOptions = styled.div`
	display: grid;
	grid-auto-flow: column;
	grid-column-gap: 16px;

	@media screen and (max-width: 580px) {
		grid-auto-flow: row;
		grid-row-gap: 8px;
		width: 100%;
		margin-bottom: 12px;
	}
`;

export const ProductGroupSection = styled.section`
	width: 100%;
	max-width: 300px;
`;
