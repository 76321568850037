export const getDataUrlFromFile = file => {
	const reader = new FileReader();
	reader.readAsDataURL(file);

	return new Promise(resolve => {
		reader.onload = e => {
			resolve(e.target.result);
		};
	});
};
export function loadXHR(url) {
	return new Promise(function (resolve, reject) {
		try {
			var xhr = new XMLHttpRequest();
			xhr.open("GET", url);
			xhr.setRequestHeader("Access-Control-Request-Method", "GET");
			xhr.setRequestHeader("Origin", "http://localhost:3000");
			xhr.setRequestHeader("Accept", "*/*");
			xhr.responseType = "blob";
			xhr.onerror = function () {
				reject("Network error.");
			};
			xhr.onload = function () {
				if (xhr.status === 200) {
					resolve(xhr.response);
				} else {
					reject("Loading error:" + xhr.statusText);
				}
			};
			xhr.send();
		} catch (err) {
			reject(err.message);
		}
	});
}
