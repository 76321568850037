import { getAuthDataFromLocalStorage } from "../../utils/authToken";
import { useQuery } from "react-query";
import { getCategoriesOptions } from "../../api/categories";
import { Field } from "formik";
import React from "react";
import Search from "../CollectionsList/Search";
import { Item, Wrapper } from "../LinkedProducts/style";
import Checkbox from "../Checkbox";
import Pagination from "../Pagination";
import qs from "query-string";
import useDebounce from "../../hooks/useDebounce";

function CategoriesSelect({ name }) {
	const { companyId } = getAuthDataFromLocalStorage();
	const [search, setSearch] = React.useState("");
	const [page, setPage] = React.useState(1);
	const debouncedSearch = useDebounce(search, 500);

	const queryParams = qs.stringify({
		search: debouncedSearch || undefined,
	});
	const { data, isLoading, error } = useQuery(
		["categoriesOptions", debouncedSearch],
		() => getCategoriesOptions(companyId, `?${queryParams}`)
	);

	if (isLoading) {
		return <div>Loading..</div>;
	}

	if (error) {
		return <div>{error.message}</div>;
	}
	function handleSearchChange(event) {
		setSearch(event.target.value);
	}

	const {
		data: { data: categories },
	} = data;
	return (
		<>
			<Search
				value={search}
				placeholder={"Search categories.."}
				onChange={handleSearchChange}
			/>
			<Wrapper>
				{!categories?.length && <div>No items</div>}

				{categories?.map(product => (
					<Item>
						<Field
							name={name}
							type="checkbox"
							value={product.id}
							as={Checkbox}
							title={product.title}
							key={product.id}
						/>
					</Item>
				))}
			</Wrapper>

			{!!data.pages && (
				<Pagination
					totalPages={data.pages}
					page={page}
					goNext={() => setPage(prev => prev + 1)}
					goPrev={() => setPage(prev => prev - 1)}
				/>
			)}
		</>
	);
}

export default CategoriesSelect;
