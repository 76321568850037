import React from "react";
import {
	Category,
	Cell,
	Company,
	Edit,
	Name,
	RowWrapper,
	Toggle,
} from "./styles";
import { useHistory } from "react-router-dom";
import {
	NotificationManager as Alert,
	NotificationManager,
} from "react-notifications";
import { deleteCategory, setCategoryVisibility } from "../../api/categories";
import { displayError } from "../../utils/errors";
import { Icon } from "../ProductsList/styles";
import { ReactComponent as Trash } from "../../images/icons/trash.svg";
import { useQueryClient } from "react-query";
import AuthContext from "../../contexts/auth";
import ConfirmModal from "../ConfirmModal";
import { Draggable } from "react-beautiful-dnd";

/**
 *
 * @param category
 * @constructor
 */
function Row({ category, index }) {
	const auth = React.useContext(AuthContext);
	const history = useHistory();
	const queryClient = useQueryClient();
	const [isVisible, setIsVisible] = React.useState(category.isVisible);
	const [modalOpen, setModalOpen] = React.useState(false);
	/**
	 *
	 */
	const handleEdit = React.useCallback(() => {
		if (auth.isSuperAdmin) {
			history.push(`/categories/public/edit/${category.id}`);
		} else {
			history.push(`/categories/edit/${category.id}`);
		}
	}, [history, category]);

	/**
	 *
	 */
	const handleToggle = React.useCallback(() => {
		setIsVisible(!isVisible);
		const formData = new FormData();
		formData.append("isVisible", !isVisible);
		setCategoryVisibility(category.id, formData)
			.then(() => {
				NotificationManager.success(
					`Category '${category.title}' is now ${
						!isVisible ? "visible" : "invisible"
					}!`,
					"Success!"
				);
				window.location.reload();
			})
			.catch(e => {
				displayError(e);
				setIsVisible(isVisible);
			});
	}, [isVisible, category.title, category.id]);
	const confirmDelete = () => {
		setModalOpen(true);
	};
	const handleDelete = () => {
		deleteCategory(category.id)
			.then(() => {
				Alert.success("Category successfully deleted", "Success");
				return queryClient.invalidateQueries("categories");
			})
			.catch(displayError);
	};
	return (
		<>
			<ConfirmModal
				isOpen={modalOpen}
				setOpen={setModalOpen}
				deleteConfirmed={handleDelete}
				confirmText={"Do you want to delete this category?"}
			/>
			<Draggable
				key={category.id}
				draggableId={category.id.toString()}
				index={index}
			>
				{(draggableProvided, draggableSnapShot) => (
					<RowWrapper
						{...draggableProvided.dragHandleProps}
						{...draggableProvided.draggableProps}
						ref={draggableProvided.innerRef}
					>
						<div className="left-side side" style={{display: 'flex', justifyContent: 'space-between', width: 'calc(60% - 150px)'}}>
							<Name>
								<div
									style={{
										whiteSpace: "nowrap",
										width: "100%",
										textOverflow: "ellipsis",
										overflow: "hidden",
									}}
								>
									<span
										onClick={() =>
											history.push(
												"/products?categoryId=" +
													category.id
											)
										}
									>
										{category.title}
									</span>
								</div>
							</Name>
							<Category>
								{category.products.length + " product(s)"}
							</Category>
						</div>
						{true ? (
								<>
									<Company>
										{category?.companies?.map(company => (
											<>{company.name}</>
										))}
									</Company>
									<Company>{category?.createdBy?.name}</Company>
								</>
							) : (
								<>
									<Company />
									<Company />
								</>
							)}
						<div className="right-side side" style={{display: 'flex', justifyContent: 'flex-start'}}>
							<Cell>
								<Edit onClick={handleEdit}>{"edit"}</Edit>
							</Cell>
							<Cell basis={"50px"} shrink={"0"} grow={"0"}>
								<Toggle active={isVisible} onClick={handleToggle} />
							</Cell>
						</div>
											
						{/* <Cell basis={"50px"} shrink={"0"} grow={"0"} center>
							<Icon onClick={confirmDelete}>
								<Trash />
							</Icon>
						</Cell> */}
					</RowWrapper>
				)}
			</Draggable>
		</>
	);
}

export default Row;
