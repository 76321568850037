import AuthLayout from "../../layout/Auth";
import {
	AlignCenter,
	AlignRight,
	BottomLink,
	Content,
	ContentTitle,
	Error,
	Input,
	InputWrapper,
	SubmitButton,
} from "./styles";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { resetPassword } from "../../api/auth";
import { NotificationManager as Alert } from "react-notifications";
import { displayError } from "../../utils/errors";
import { useHistory } from "react-router-dom";
import Helmet from "react-helmet";

/**
 *
 */
const ResetPasswordSchema = Yup.object().shape({
	email: Yup.string().email("Invalid email").required("Required"),
});

/**
 *
 * @constructor
 */
function ResetPassword() {
	const history = useHistory();

	/**
	 * Form submit handler
	 */
	const handleSubmit = React.useCallback(
		(values, { setSubmitting }) =>
			resetPassword(values)
				// Successfully sent request for password rest
				.then(() => {
					history.push("/");

					return Alert.success(
						"Your password recovery request has been successfully sent!",
						"Success"
					);
				})
				// Handle error
				.catch(displayError)
				// Finish form submitting
				.finally(() => setSubmitting(false)),

		[history]
	);

	return (
		<AuthLayout>
			<Helmet>
				<title>Augmento.com - Reset password</title>
			</Helmet>
			<Content>
				<ContentTitle>
					{"Fill in your email to recover your password"}
				</ContentTitle>

				<Formik
					initialValues={{ email: "" }}
					validationSchema={ResetPasswordSchema}
					onSubmit={handleSubmit}
				>
					<Form>
						<InputWrapper mb={32}>
							<Field
								as={Input}
								name="email"
								type="email"
								placeholder="Email"
								mb={3}
							/>
							<AlignRight>
								<ErrorMessage name="email" component={Error} />
							</AlignRight>
						</InputWrapper>

						<SubmitButton type="submit">
							{"Reset password"}
						</SubmitButton>
					</Form>
				</Formik>

				<AlignCenter>
					<BottomLink to={"/auth/signin"}>{"or LOGIN"}</BottomLink>
				</AlignCenter>
			</Content>
		</AuthLayout>
	);
}

export default ResetPassword;
