import { useEffect, useState, useRef } from "react";
import * as THREE from "three";

import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import Loading from "../Loading/Loading";
import { RemoveIcon } from "./styles";
import CropImage from "../MediaUploader/CropImage";
import { CodeSharp } from "@material-ui/icons";

function ModelViewerElement({
	artUrl,
	frameConfig,
	frameImg,
	returnFile,
	loadedModel,
	useGlass,
	longestSideHandl,
	setFieldValue,
	inputSizeValue,
	longestSide,
	removeArtModel,
	productName,
	productId,
	type,
	src,
	setArtUrl,
}) {
	
	const [modelUrl, setModelUrl] = useState(null);

	const refHeightArt = useRef(0);
	const refWidthArt = useRef(0);

	const getAspectRatioModel = model => {
		let img = new Image();
		let isLoaded = false;
		let height = 0;
		let width = 0;

		let heightModel = 0;
		let widthModel = 0;
		let heightArt = 0;
		let widthArt = 0;

		img.src = model;

		img.onload = function () {
			height = img.height;
			width = img.width;

			isLoaded = true;
		};

		const checkLoaded = setInterval(() => {
			if (!isLoaded) return;

			if (height > width) heightModel = height / width;

			if (height < width) widthModel = width / height;
			inputSizeValue.current = height > width ? "height" : "width";

			if (inputSizeValue.current === "height") {
				if (height > 600) {
					heightArt = 600;
				} else {
					heightArt = height;
				}
				widthArt = heightArt / heightModel;
			} else if (inputSizeValue.current === "width") {
				if (width > 600) {
					widthArt = 600;
				} else {
					widthArt = width;
				}
				heightArt = widthArt / widthModel;
			}

			refHeightArt.current = heightArt.toFixed(0);
			refWidthArt.current = widthArt.toFixed(0);

			setFieldValue("arWidth", refWidthArt.current / 100);
			setFieldValue("arHeight", refHeightArt.current / 100);

			if (Number(refWidthArt.current) > Number(refHeightArt.current)) {
				longestSideHandl(Number(refWidthArt.current) / 100);
			} else {
				longestSideHandl(Number(refHeightArt.current) / 100);
			}

			loadFrame();
			clearInterval(checkLoaded);
		}, 100);
	};

	const loadFrame = () => {
		const image = new Image();

		if (!frameImg) return imageToModel(artUrl);

		image.src = frameImg;

		image.onload = () => {
			const texture = new THREE.Texture(image);
			imageToModel(artUrl, texture);
		};
	};

	const gltfToBlob = model => {
		fetch(model)
			.then(response => response.arrayBuffer())
			.then(buffer => {
				blobToFile(
					new Blob([buffer], { type: "text/plain" }),
					"artwork.gltf"
				);
			})
			.catch(error => console.error(error));
	};

	function blobToFile(theBlob, fileName) {
		const file = new File([theBlob], fileName, {
			lastModified: new Date(),
		});

		returnFile(file);
	}

	const imageToModel = (inputFile, textureFrame) => {
			const image = new Image();

			image.src = inputFile;
			image.onload = () => {
			const texture = new THREE.Texture(image);

			let w_temp = 0;
			let h_temp = 0;

			w_temp = refWidthArt.current;
			h_temp = refHeightArt.current;

			let thickness_frame_temp = frameConfig.frameThickness;
			let thickness_temp = frameConfig?.frameWidth / 100;

			const geometry = new THREE.BoxGeometry(
				w_temp / 100,
				h_temp / 100,
				!frameImg ? thickness_temp : 0.001
			);

			const material = new THREE.MeshPhysicalMaterial({
				envMapIntensity: 0.4,
				map: texture,
				metalness: 0.3,
				clearcoatRoughness: useGlass ? 0 : 1,
				clearcoat: useGlass ? 1 : 0,
			});

			const mesh = new THREE.Mesh(geometry, material);
			const gltfExporter = new GLTFExporter();

			const frameGeometry = new THREE.BoxGeometry(
				w_temp / 100 + frameConfig.frameWidth / 3,
				h_temp / 100 + frameConfig.frameHeight / 3,
				thickness_frame_temp
			);

			const frameMaterial = new THREE.MeshPhysicalMaterial({
				envMapIntensity: 0.4,
				map: textureFrame ? textureFrame : null,
				clearcoatRoughness: 1,
				metalness: 0.3,
			});

			const frameMesh = new THREE.Mesh(frameGeometry, frameMaterial);
			frameMesh.position.set(0, 0, -thickness_frame_temp * 0.51);

			let frame = !textureFrame ? mesh : [mesh, frameMesh];
			gltfExporter.parse(
				frame,
				gltf => {
					
					const gltfUrl = URL.createObjectURL(
						new Blob([JSON.stringify(gltf)], {
							
							type: "model/gltf+json",
						}),
					);
					gltfToBlob(gltfUrl);
					setModelUrl(() => gltfUrl);
				}
			);
		};
	};
	useEffect(() => {
		getAspectRatioModel(artUrl);
	}, [
		artUrl,
		frameImg,
		useGlass,
		frameConfig.frameWidth,
		frameConfig.frameHeight,
	]);
	useEffect(() => {
		if (
			!refHeightArt.current ||
			!refWidthArt.current ||
			longestSide * 100 === Number(refHeightArt.current) ||
			longestSide * 100 === Number(refWidthArt.current) ||
			longestSide <= 0
		)
			return;

		if (inputSizeValue.current === "height") {
			const coefficientHeightWidth =
				Number(refWidthArt.current) / Number(refHeightArt.current);

			refHeightArt.current = longestSide * 100;

			refWidthArt.current = refHeightArt.current * coefficientHeightWidth;
		}
		if (inputSizeValue.current === "width") {
			const coefficientWidthHeight =
				Number(refHeightArt.current) / Number(refWidthArt.current);

			refWidthArt.current = longestSide * 100;

			refHeightArt.current = refWidthArt.current * coefficientWidthHeight;
		}

		loadFrame();
	}, [longestSide]);

	useEffect(() => {
		loadFrame();
	}, [frameConfig.frameThickness]);
	// useEffect(()=>{
	// 	const renderer = new THREE.WebGLRenderer();
    //   renderer.setSize(window.innerWidth, window.innerHeight);
    //   document.body.appendChild(renderer.domElement);
	//   const dataURL = renderer.domElement.toDataURL();
    //   setModelUrl(dataURL);
	// })
	
	return (
		<>
			<RemoveIcon onClick={removeArtModel} />

			{type === "artworkImage" && (
				<CropImage
					productName={productName}
					productId={productId}
					src={src}
					type={type}
					setArtUrl={setArtUrl}
				/>
			)}
			{loadedModel || modelUrl ? (
				<model-viewer
					id="model-viewer"
					alt="3D model"
					src={loadedModel || modelUrl}
					ar
					shadow-intensity="3"
					shadow-softness="1"
					camera-controls
					auto-rotate
					autoplay
					loading="eager"
					style={{
						height: "100%",
						width: "100%",
					}}
				/>
			) : (
				<Loading />
			)}
		</>
	);
}

export default ModelViewerElement;
