import styled from "styled-components";

export const Wrapper = styled.div`
	position: relative;
	margin-top: -17px;
	margin-bottom: 10px;
	max-height: 300px;
	overflow: auto;
	background: rgba(0, 0, 0, 0.02);
	padding: 43px 23px 40px;
`;

export const Item = styled.div`
	&:not(:last-child) {
		margin: 0 0 40px;
	}

	&:last-child > label {
		margin-bottom: 0;
	}
`;
