import React from "react";
import PageContent from "../../components/PageContent";
import { Wrapper } from "../../layout/ProductEdit/styles";
import ProductType from "../../components/ProductType";
import ProductsContentWrapper from "../../components/Products/ProductsContentWrapper";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function SelectTypeProduct() {
	return (
		<ProductsContentWrapper helmet="Augmento.com - Select product type">
			<PageContent>
				<Wrapper>
					<ProductType />
				</Wrapper>
			</PageContent>
		</ProductsContentWrapper>
	);
}

export default SelectTypeProduct;
